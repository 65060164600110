
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import SelecionarTipoDocumentoFinanceiro from '../SelecionarTipoDocumentoFinanceiro.vue';

export default defineComponent({
  name: 'FormaPagamentoCopiarTipoDocumentoFinanceiro',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    tipoDocumentoFinanceiro: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
    SelecionarTipoDocumentoFinanceiro,
  },
  emits: ['update:visivel', 'confirmar'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      codigosSelecionados: [] as number[],
    });

    function limparTela() {
      state.codigosSelecionados = [] as number[];
    }

    function salvar() {
      if (state.codigosSelecionados.length === 0) {
        apresentarMensagemAlerta('Nenhum tipo selecionado!');
        return;
      }
      emit('confirmar', state.codigosSelecionados);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      salvar,
    };
  },
});
