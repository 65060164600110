
import { computed, defineComponent, reactive } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useSelecionarBase } from '@/core/composables/SelecionarBase';
import { ETipoConta } from '@/models/Enumeradores/Cadastros/Financeiro/ETipoConta';
import { IDTOContaDetalhe } from '@/models/DTO/Cadastros/Financeiro/IDTOContaDetalhe';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoConta from '@/servicos/Cadastros/Financeiro/ServicoConta';

export default defineComponent({
  name: 'SelecionarConta',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    descricaoSelecionado: {
      type: String,
      default: '',
    },
    empresas: {
      type: Array as () => number[],
      default: () => [],
    },
    contasReceber: {
      type: Boolean,
      default: false,
    },
    contasPagar: {
      type: Boolean,
      default: false,
    },
    verificarOperador: {
      type: Boolean,
      default: false,
    },
    usuarioOperando: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Conta',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    omitirContas: {
      type: Array as () => number[],
    },
  },
  components: {
    Icone,
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:descricaoSelecionado', 'change'],
  setup(props, { emit }) {
    const {
      selecionarBase, montaOpcoesComListaConsultaRapida, verificacaoPreBusca, verificacaoUltimaPesquisa, aguardarConclusaoCarregamento,
      comportamentoPadraoSemResultado, instanciaParametrosConsultaRapidaPorCodigo, instanciaParametrosConsultaRapidaPesquisa,
    } = useSelecionarBase(props, emit);

    let debounce = 0;
    const servicoConta = new ServicoConta();

    const state = reactive({
      detalhe: {} as IDTOContaDetalhe,
      icone: 'mala-de-dinheiro',
      cor: '#bc80ff',
    });

    function atualizarVisual() {
      switch (state.detalhe.tipoConta) {
        case ETipoConta.CaixaDiario:
          state.icone = 'saco-de-dinheiro';
          state.cor = '#ec9b73';
          break;
        case ETipoConta.Cartao:
          state.icone = 'cartao';
          state.cor = '#c9ec73';
          break;
        case ETipoConta.Cofre:
          state.icone = 'cofre';
          state.cor = '#67bdd6';
          break;
        case ETipoConta.ContaCorrente:
          state.icone = 'banco';
          state.cor = '#90cf97';
          break;
        case ETipoConta.ContaPoupanca:
          state.icone = 'banco';
          state.cor = '#869fbd';
          break;
        case ETipoConta.Investimento:
          state.icone = 'investimento';
          state.cor = '#ff8787';
          break;
        default:
          state.icone = 'mala-de-dinheiro';
          state.cor = '#bc80ff';
          break;
      }
    }
    function preencheListaOpcoes():void {
      if (UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida)) {
        if (props.omitirContas !== undefined) {
          props.omitirContas.forEach((codigoConta) => {
            selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((c) => c.codigo !== codigoConta);
          });
        }
        selecionarBase.listaOpcoes = montaOpcoesComListaConsultaRapida(selecionarBase.listaItensConsultaRapida);
      }
    }

    async function obterContaPorCodigo(valor: any) {
      if (!verificacaoPreBusca(valor)) return;

      selecionarBase.listaOpcoes = [];

      const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPorCodigo(valor, true);
      parametrosConsultaRapida.quantidadeRegistros = 9999999;
      selecionarBase.listaItensConsultaRapida = await servicoConta.consultaRapida(parametrosConsultaRapida, props.contasReceber, props.contasPagar, props.verificarOperador, props.usuarioOperando);
      preencheListaOpcoes();
      comportamentoPadraoSemResultado();
    }

    async function pesquisarConta(valorPesquisa: any) {
      selecionarBase.emDigitacao = true;

      if (!verificacaoUltimaPesquisa(valorPesquisa)) return;

      selecionarBase.buscandoDados = true;
      selecionarBase.listaOpcoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPesquisa(valorPesquisa, true);
        selecionarBase.listaItensConsultaRapida = await servicoConta.consultaRapida(parametrosConsultaRapida, props.contasReceber, props.contasPagar, props.verificarOperador, props.usuarioOperando);
        preencheListaOpcoes();
        selecionarBase.valorUltimaPesquisa = valorPesquisa;
        selecionarBase.buscandoDados = false;
      }, 600);
    }

    async function buscarDetalheConta() {
      if (props.codigoSelecionado !== undefined) {
        state.detalhe = await servicoConta.obterDetalheConta(props.codigoSelecionado);
      }
      atualizarVisual();
    }
    const computedDescricaoSelecionado = computed({
      get: () => props.descricaoSelecionado,
      set: (val: string) => {
        emit('update:descricaoSelecionado', val);
      },
    });

    function preencherDescricaoConta(codigoConta:number) {
      const option = selecionarBase.listaOpcoes.find((c) => c.value === codigoConta);
      if (option?.label !== undefined && option?.label !== '') {
        computedDescricaoSelecionado.value = option?.label;
      } else {
        computedDescricaoSelecionado.value = '';
      }
    }
    function obterConta():any {
      if (props.codigoSelecionado === 0) {
        return undefined;
      }
      obterContaPorCodigo(props.codigoSelecionado);
      if (props.codigoSelecionado !== undefined && props.codigoSelecionado > 0) {
        preencherDescricaoConta(props.codigoSelecionado);
        buscarDetalheConta();
      } else {
        state.detalhe.tipoConta = ETipoConta.Outros;
        atualizarVisual();
      }
      return props.codigoSelecionado;
    }

    const computedCodigoSelecionado = computed({
      get: () => obterConta(),
      set: (val: number) => {
        preencherDescricaoConta(val);
        emit('update:codigoSelecionado', val);
      },
    });

    function obterContas():number[] {
      obterContaPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterContas(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      state,
      selecionarBase,
      UtilitarioGeral,
      ETipoConta,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      atualizarVisual,
      pesquisarConta,
      aguardarConclusaoCarregamento,
      change,
    };
  },
});
