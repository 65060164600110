import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05cc10ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ss-div-progresso" }
const _hoisted_2 = { class: "ss-div-etapas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_configuracao_inicial_empresa = _resolveComponent("configuracao-inicial-empresa")!
  const _component_configuracao_inicial_equipe = _resolveComponent("configuracao-inicial-equipe")!
  const _component_configuracao_inicial_produtos = _resolveComponent("configuracao-inicial-produtos")!
  const _component_configuracao_inicial_financeiro = _resolveComponent("configuracao-inicial-financeiro")!
  const _component_configuracao_inicial_vendas = _resolveComponent("configuracao-inicial-vendas")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_progress, {
        percent: _ctx.state.configuracao.progresso,
        size: "small"
      }, null, 8, ["percent"]),
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pularConfiguracao && _ctx.pularConfiguracao(...args)))
      }, "Pular configuração")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_configuracao_inicial_empresa, {
        configuracao: _ctx.state.configuracao,
        "onUpdate:configuracao": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.configuracao) = $event)),
        onAtualizarConfiguracao: _ctx.atualizarConfiguracao
      }, null, 8, ["configuracao", "onAtualizarConfiguracao"]),
      (_ctx.state.configuracao.etapaAtual >= _ctx.EEtapaConfiguracaoInicial.Equipe)
        ? (_openBlock(), _createBlock(_component_configuracao_inicial_equipe, {
            key: 0,
            configuracao: _ctx.state.configuracao,
            "onUpdate:configuracao": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.configuracao) = $event)),
            onAtualizarConfiguracao: _ctx.atualizarConfiguracao
          }, null, 8, ["configuracao", "onAtualizarConfiguracao"]))
        : _createCommentVNode("", true),
      (_ctx.state.configuracao.etapaAtual >= _ctx.EEtapaConfiguracaoInicial.Produtos)
        ? (_openBlock(), _createBlock(_component_configuracao_inicial_produtos, {
            key: 1,
            configuracao: _ctx.state.configuracao,
            "onUpdate:configuracao": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.configuracao) = $event)),
            onAtualizarConfiguracao: _ctx.atualizarConfiguracao
          }, null, 8, ["configuracao", "onAtualizarConfiguracao"]))
        : _createCommentVNode("", true),
      (_ctx.state.configuracao.etapaAtual >= _ctx.EEtapaConfiguracaoInicial.Financeiro)
        ? (_openBlock(), _createBlock(_component_configuracao_inicial_financeiro, {
            key: 2,
            configuracao: _ctx.state.configuracao,
            "onUpdate:configuracao": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.configuracao) = $event)),
            onAtualizarConfiguracao: _ctx.atualizarConfiguracao
          }, null, 8, ["configuracao", "onAtualizarConfiguracao"]))
        : _createCommentVNode("", true),
      (_ctx.state.configuracao.etapaAtual >= _ctx.EEtapaConfiguracaoInicial.Vendas)
        ? (_openBlock(), _createBlock(_component_configuracao_inicial_vendas, {
            key: 3,
            configuracao: _ctx.state.configuracao,
            "onUpdate:configuracao": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.configuracao) = $event)),
            onAtualizarConfiguracao: _ctx.atualizarConfiguracao
          }, null, 8, ["configuracao", "onAtualizarConfiguracao"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}