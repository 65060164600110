
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ICardFluxo } from '@/core/models/IFluxo';
import Fluxo from './Fluxo.vue';

export default defineComponent({
  name: 'ModalFullScreenFluxo',
  props: {
    visivel: {
      type: Boolean,
    },
    editavel: {
      type: Boolean,
      default: true,
    },
    titulo: {
      type: String,
      default: 'Fluxo',
    },
    cards: {
      type: Array as () => ICardFluxo[],
      required: true,
    },
  },
  components: {
    Icone,
    Fluxo,
  },
  emits: ['update:visivel', 'selecaoAlterada', 'adicionar', 'editar', 'excluir'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      zoom: 100,
    });

    function zoomin() {
      state.zoom += 10;
    }

    function zoomout() {
      state.zoom -= 10;
    }

    function selecaoCardAlterada(identificador: string) {
      emit('selecaoAlterada', identificador);
    }

    function adicionar(identificador: string) {
      emit('adicionar', identificador);
    }

    function editar(identificador: string) {
      emit('editar', identificador);
    }

    function excluir(identificador: string) {
      emit('excluir', identificador);
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      zoomin,
      zoomout,
      selecaoCardAlterada,
      adicionar,
      editar,
      excluir,
    };
  },
});
