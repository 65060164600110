import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ITabelaPreco, ITabelaPrecoItemProduto } from '@/models/Entidades/Cadastros/Precificacao/ITabelaPreco';
import { IDTOTabelaPreco } from '@/models/DTO/Cadastros/Precificacao/IDTOTabelaPreco';
import { IDTOAlteracaoPrecoProduto } from '@/models/DTO/Cadastros/Precificacao/IDTOAlteracaoPrecoProduto';
import { IDTOExclusaoProdutosTabelaPreco } from '@/models/DTO/Cadastros/Precificacao/IDTOExclusaoProdutosTabelaPreco';
import { IDTOTabelaPrecoItemProduto } from '@/models/DTO/Cadastros/Precificacao/IDTOTabelaPrecoItemProduto';

/**
 * Serviço de Tabela de preços
 * Fornece todas regras de negócios e lógicas relacionado a tabela de preços.
 */

class ServicoTabelaPreco implements IServicoBase<ITabelaPreco> {
    endPoint = 'tabelas-precos';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterListaStatus(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/status`);
      return result.data;
    }

    public async obterListaConceitoPrecificacao(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/conceitos-precificacao`);
      return result.data;
    }

    public async obterListaFormaInclusaoItens(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/forma-inclusao-itens`);
      return result.data;
    }

    public async obterTabelasPrecoEstruturaCompleta(empresas:number[], tipoItem:number, apenasAtivas:boolean): Promise<IDTOTabelaPreco[]> {
      let parametrosAdicionais = this.apiERP.obterParametroEmpresas(empresas);
      parametrosAdicionais += `&tipoItem=${tipoItem}`;
      parametrosAdicionais += `&apenasAtivas=${apenasAtivas}`;
      const result: any = await this.apiERP.get(`${this.endPoint}/todas/estruturas${parametrosAdicionais}`);
      return result.data;
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOTabelaPreco): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarCodigoInterno?:boolean): Promise<IItemConsultaRapida[]> {
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaBoleano(apresentarCodigoInterno)) {
        parametrosAdicionais += `&apresentarCodigoInterno=${apresentarCodigoInterno}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapidaProduto(parametrosConsultaRapida: IParametrosConsultaRapida, codigoProdutoDefinicao: number): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida/produto/${codigoProdutoDefinicao}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterPrecoProduto(codigoItemTabelaPreco: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/preco-produto/${codigoItemTabelaPreco}`);
      return result.data;
    }

    public async incluir(objeto: ITabelaPreco): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
      return result.data;
    }

    public async alterar(objeto: ITabelaPreco): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}`, objeto);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<ITabelaPreco> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obterProdutos(codigoTabela: number, empresa: number, codigoDefinicoesProdutos?:number[]): Promise<ITabelaPrecoItemProduto[]> {
      let parametrosAdicionais = '';
      if (codigoDefinicoesProdutos !== undefined) {
        codigoDefinicoesProdutos.forEach((codigoDefinicao) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `codigoDefinicoesProdutos=${codigoDefinicao}`;
        });
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigoTabela}/empresa/${empresa}/produtos${parametrosAdicionais}`);
      return result.data;
    }

    public async obterTabelasProdutosDefinicoes(empresas:number[], codigoDefinicoesProdutos:number[], codigosEspecificos: number[], apenasTabelasAtivas:boolean): Promise<IDTOTabelaPrecoItemProduto[]> {
      let parametrosAdicionais = '';
      if (empresas !== undefined) {
        empresas.forEach((codigoEmpresa) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `empresas=${codigoEmpresa}`;
        });
      }
      if (codigoDefinicoesProdutos !== undefined) {
        codigoDefinicoesProdutos.forEach((codigoDefinicao) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `codigoDefinicoesProdutos=${codigoDefinicao}`;
        });
      }
      if (codigosEspecificos !== undefined) {
        codigosEspecificos.forEach((codigoTabela) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `codigosEspecificos=${codigoTabela}`;
        });
      }

      if (UtilitarioGeral.validaBoleano(apenasTabelasAtivas)) {
        if (apenasTabelasAtivas) {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += 'apenasTabelasAtivas=true';
        }
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/definicoes-produtos/precos${parametrosAdicionais}`);
      return result.data;
    }

    public async adicionarPrecosProdutos(codigoTabela:number, produtos: ITabelaPrecoItemProduto[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/${codigoTabela}/produtos`, produtos);
      return result.data;
    }

    public async alterarPrecosProduto(codigoTabela:number, codigoProdutoDefinicao:number, objeto: IDTOAlteracaoPrecoProduto): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${codigoTabela}/produtos/${codigoProdutoDefinicao}`, objeto);
      return result.data;
    }

    public async removerProdutosTabela(codigoTabela:number, todosProdutos:boolean, codigosProdutosDefinicao:number[]): Promise<IRetornoRequisicao> {
      const exclusaoProdutos:IDTOExclusaoProdutosTabelaPreco = {} as IDTOExclusaoProdutosTabelaPreco;
      exclusaoProdutos.todosProdutos = todosProdutos;
      exclusaoProdutos.codigosProdutosDefinicao = [];
      if (UtilitarioGeral.validaLista(codigosProdutosDefinicao)) {
        exclusaoProdutos.codigosProdutosDefinicao = codigosProdutosDefinicao;
      }
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigoTabela}/produtos`, exclusaoProdutos);
      return result.data;
    }

    public async removerProdutoTabela(codigoTabela:number, codigoProdutoDefinicao:number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigoTabela}/produtos/${codigoProdutoDefinicao}`);
      return result.data;
    }
}
export default ServicoTabelaPreco;
