
import { defineComponent, reactive } from 'vue';
import { ICardFluxo } from '@/core/models/IFluxo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'Fluxo',
  components: {
    Icone,
  },
  props: {
    pontoInicial: {
      type: Boolean,
      default: true,
    },
    editavel: {
      type: Boolean,
      default: true,
    },
    cards: {
      type: Array as () => ICardFluxo[],
      required: true,
    },
  },
  emits: ['selecaoAlterada', 'adicionar', 'editar', 'excluir'],
  setup(props, { emit }) {
    const state = reactive({
      mouseIn: false,
    });

    function mouseIn() {
      state.mouseIn = true;
    }

    function mouseOut() {
      state.mouseIn = false;
    }

    function selecaoCardAlterada(identificador: string) {
      emit('selecaoAlterada', identificador);
    }

    function adicionar(identificador: string) {
      emit('adicionar', identificador);
    }

    function editar(identificador: string) {
      emit('editar', identificador);
    }

    function excluir(identificador: string) {
      emit('excluir', identificador);
    }

    return {
      props,
      state,
      UtilitarioGeral,
      selecaoCardAlterada,
      mouseIn,
      mouseOut,
      adicionar,
      editar,
      excluir,
    };
  },
});
