
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IPlanoContaCategoria, IPlanoContaCategoriaCentroCusto } from '@/models/Entidades/Cadastros/PlanosContas/IPlanoContaCategoria';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import SelecionarPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarPlanoConta.vue';
import SelecionarTipoCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarTipoCategoriaPlanoConta.vue';
import SelecionarCreditoDebito from '@/components/Compartilhados/SelecionarCreditoDebito.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import PlanoContaCategoriaCentrosCustoModal from './PlanoContaCategoriaCentrosCustoModal.vue';
import { IDTOPlanoContaCategoriaCentroCusto } from '@/models/DTO/Cadastros/PlanosContas/IDTOPlanoContaCategoriaCentroCusto';
import ServicoPlanoContas from '@/servicos/Cadastros/PlanosContas/ServicoPlanoContas';
import SelecionarContaContabilReferenciada from '@/components/Cadastros/PlanosContas/SelecionarContaContabilReferenciada.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';

export default defineComponent({
  name: 'PlanoContaCategoriaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
    planoContas: {
      type: Number,
      default: 0,
    },
    mascaraPlanoContas: {
      type: String,
      default: '',
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    SelecionarPlanoConta,
    SelecionarCategoriaPlanoConta,
    SelecionarTipoCategoriaPlanoConta,
    SelecionarCreditoDebito,
    SelecionarSimNao,
    PlanoContaCategoriaCentrosCustoModal,
    SelecionarContaContabilReferenciada,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, preencherPermissoesDados, defineEmpresasSelecionadasComPermissao,
      filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);

    const servicoPlanoContas = new ServicoPlanoContas();
    telaBase.identificadorRecurso = 'CADASTRO_PLANOS_CONTAS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_PLANOS_CONTAS';

    const state = reactive({
      categoria: {} as IPlanoContaCategoria,
      centrosCusto: [] as IDTOPlanoContaCategoriaCentroCusto[],
      mascaraConta: '',
      mostrarCentrosCusto: false,
      categoriaPaiAlterada: false,
    });

    function objetoInicial() {
      state.categoriaPaiAlterada = false;
      state.categoria = {} as IPlanoContaCategoria;
      state.categoria.ativo = true;
      state.categoria.codigo = 0;
      state.categoria.contaReduzida = 0;
      state.categoria.balancete = false;
      state.categoria.tipo = 'S';
      state.categoria.natureza = 'C';
      state.categoria.centrosCustos = [] as IPlanoContaCategoriaCentroCusto[];
      state.centrosCusto = [] as IDTOPlanoContaCategoriaCentroCusto[];
      if (props.planoContas > 0) {
        state.categoria.codigoPlanoConta = props.planoContas;
      }
    }

    async function atualizarMascaraConta() {
      state.mascaraConta = UtilitarioMascara.obterMascaras(props.mascaraPlanoContas);
    }

    function abrirCentrosCustos() {
      state.mostrarCentrosCusto = true;
    }

    function obterCentrosCustoFormatados() {
      state.categoria.centrosCustos = [] as IPlanoContaCategoriaCentroCusto[];
      if (UtilitarioGeral.validaLista(state.centrosCusto)) {
        state.centrosCusto.forEach((c) => {
          const centroCusto = {} as IPlanoContaCategoriaCentroCusto;
          centroCusto.codigo = c.codigo;
          centroCusto.codigoCentroCusto = c.codigoCentroCusto;
          centroCusto.codigoPlanoContaCategoria = props.operacao.codigoRegistro;
          centroCusto.proporcao = c.proporcao;
          state.categoria.centrosCustos.push(centroCusto);
        });
      }
    }

    async function obterProximoNumeroConta(categoriaPaiAlterada: boolean) {
      if (categoriaPaiAlterada) {
        state.categoriaPaiAlterada = true;
      }
      state.categoria.numero = '';
      const retornoNumeroConta = await servicoPlanoContas.proximoNumeroCategoria(props.planoContas, state.categoria.codigoPlanoContaCategoriaPai);
      if (UtilitarioGeral.objetoValido(retornoNumeroConta)) {
        if (retornoNumeroConta.sucesso) {
          state.categoria.numero = retornoNumeroConta.numero;
        } else {
          apresentarMensagemAlerta(retornoNumeroConta.mensagem);
        }
      }
      if (UtilitarioGeral.valorValido(state.categoria.numero)) {
        state.categoria.tipo = state.categoria.numero.length === props.mascaraPlanoContas.replaceAll('.', '').length ? 'A' : 'S';
      }
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      obterCentrosCustoFormatados();
      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoPlanoContas.incluirCategoria(state.categoria);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoPlanoContas.alterarCategoria(state.categoria);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          await obterProximoNumeroConta(false);
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }
    async function obterCategoriaPlanoConta(codigo: number) {
      state.categoria = await servicoPlanoContas.obterCategoria(codigo, props.operacao.empresaSelecionada);
      state.centrosCusto = await servicoPlanoContas.obterCentrosCustos(codigo, props.operacao.empresaSelecionada);
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      await atualizarMascaraConta();
      if (modalBase.computedVisivel) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          modalBase.textoBotaoSalvar = 'Salvar alterações';
          modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          await obterCategoriaPlanoConta(props.operacao.codigoRegistro);
        } else {
          if (UtilitarioGeral.validaCodigo(props.operacao.codigoRegistroDuplicar)) {
            await obterCategoriaPlanoConta(props.operacao.codigoRegistroDuplicar);
            state.categoria.codigo = 0;
            state.categoria.contaReduzida = 0;

            for (let i = 0; i < state.categoria.centrosCustos.length; (i += 1)) {
              state.categoria.centrosCustos[i].codigo = 0;
              state.categoria.centrosCustos[i].codigoPlanoContaCategoria = 0;
            }
          }

          if (UtilitarioGeral.validaCodigo(props.operacao.codigoRegistroPai)) {
            state.categoria.codigoPlanoContaCategoriaPai = props.operacao.codigoRegistroPai;
          }
          await obterProximoNumeroConta(false);
          modalBase.textoBotaoSalvar = 'Concluir cadastro';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
        }
      }
      telaBase.carregando = false;
    });

    function verificaApresentacaoCategoriaAnalitica() : boolean {
      return (state.categoria.tipo === 'A' && state.categoria.codigo > 0);
    }

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      abrirCentrosCustos,
      apresentarRetornoRequisicao,
      objetoInicial,
      EPermissaoDados,
      atualizarMascaraConta,
      verificaApresentacaoCategoriaAnalitica,
      obterProximoNumeroConta,
    };
  },
});
