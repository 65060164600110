import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import storeSistema from '@/store/storeSistema';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import {
  IFormaPagamento, IFormaPagamentoTabelaPreco, IFormaPagamentoTipoMovimento, IFormaPagamentoTipoDocumentoFinanceiro,
} from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';
import { EMeioPagamento } from '@/models/Enumeradores/Cadastros/Financeiro/EMeioPagamento';

/**
 * Serviço de Formas de Pagamento
 * Fornece todas regras de negócios e lógicas relacionado a Forma de pagamento.
 */

class ServicoFormaPagamento implements IServicoBase<IFormaPagamento> {
    endPoint = 'formas-pagamentos';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(FormaArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, FormaArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IFormaPagamento): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapidaAvancada(parametrosConsultaRapida: IParametrosConsultaRapida, tipoMovimento: number): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida/tipo-movimento/${tipoMovimento}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterBancoConsultaRapida(codigoConta: number, empresas?: number[]): Promise<IItemConsultaRapida> {
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        valor: codigoConta.toString(), filtrarPorCodigo: true, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(empresas)) {
        parametrosConsultaRapida.empresas = empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      const listaBancos = await this.consultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaLista(listaBancos)) {
        return listaBancos[0];
      }

      return {} as IItemConsultaRapida;
    }

    public async incluir(conta: IFormaPagamento): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, conta);
      return result.data;
    }

    public async alterar(conta: IFormaPagamento): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${conta.codigo}`, conta);
      return result.data;
    }

    public async obter(codigo: number): Promise<IFormaPagamento> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obterTiposMovimento(codigo: number, empresa: number): Promise<IFormaPagamentoTipoMovimento[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos-movimento/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterTabelasPreco(codigo: number, empresa: number): Promise<IFormaPagamentoTabelaPreco[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tabelas-preco/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterTiposDocumentos(codigo: number, empresa: number): Promise<IFormaPagamentoTipoDocumentoFinanceiro[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos-documentos/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterFormaPagamentoAtivaPorEmpresaMovimentoEMeioPagamento(empresa: number, tipoMovimento:ETipoMovimentoComercial, meioPagamento:EMeioPagamento): Promise<IFormaPagamento> {
      const result: any = await this.apiERP.get(`${this.endPoint}/empresa/${empresa}/tipoMovimento/${tipoMovimento}/meioPagamento/${meioPagamento}`);
      return result.data;
    }
}
export default ServicoFormaPagamento;
