
import { computed, defineComponent, reactive } from 'vue';
import { IConfiguracaoInicial } from '@/models/Entidades/MeuSistema/IConfiguracaoInicial';
import { EEtapaConfiguracaoInicial } from '@/models/Enumeradores/MeuSistema/EEtapaConfiguracaoInicial';
import EmpresaDadosCadastraisModal from '@/views/MeuSistema/Empresas/EmpresaDadosCadastraisModal.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import storeSistema from '@/store/storeSistema';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IDTOUsuarioPermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoes';

export default defineComponent({
  name: 'ConfiguracaoInicialEmpresa',
  props: {
    configuracao: {
      type: Object as () => IConfiguracaoInicial,
      required: true,
    },
  },
  components: {
    EmpresaDadosCadastraisModal,
  },
  emits: ['update:configuracao', 'atualizarConfiguracao'],
  setup(props, { emit }) {
    const state = reactive({
      exibirDados: false,
      carregando: false,
      operacao: {} as ITelaOperacao,
    });

    const computedConfiguracao = computed({
      get: () => props.configuracao,
      set: (val: IConfiguracaoInicial) => {
        emit('update:configuracao', val);
      },
    });

    function abrirEmpresa() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.codigoRegistro = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Visualizar;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      const permissao = {} as IDTOUsuarioPermissoes;
      permissao.codigoEmpresa = state.operacao.empresaSelecionada;
      permissao.identificadorPermissao = 'AUT_PAINEL_EMPRESAS';
      state.operacao.listaPermissoes.push(permissao);
      state.exibirDados = true;
    }

    function concluir() {
      state.carregando = true;
      emit('atualizarConfiguracao');
    }

    return {
      props,
      state,
      EEtapaConfiguracaoInicial,
      computedConfiguracao,
      concluir,
      abrirEmpresa,
    };
  },
});
