
import { computed, defineComponent, reactive } from 'vue';
import draggable from 'vuedraggable';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { IEtapaProcessoVendas } from '@/models/Entidades/Cadastros/Vendas/IProcessoVendas';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import Icone from '@/core/components/Icone.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'ProcessoVendasOrdenacaoEtapasModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    etapas: {
      type: Array as () => IEtapaProcessoVendas[],
      required: true,
    },
  },
  components: {
    Icone,
    MensagemSemDados,
    draggable,
  },
  emit: ['update:etapas'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      etapasFiltradas: [] as number[],
      subprocessoSelecionado: '',
    });

    const computedEtapas = computed({
      get: () => props.etapas,
      set: (valor: IEtapaProcessoVendas[]) => {
        emit('update:etapas', valor);
      },
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      UtilitarioGeral,
      computedEtapas,
    };
  },
});
