
import { defineComponent, reactive, onBeforeMount } from 'vue';
import router from '@/router';
import storeSistema from '@/store/storeSistema';
import ServicoPAS from '@/servicos/ServicoPAS';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { IDTOAssinaturaDetalhe } from '@/models/DTO/PainelAdministrativo/IDTOAssinaturaDetalhe';
import { EStatusAssinatura } from '@/models/Enumeradores/MeuSistema/EStatusAssinatura';
import GerenteAutenticacao from '../gerentes/GerenteAutenticacao';
import QuadroLogin from './QuadroLogin.vue';

export default defineComponent({
  name: 'IdentificarAmbiente',
  components: {
    QuadroLogin,
  },

  setup() {
    const gerenteAutenticacao = new GerenteAutenticacao();

    const state = reactive({
      chaveParceiro: '',
      mensagemAlerta: '',
    });

    onBeforeMount(async () => {
      if (gerenteAutenticacao.verificaAutenticacao()) {
        router.push({ name: 'MinhaDashboard' });
      }

      if (storeSistema.state.configuracaoApp.tipoAmbiente === 1) {
        gerenteAutenticacao.salvarIdentificadorContratante('1');
        router.push({ name: 'Login' });
      }
    });

    async function identificarAmbiente() {
      if (!state.chaveParceiro) {
        state.mensagemAlerta = 'Digite a sua chave de parceiro.';
        return;
      }

      let detalhesAssinatura: IDTOAssinaturaDetalhe = {} as IDTOAssinaturaDetalhe;
      if (storeSistema.state.configuracaoApp.tipoAmbiente === 2) {
        detalhesAssinatura = await new ServicoPAS().obterDetalhesAssinatura(state.chaveParceiro);
        if (!UtilitarioGeral.objetoValido(detalhesAssinatura) || detalhesAssinatura.codigoContratante === 0) {
          router.push({ name: 'AmbienteNaoEncontrado' });
          return;
        }
        storeSistema.mutations.atualizarUrlApiESignalR(detalhesAssinatura.urlApi);

        if (detalhesAssinatura.status !== EStatusAssinatura.Ativa && detalhesAssinatura.status !== EStatusAssinatura.Teste) {
          router.push({ name: 'AssinaturaInativa' });
        }

        gerenteAutenticacao.salvarIdentificadorContratante(detalhesAssinatura.codigoContratante.toString());
        router.push({ name: 'Login' });
      }
    }

    return {
      state,
      storeSistema,
      identificarAmbiente,
    };
  },
});
