
import {
  computed, defineComponent, reactive,
} from 'vue';

export default defineComponent({
  name: 'SelecionarCor',
  props: {
    idComponente: {
      type: String,
      default: '1',
    },
    cor: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
  },
  emits: ['update:cor'],
  setup(props, { emit }) {
    const state = reactive({
      coresPredefinidas: ['#db2828', '#f2711c', '#fbbd08', '#b5cc18', '#21ba45', '#00b5ad', '#0093d3', '#0053ab', '#6435C9', '#a333C8', '#e03997', '#000000'],
      backgroundOutro: 'background: conic-gradient(#db2828,#f2711c, #fbbd08, #b5cc18, #21ba45, #00b5ad, #0093d3, #0053ab, #6435c9, #a333c8, #e03997);',
      classOutro: 'ss-span-outro',
    });

    const computedCor = computed({
      get: () => props.cor,
      set: (val: string) => {
        emit('update:cor', val);
      },
    });

    function valorAlterado() {
      if (state.coresPredefinidas.includes(computedCor.value)) {
        state.classOutro = 'ss-span-outro';
        state.backgroundOutro = 'background: conic-gradient(#db2828,#f2711c, #fbbd08, #b5cc18, #21ba45, #00b5ad, #0093d3, #0053ab, #6435c9, #a333c8, #e03997);';
      } else {
        state.classOutro = 'ss-span-outro-selecionado';
        state.backgroundOutro = `background: ${computedCor.value};`;
      }
    }

    return {
      props,
      state,
      computedCor,
      valorAlterado,
    };
  },
});
