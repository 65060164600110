
import { defineComponent, onBeforeMount, reactive } from 'vue';
import Icone from '../../components/Icone.vue';
import storeSistema from '@/store/storeSistema';
import ServicoNotificacao from '@/servicos/MeuSistema/ServicoNotificacao';
import { IDTONotificacao } from '@/models/DTO/MeuSistema/Notificacoes/IDTONotificacao';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ETipoNotificacao } from '@/models/Enumeradores/MeuSistema/Notificacoes/ETipoNotificacao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import router from '@/router';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

// Componente utilizado Barra superior do layout: ERP
export default defineComponent({
  components: {
    Icone,
  },
  name: 'Notificacoes',
  setup() {
    const servicoNotificacao = new ServicoNotificacao();
    const state = reactive({
      notificacoes: [] as IDTONotificacao[],
      buscandoNotificacoes: false,
      exibirNotificacoes: false,
    });

    async function obterNotificacoesAtualizadas() {
      state.exibirNotificacoes = true;
      state.buscandoNotificacoes = true;

      state.buscandoNotificacoes = false;
    }

    onBeforeMount(async () => {
      state.notificacoes = await servicoNotificacao.obterNotificacoesUsuarioBarraSuperior();
    });

    async function acessarLinkNotificacao(notificacao: IDTONotificacao) {
      state.exibirNotificacoes = false;

      if (!notificacao.lida) {
        const retornoConfirmacao = await new ServicoNotificacao().confirmarLeitura(notificacao.codigo);
        if (retornoConfirmacao.status === EStatusRetornoRequisicao.Sucesso) {
          storeSistema.mutations.diminuirNotificacaoNaoLida();
        }
      }

      if (UtilitarioGeral.valorValido(notificacao.parametros)) {
        if (notificacao.parametros.includes('?')) {
          // Extraindo todas as queries da string
          const urlParams = new URLSearchParams(notificacao.parametros);
          const queryObject: Record<string, string> = {};

          // Iterando sobre todas as entradas da query string e adicionando ao objeto
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of urlParams.entries()) {
            queryObject[key] = value;
          }
          router.push({ path: `/${notificacao.rotaRecurso}`, query: queryObject });
        } else {
          router.push({ path: `/${notificacao.rotaRecurso}${notificacao.parametros}` });
        }
      } else {
        router.push({ path: `/${notificacao.rotaRecurso}` });
      }
    }
    function obterCorNotificacao(tipo: number): string {
      let cor = '#2196f3';
      if (tipo === ETipoNotificacao.Normal) {
        cor = '#2196f3';
      } else if (tipo === ETipoNotificacao.Importante) {
        cor = '#ffc107';
      } else if (tipo === ETipoNotificacao.Urgente) {
        cor = '#f44336';
      }
      return cor;
    }

    function visualizarTodasNotificacoes() {
      state.exibirNotificacoes = false;
      router.push({ name: 'Notificacoes' });
    }
    function obterAbreviacaoRecurso(recurso: string): string {
      let abreviacao = '';
      if (UtilitarioGeral.valorValido(recurso)) {
        abreviacao = recurso.length > 3 ? recurso.substring(0, 3) : recurso.substring(0, 1);
      }
      return abreviacao;
    }
    return {
      storeSistema,
      state,
      obterNotificacoesAtualizadas,
      obterCorNotificacao,
      obterAbreviacaoRecurso,
      acessarLinkNotificacao,
      visualizarTodasNotificacoes,
      UtilitarioData,
    };
  },
});
