
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { IVersaoSistema } from '@/models/Entidades/MeuSistema/IVersaoSistema';
import SobreVersaoSistema from './SobreVersaoSistema.vue';

export default defineComponent({
  name: 'SobreModal',
  props: {
    visivel: {
      type: Boolean,
    },
  },
  components: {
    Icone,
    SobreVersaoSistema,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoSistema = new ServicoSistema();

    const state = reactive({
      versaoSistema: {} as IVersaoSistema,
    });

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        state.versaoSistema = await servicoSistema.obterVersaoSistema();
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
    };
  },
});
