
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QuadroLogin',
  setup() {
    return {
    };
  },
});
