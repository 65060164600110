
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import ConfiguracaoInicialEmpresa from '@/components/MeuSistema/ConfiguracaoInicial/ConfiguracaoInicialEmpresa.vue';
import ConfiguracaoInicialEquipe from '@/components/MeuSistema/ConfiguracaoInicial/ConfiguracaoInicialEquipe.vue';
import ConfiguracaoInicialProdutos from '@/components/MeuSistema/ConfiguracaoInicial/ConfiguracaoInicialProdutos.vue';
import ConfiguracaoInicialFinanceiro from '@/components/MeuSistema/ConfiguracaoInicial/ConfiguracaoInicialFinanceiro.vue';
import ConfiguracaoInicialVendas from '@/components/MeuSistema/ConfiguracaoInicial/ConfiguracaoInicialVendas.vue';
import ServicoConfiguracaoInicial from '@/servicos/MeuSistema/ServicoConfiguracaoInicial';
import { useTelaBase } from '@/core/composables/TelaBase';
import storeSistema from '@/store/storeSistema';
import { IConfiguracaoInicial } from '@/models/Entidades/MeuSistema/IConfiguracaoInicial';
import { EEtapaConfiguracaoInicial } from '@/models/Enumeradores/MeuSistema/EEtapaConfiguracaoInicial';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioMatematico from '@/core/utilitarios/UtilitarioMatematico';
import router from '@/router';

export default defineComponent({
  name: 'ConfiguracaoInicialEtapas',
  components: {
    ConfiguracaoInicialEmpresa,
    ConfiguracaoInicialEquipe,
    ConfiguracaoInicialProdutos,
    ConfiguracaoInicialFinanceiro,
    ConfiguracaoInicialVendas,
  },
  setup(props) {
    const { telaBase, apresentarMensagemErro } = useTelaBase();
    const servicoConfiguracaoInicial = new ServicoConfiguracaoInicial();

    const state = reactive({
      configuracao: {} as IConfiguracaoInicial,
    });

    function popularDadosIniciais() {
      if (state.configuracao.etapaAtual <= EEtapaConfiguracaoInicial.Produtos) {
        state.configuracao.usarCusto = true;
        state.configuracao.usarCustoMedio = true;
        state.configuracao.usarPrecoAtacado = true;
        state.configuracao.usarPrecoVarejo = true;
        state.configuracao.usarUnidadeAp = true;
        state.configuracao.usarUnidadePt = true;
        state.configuracao.usarUnidadeCx = true;
        state.configuracao.usarUnidadeGl = true;
        state.configuracao.usarUnidadePc = true;
        state.configuracao.usarUnidadeRl = true;
        state.configuracao.usarUnidadeFr = true;
        state.configuracao.usarUnidadePr = true;
        state.configuracao.usarUnidadeMt = true;
        state.configuracao.usarUnidadeKg = true;
        state.configuracao.usarUnidadeMi = true;
        state.configuracao.usarUnidadeLt = true;
        state.configuracao.usarUnidadeKt = true;
        state.configuracao.usarUnidadeCx10 = true;
        state.configuracao.usarUnidadeCx20 = true;
        state.configuracao.usarUnidadeUn = true;
        state.configuracao.usarUnidadeCj = true;
        state.configuracao.usarEstoquePadrao = true;
        state.configuracao.usarTabelaPrecoPadrao = true;
        state.configuracao.usarMarcaPadrao = true;
        state.configuracao.usarCategoriaProdutoPadrao = true;
      }
      if (state.configuracao.etapaAtual <= EEtapaConfiguracaoInicial.Financeiro) {
        state.configuracao.usarDinheiro = true;
        state.configuracao.usarCheque = true;
        state.configuracao.usarCartaoCredito = true;
        state.configuracao.usarCartaoDebito = true;
        state.configuracao.usarValePresente = true;
        state.configuracao.usarBoleto = true;
        state.configuracao.usarPix = true;
        state.configuracao.usarDeposito = true;
        state.configuracao.usarTransferenciaBancaria = true;
        state.configuracao.usarCreditoCliente = true;
        state.configuracao.usarCreditoFornecedor = true;
        state.configuracao.usarFormaPagamentoPadrao = true;
        state.configuracao.usarPlanoContasPadrao = true;
        state.configuracao.usarCentroCustoPadrao = true;
        state.configuracao.usarContaPadrao = true;
      }

      if (state.configuracao.etapaAtual <= EEtapaConfiguracaoInicial.Vendas) {
        state.configuracao.usarProcessoVendasPadrao = true;
        state.configuracao.usarClientePadrao = true;
      }
      state.configuracao.progresso = UtilitarioMatematico.calcularPorcentagem(5, state.configuracao.etapaAtual - 1);
    }

    async function atualizarConfiguracao() {
      const retorno = await servicoConfiguracaoInicial.atualizarConfiguracaoInicial(state.configuracao);
      if (retorno.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemErro(retorno.mensagem);
      }
      if (state.configuracao.etapaAtual !== EEtapaConfiguracaoInicial.Vendas) {
        state.configuracao.etapaAtual += 1;
        state.configuracao.progresso = UtilitarioMatematico.calcularPorcentagem(5, state.configuracao.etapaAtual - 1);
      } else {
        router.push({ name: 'MinhaDashboard' });
      }
    }

    async function pularConfiguracao() {
      Modal.confirm({
        title: 'Deseja pular a configuração inicial?',
        content: 'Você poderá realizar as mesmas alterações propostas aqui indo diretamente nas telas de cadastros.',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          state.configuracao.concluido = true;
          await servicoConfiguracaoInicial.atualizarConfiguracaoInicial(state.configuracao);
          router.push({ name: 'MinhaDashboard' });
        },
      });
    }

    onBeforeMount(async () => {
      telaBase.carregando = true;
      if (storeSistema.state.carregando) {
        state.configuracao = {} as IConfiguracaoInicial;
        state.configuracao.progresso = 0;
      } else {
        state.configuracao = await servicoConfiguracaoInicial.obterConfiguracaoInicial(storeSistema.getters.codigoEmpresaOperacao());
        if (state.configuracao.concluido) {
          router.push({ name: 'MinhaDashboard' });
        }
      }
      popularDadosIniciais();
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      storeSistema,
      EEtapaConfiguracaoInicial,
      atualizarConfiguracao,
      pularConfiguracao,
    };
  },
});
