
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import SelecionarTipoMovimentoComercial from '@/components/Compartilhados/SelecionarTipoMovimentoComercial.vue';
import ServicoFormaPagamento from '@/servicos/Cadastros/Financeiro/ServicoFormaPagamento';
import {
  IFormaPagamento, IFormaPagamentoTabelaPreco, IFormaPagamentoTipoMovimento, IFormaPagamentoTipoDocumentoFinanceiro,
} from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import FormaPagamentoTiposPagamento from '@/components/Cadastros/Financeiro/FormasPagamento/FormaPagamentoTiposPagamento.vue';
import SelecionarTabelaPreco from '@/components/Cadastros/Precificacao/TabelasPreco/SelecionarTabelaPreco.vue';
import SelecionarTipoOperacao from '@/components/Fiscal/SelecionarTipoOperacao.vue';
import { ETipoOperacao } from '@/models/Enumeradores/Fiscal/ETipoOperacao';
import { ETipoMovimentoFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoMovimentoFinanceiro';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'FormaPagamentoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    Icone,
    SelecionarTipoMovimentoComercial,
    FormaPagamentoTiposPagamento,
    SelecionarTabelaPreco,
    SelecionarTipoOperacao,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas, defineEmpresasSelecionadasCadastroCompartilhado,
      preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro, defineTextoPadraoBotoes,
    } = useModalBase(props, emit);
    const servicoFormaPagamento = new ServicoFormaPagamento();
    telaBase.identificadorRecurso = 'CADASTRO_FORMAS_PAGAMENTO';
    telaBase.identificadorPermissao = 'PER_CADASTRO_FORMAS_PAGAMENTO';

    const state = reactive({
      formaPagamento: {} as IFormaPagamento,
      tiposMovimento: [] as number[],
      tabelasPrecos: [] as number[],
      codigosTiposUtilizados: [] as number[],
    });

    function limparTela() {
      state.formaPagamento = {} as IFormaPagamento;
      state.formaPagamento.codigoEmpresa = props.operacao.empresaSelecionada;
      state.formaPagamento.ativo = true;
      state.formaPagamento.tipoOperacao = ETipoOperacao.Entrada;
      state.formaPagamento.tiposMovimento = [] as IFormaPagamentoTipoMovimento[];
      state.formaPagamento.tiposDocumentosFinanceiro = [] as IFormaPagamentoTipoDocumentoFinanceiro[];
      state.formaPagamento.tabelasPreco = [] as IFormaPagamentoTabelaPreco[];
      state.tiposMovimento = [] as number[];
      state.tabelasPrecos = [] as number[];
      state.codigosTiposUtilizados = [] as number[];
    }

    function alterarTipoOperacao() {
      state.tiposMovimento = [] as number[];
      state.formaPagamento.tiposDocumentosFinanceiro.forEach((t, index) => {
        if (state.formaPagamento.tipoOperacao === ETipoOperacao.Entrada) {
          state.formaPagamento.tiposDocumentosFinanceiro[index].tipoMovimentoCaixa = ETipoMovimentoFinanceiro.Pagamento;
          state.formaPagamento.tiposDocumentosFinanceiro[index].tipoTituloCarteira = ETipoTituloFinanceiro.Pagar;
        } else {
          state.formaPagamento.tiposDocumentosFinanceiro[index].tipoMovimentoCaixa = ETipoMovimentoFinanceiro.Recebimento;
          state.formaPagamento.tiposDocumentosFinanceiro[index].tipoTituloCarteira = ETipoTituloFinanceiro.Receber;
        }
      });
    }

    function obterTiposMovimentos() {
      const tiposMovimento = [] as IFormaPagamentoTipoMovimento[];
      state.tiposMovimento.forEach((t) => {
        const tipoMovimento = {} as IFormaPagamentoTipoMovimento;

        tipoMovimento.codigoFormaPagamento = props.operacao.codigoRegistro;
        tipoMovimento.tipo = t;
        tiposMovimento.push(tipoMovimento);
      });
      return tiposMovimento;
    }

    function obterTabelasPrecos() {
      const tabelas = [] as IFormaPagamentoTabelaPreco[];
      state.tabelasPrecos.forEach((t) => {
        const tabela = {} as IFormaPagamentoTabelaPreco;
        tabela.codigoFormaPagamento = props.operacao.codigoRegistro;
        tabela.codigoTabelaPreco = t;
        tabelas.push(tabela);
      });
      return tabelas;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      state.formaPagamento.tiposMovimento = obterTiposMovimentos();
      state.formaPagamento.tabelasPreco = obterTabelasPrecos();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoFormaPagamento.incluir(state.formaPagamento);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoFormaPagamento.alterar(state.formaPagamento);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          limparTela();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    function duplicarRegistro() {
      const telaOperacao: ITelaOperacao = props.operacao;
      telaOperacao.codigoRegistro = 0;
      telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      modalBase.computedOperacao = telaOperacao;
      state.formaPagamento.codigo = 0;
      state.formaPagamento.dataCriacao = '';
      state.formaPagamento.dataAlteracao = '';
      state.formaPagamento.tiposDocumentosFinanceiro.forEach((t, index) => {
        state.formaPagamento.tiposDocumentosFinanceiro[index].codigo = 0;
        state.formaPagamento.tiposDocumentosFinanceiro[index].codigoFormaPagamento = 0;
        state.formaPagamento.tiposDocumentosFinanceiro[index].parcelamentos.forEach((p, indexParcela) => {
          state.formaPagamento.tiposDocumentosFinanceiro[index].parcelamentos[indexParcela].codigo = 0;
          state.formaPagamento.tiposDocumentosFinanceiro[index].parcelamentos[indexParcela].codigoFormaPagamentoTipoDocumentoFinanceiro = 0;
        });
      });
      apresentarMensagemSucesso('Modo alterado para novo registro!');
    }

    async function preencherFormaPagamento() {
      state.formaPagamento = await servicoFormaPagamento.obter(props.operacao.codigoRegistro);
      state.formaPagamento.tiposMovimento = await servicoFormaPagamento.obterTiposMovimento(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      state.tiposMovimento = [] as number[];
      if (UtilitarioGeral.validaLista(state.formaPagamento.tiposMovimento)) {
        state.formaPagamento.tiposMovimento.forEach((t) => {
          state.tiposMovimento.push(t.tipo);
        });
      }

      state.tabelasPrecos = [] as number[];
      state.formaPagamento.tabelasPreco = await servicoFormaPagamento.obterTabelasPreco(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      state.formaPagamento.tabelasPreco.forEach((t) => {
        state.tabelasPrecos.push(t.codigoTabelaPreco);
      });

      state.formaPagamento.tiposDocumentosFinanceiro = await servicoFormaPagamento.obterTiposDocumentos(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      if (UtilitarioGeral.validaLista(state.formaPagamento.tiposDocumentosFinanceiro)) {
        state.formaPagamento.tiposDocumentosFinanceiro.forEach((t) => {
          state.codigosTiposUtilizados.push(t.codigoTipoDocumentoFinanceiro);
        });
      }

      telaBase.empresasSelecionadas = [];
      telaBase.empresasSelecionadas.push(state.formaPagamento.codigoEmpresa);
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        if (props.operacao.requisicaoSistema) {
          servicoFormaPagamento.requisicaoSistema();
        } else {
          if (props.operacao.listaPermissoesDados.length > 0) {
            await preencherPermissoesDados(props.operacao.listaPermissoesDados);
          } else {
            await obterPermissoes(ETipoPermissao.Dados);
          }
          await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);
        }

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          await preencherFormaPagamento();
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }
      }

      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      verificaConceitoParaApresentarEmpresas();
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      ETipoOperacao,
      apresentarRetornoRequisicao,
      salvar,
      duplicarRegistro,
      alterarTipoOperacao,
    };
  },
});
