import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_meus_atalhos = _resolveComponent("meus-atalhos")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_modal, {
      keyboard: false,
      visible: _ctx.computedVisualizar,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedVisualizar) = $event))
    }, {
      title: _withCtx(() => [
        _createTextVNode("Meus Atalhos")
      ]),
      closeIcon: _withCtx(() => [
        _createVNode(_component_icone, { nome: "voltar" })
      ]),
      footer: _withCtx(() => []),
      default: _withCtx(() => [
        _createVNode(_component_meus_atalhos)
      ]),
      _: 1
    }, 8, ["visible"]),
    _createElementVNode("a", {
      style: {"color":"#232a56"},
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.abrirFavoritos && _ctx.abrirFavoritos(...args)))
    }, [
      _createVNode(_component_icone, {
        style: {"width":"35px","height":"35px","margin-top":"11"},
        nome: "estrela"
      })
    ])
  ], 64))
}