
import { defineComponent, reactive, computed } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import { IPlanoContaNivel } from '@/models/Entidades/Cadastros/PlanosContas/IPlanoConta';

export default defineComponent({
  name: 'ApresentarNivelPlanoContas',
  props: {
    indice: {
      type: Number,
      required: true,
    },
    nivel: {
      type: Object as () => IPlanoContaNivel,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
  },
  emits: ['update:nivel', 'valorAtualizado', 'removerNivel'],
  setup(props, { emit }) {
    const state = reactive({
      carregando: false,
      valor: 1,
    });

    const computedNivel = computed({
      get: () => props.nivel,
      set: (valor: IPlanoContaNivel) => {
        emit('update:nivel', valor);
      },
    });

    function aumentarValor() {
      computedNivel.value.valor += 1;
      emit('valorAtualizado');
    }

    function diminuirValor() {
      if (computedNivel.value.valor > 1) {
        computedNivel.value.valor -= 1;
        emit('valorAtualizado');
      }
    }

    function removerNivel() {
      emit('removerNivel', props.nivel);
    }

    return {
      props,
      state,
      computedNivel,
      aumentarValor,
      diminuirValor,
      removerNivel,
    };
  },
});
