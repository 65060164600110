
import {
  defineComponent, reactive,
} from 'vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarTipoCusto from '@/components/Cadastros/Precificacao/SelecionarTipoCusto.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { ITipoPreco } from '@/models/Entidades/Cadastros/Precificacao/ITipoPreco';
import { IDTOProdutoDefinicao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicao';
import { ITabelaPrecoEstrutura, ITabelaPrecoItemProduto } from '@/models/Entidades/Cadastros/Precificacao/ITabelaPreco';
import ServicoTabelaPreco from '@/servicos/Cadastros/Precificacao/ServicoTabelaPreco';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOAlteracaoPrecoProduto } from '@/models/DTO/Cadastros/Precificacao/IDTOAlteracaoPrecoProduto';
import { useModalBase } from '@/core/composables/ModalBase';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'AlteracaoPrecoProdutoModal',
  props: {
    codigoTabelaPreco: {
      type: Number,
      required: true,
    },
    tiposPrecos: {
      type: Array as () => ITipoPreco[],
      required: true,
    },
    estruturas: {
      type: Array as () => ITabelaPrecoEstrutura[],
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    CampoNumerico,
    SelecionarTipoCusto,
  },
  emits: ['precosAtualizados'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso } = useTelaBase();

    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const servicoTabelaPreco = new ServicoTabelaPreco();

    const state = reactive({
      apresentarModal: false,
      produtoDefinicao: {} as IDTOProdutoDefinicao,
      produtoPrecos: [] as ITabelaPrecoItemProduto[],
    });

    function apresentarAlteracaoPreco(produtoDefinicao: IDTOProdutoDefinicao, produtoPrecos:ITabelaPrecoItemProduto[]) {
      state.produtoDefinicao = produtoDefinicao;

      state.produtoPrecos = [];
      if (UtilitarioGeral.validaLista(produtoPrecos)) {
        state.produtoPrecos = UtilitarioGeral.instanciaObjetoLocal(produtoPrecos);
        state.apresentarModal = true;
      }
    }
    function obtemIndexPrecoTabela(coditoTipoPreco:number):number {
      if (UtilitarioGeral.validaLista(state.produtoPrecos)) {
        const index = state.produtoPrecos.findIndex((c) => c.codigoTipoPreco === coditoTipoPreco);
        if (index >= 0) { return index; }
      }

      return 0;
    }

    function obterDescricaoTipoPreco(codigoTipoPreco:number):string {
      const tipoPreco = props.tiposPrecos.find((c) => c.codigo === codigoTipoPreco);
      if (tipoPreco !== undefined) {
        return tipoPreco.descricao;
      }

      return '';
    }

    function obterQuantidadeCasasTipoPreco(codigoTipoPreco:number):number {
      const tipoPreco = props.tiposPrecos.find((c) => c.codigo === codigoTipoPreco);
      if (tipoPreco !== undefined) {
        return tipoPreco.casasDecimais;
      }

      return 2;
    }

    async function salvarAlteracaoPreco() {
      const alteracaoPreco:IDTOAlteracaoPrecoProduto = {} as IDTOAlteracaoPrecoProduto;
      alteracaoPreco.precos = [];
      alteracaoPreco.precos = state.produtoPrecos;

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      retorno = await servicoTabelaPreco.alterarPrecosProduto(props.codigoTabelaPreco, state.produtoDefinicao.codigoDefinicao, alteracaoPreco);
      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        emit('precosAtualizados', alteracaoPreco.precos);
        state.apresentarModal = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    return {
      props,
      state,
      telaBase,
      modalBase,
      obtemIndexPrecoTabela,
      obterDescricaoTipoPreco,
      obterQuantidadeCasasTipoPreco,
      apresentarAlteracaoPreco,
      salvarAlteracaoPreco,
    };
  },
});
