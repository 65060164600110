import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import storeSistema from '@/store/storeSistema';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IConta, IContaUsuario } from '@/models/Entidades/Cadastros/Financeiro/IConta';
import { IDTOConta } from '@/models/DTO/Cadastros/Financeiro/IDTOConta';
import { ETipoConta } from '@/models/Enumeradores/Cadastros/Financeiro/ETipoConta';
import { IDTOContaDetalhe } from '@/models/DTO/Cadastros/Financeiro/IDTOContaDetalhe';
import { IConfiguracaoBoletos } from '@/models/Entidades/Cadastros/Financeiro/IConfiguracoesBoletos';

/**
 * Serviço de Contas
 * Fornece todas regras de negócios e lógicas relacionado a conta.
 */

class ServicoConta implements IServicoBase<IConta> {
    endPoint = 'contas';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOConta): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, contasReceber?: boolean, contasPagar?: boolean, verificarOperador?: boolean, usuario?: number): Promise<IItemConsultaRapida[]> {
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);

      if (contasReceber !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `contasReceber=${contasReceber}`;
      }

      if (contasPagar !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `contasPagar=${contasPagar}`;
      }

      if (verificarOperador !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `verificarOperador=${verificarOperador}`;
      }

      if (usuario !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `usuario=${usuario}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async obterBancoConsultaRapida(codigoConta: number, empresas?: number[]): Promise<IItemConsultaRapida> {
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        valor: codigoConta.toString(), filtrarPorCodigo: true, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(empresas)) {
        parametrosConsultaRapida.empresas = empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      const listaBancos = await this.consultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaLista(listaBancos)) {
        return listaBancos[0];
      }

      return {} as IItemConsultaRapida;
    }

    public async incluir(conta: IConta): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, conta);
      return result.data;
    }

    public async alterar(conta: IConta): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${conta.codigo}`, conta);
      return result.data;
    }

    public async obter(codigo: number): Promise<IConta> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obterUsuarios(codigo: number, empresa: number): Promise<IContaUsuario[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/usuarios/empresa/${empresa}`);
      return result.data;
    }

    public async obterConfiguracaoBoletos(codigo: number, empresa: number): Promise<IConfiguracaoBoletos> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/configuracao-boletos/empresa/${empresa}`);
      return result.data;
    }

    public async obterTipoConta(codigo: number): Promise<ETipoConta> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/tipo`);
      return result.data;
    }

    public async obterDetalheConta(codigo: number): Promise<IDTOContaDetalhe> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/detalhe`);
      return result.data;
    }

    public async obterCodigoContaPadrao(empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/conta-padrao/empresa/${empresa}`);
      return result.data;
    }

    public async obterDescricao(codigo: number): Promise<string> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/descricao`);
      return result.data;
    }
}
export default ServicoConta;
