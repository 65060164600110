import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { ITipoCusto } from '@/models/Entidades/Cadastros/Precificacao/ITipoCusto';
import { IDTOTipoCusto } from '@/models/DTO/MeuSistema/Precificacao/IDTOTipoCusto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTODadosCalculoCustoProduto } from '@/models/DTO/Cadastros/Precificacao/IDTODadosCalculoCustoProduto';
import { IDTORetornoCalculoCustoProduto } from '@/models/DTO/Cadastros/Precificacao/IDTORetornoCalculoCustoProduto';

/**
 * Serviço de Tipo de custos
 * Fornece todas regras de negócios e lógicas relacionado a Tipo de custos.
 */

class ServicoTipoCusto implements IServicoBase<ITipoCusto> {
    endPoint = 'tipos-custos';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterTodosTiposCustos(empresas: number[]): Promise<ITipoCusto[]> {
      const parametrosAdicionais = this.apiERP.obterParametroEmpresas(empresas);
      const result: any = await this.apiERP.get(`${this.endPoint}/todos${parametrosAdicionais}`);
      return result.data;
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOTipoCusto): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(objeto: ITipoCusto): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
      return result.data;
    }

    public async alterar(objeto: ITipoCusto): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}`, objeto);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<ITipoCusto> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obterTiposCustosPorEmpresaTipoItem(empresas:number[], tipoItem:number, apenasAtivos:boolean): Promise<ITipoCusto[]> {
      const tiposCustos: ITipoCusto[] = [];
      const listaTipoCustos = await this.obterTodosTiposCustos(empresas);
      if (UtilitarioGeral.validaLista(listaTipoCustos)) {
        let listaTiposCustosFiltrados = listaTipoCustos.filter((c) => c.tipoItem === tipoItem);
        if (apenasAtivos) { listaTiposCustosFiltrados = listaTipoCustos.filter((c) => c.ativo === true); }
        listaTiposCustosFiltrados.forEach((tipoCusto) => {
          let adicionarTipoCusto = false;
          if (empresas.length === 1) {
            adicionarTipoCusto = tipoCusto.empresas.some((c) => c.codigoEmpresa === empresas[0]);
          } else {
            const empresasTipoCusto = tipoCusto.empresas.map((c) => c.codigoEmpresa);
            adicionarTipoCusto = empresas.some((v) => empresasTipoCusto.includes(v));
          }
          if (adicionarTipoCusto) { tiposCustos.push(tipoCusto); }
        });
      }
      return tiposCustos;
    }

    public async obterQtdCasasDecimais(codigo: number, empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/casas-decimais/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async calcularCustoProduto(dadosCalculo: IDTODadosCalculoCustoProduto): Promise<IDTORetornoCalculoCustoProduto[]> {
      const result: any = await this.apiERP.post(`${this.endPoint}/produtos/calcular`, dadosCalculo);
      return result.data;
    }
}
export default ServicoTipoCusto;
