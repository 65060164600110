
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { IConfiguracaoInicial } from '@/models/Entidades/MeuSistema/IConfiguracaoInicial';
import { EEtapaConfiguracaoInicial } from '@/models/Enumeradores/MeuSistema/EEtapaConfiguracaoInicial';
import TipoCustoModal from '@/views/Cadastros/Precificacao/TipoCustos/TipoCustoModal.vue';
import TipoPrecoModal from '@/views/Cadastros/Precificacao/TipoPrecos/TipoPrecoModal.vue';
import EstoqueModal from '@/views/Cadastros/Estoques/EstoqueModal.vue';
import TabelaPrecoModal from '@/views/Cadastros/Precificacao/TabelasPreco/TabelaPrecoModal.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import storeSistema from '@/store/storeSistema';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IDTOUsuarioPermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoes';
import { IDTOUsuarioPermissoesDados } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoesDados';
import SelecionarGenericoString from '@/core/components/Tela/SelecionarGenericoString.vue';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import MarcaModal from '@/views/Cadastros/Produtos/Marcas/MarcaModal.vue';
import CategoriaProdutoModal from '@/views/Cadastros/Produtos/CategoriasProdutos/CategoriaProdutoModal.vue';

export default defineComponent({
  name: 'ConfiguracaoInicialProdutos',
  props: {
    configuracao: {
      type: Object as () => IConfiguracaoInicial,
      required: true,
    },
  },
  components: {
    TipoCustoModal,
    TipoPrecoModal,
    EstoqueModal,
    TabelaPrecoModal,
    SelecionarGenericoString,
    MarcaModal,
    CategoriaProdutoModal,
  },
  emits: ['update:configuracao', 'atualizarConfiguracao'],
  setup(props, { emit }) {
    const servicoSistema = new ServicoSistema();

    const state = reactive({
      permissaoTipoCusto: false,
      permissaoTipoPreco: false,
      permissaoUnidade: false,
      permissaoEstoque: false,
      permissaoTabelaPreco: false,
      permissaoMarca: false,
      permissaoCategoria: false,
      optionsTipoCusto: [] as IOptionString[],
      optionsTipoPreco: [] as IOptionString[],
      optionsUnidade: [] as IOptionString[],
      tiposCustoSelecionados: [] as string[],
      tiposPrecoSelecionados: [] as string[],
      unidadesSelecionadas: [] as string[],
      exibirTipoCusto: false,
      exibirTipoPreco: false,
      exibirEstoque: false,
      exibirTabelaPreco: false,
      exibirMarca: false,
      exibirCategoria: false,
      carregando: false,
      operacao: {} as ITelaOperacao,
    });

    const computedConfiguracao = computed({
      get: () => props.configuracao,
      set: (val: IConfiguracaoInicial) => {
        emit('update:configuracao', val);
      },
    });

    function abrirTipoCusto() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_TIPOS_CUSTO';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirTipoCusto = true;
    }

    function abrirTipoPreco() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_TIPOS_PRECO';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirTipoPreco = true;
    }

    function abrirEstoque() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_ESTOQUES';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirEstoque = true;
    }

    function abrirTabelaPreco() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_TABELAS_PRECOS';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirTabelaPreco = true;
    }

    function abrirMarca() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_MARCAS';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirMarca = true;
    }

    function abrirCategoria() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_CATEGORIAS_PRODUTOS';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirCategoria = true;
    }

    function adicionarOptionTipoCusto(sigla: string, descricao: string) {
      const option = {} as IOptionString;
      option.label = descricao;
      option.value = sigla;
      state.optionsTipoCusto.push(option);
      state.tiposCustoSelecionados.push(sigla);
    }

    function adicionarOptionTipoPreco(sigla: string, descricao: string) {
      const option = {} as IOptionString;
      option.label = descricao;
      option.value = sigla;
      state.optionsTipoPreco.push(option);
      state.tiposPrecoSelecionados.push(sigla);
    }

    function adicionarOptionUnidade(sigla: string, descricao: string) {
      const option = {} as IOptionString;
      option.label = descricao;
      option.value = sigla;
      state.optionsUnidade.push(option);
      state.unidadesSelecionadas.push(sigla);
    }

    function preencherOpcoesTipoCusto() {
      state.optionsTipoCusto = [] as IOptionString[];
      state.tiposCustoSelecionados = [] as string[];
      adicionarOptionTipoCusto('C', 'Custo');
      adicionarOptionTipoCusto('CM', 'Custo Médio');
    }

    function preencherOpcoesTipoPreco() {
      state.optionsTipoPreco = [] as IOptionString[];
      state.tiposPrecoSelecionados = [] as string[];
      adicionarOptionTipoPreco('A', 'Atacado');
      adicionarOptionTipoPreco('V', 'Varejo');
    }

    function preencherOpcoesUnidade() {
      state.optionsUnidade = [] as IOptionString[];
      state.unidadesSelecionadas = [] as string[];
      adicionarOptionUnidade('AP', 'Aparelho (AP)');
      adicionarOptionUnidade('CJ', 'Conjunto (CJ)');
      adicionarOptionUnidade('CX', 'Caixa (CX)');
      adicionarOptionUnidade('CX10', 'Caixa com 10 (CX10)');
      adicionarOptionUnidade('CX20', 'Caixa com 20 (CX20)');
      adicionarOptionUnidade('FR', 'Frasco (FR)');
      adicionarOptionUnidade('GL', 'Galão (GL)');
      adicionarOptionUnidade('KG', 'Quilograma (KG)');
      adicionarOptionUnidade('KT', 'Kit (KT)');
      adicionarOptionUnidade('LT', 'Litro (LT)');
      adicionarOptionUnidade('MI', 'Milheiro (MI)');
      adicionarOptionUnidade('MT', 'Metro (MT)');
      adicionarOptionUnidade('PC', 'Peça (PC)');
      adicionarOptionUnidade('PT', 'Pacote (PT)');
      adicionarOptionUnidade('PR', 'Par (PR)');
      adicionarOptionUnidade('RL', 'Rolo (RL)');
      adicionarOptionUnidade('UN', 'Unidade (UN)');
    }

    function concluir() {
      state.carregando = true;
      computedConfiguracao.value.usarCusto = state.tiposCustoSelecionados.includes('C');
      computedConfiguracao.value.usarCustoMedio = state.tiposCustoSelecionados.includes('CM');
      computedConfiguracao.value.usarPrecoAtacado = state.tiposPrecoSelecionados.includes('A');
      computedConfiguracao.value.usarPrecoVarejo = state.tiposPrecoSelecionados.includes('V');
      computedConfiguracao.value.usarUnidadeAp = state.unidadesSelecionadas.includes('AP');
      computedConfiguracao.value.usarUnidadeCx = state.unidadesSelecionadas.includes('CX');
      computedConfiguracao.value.usarUnidadeCx10 = state.unidadesSelecionadas.includes('CX10');
      computedConfiguracao.value.usarUnidadeCx20 = state.unidadesSelecionadas.includes('CX20');
      computedConfiguracao.value.usarUnidadeFr = state.unidadesSelecionadas.includes('FR');
      computedConfiguracao.value.usarUnidadeGl = state.unidadesSelecionadas.includes('GL');
      computedConfiguracao.value.usarUnidadeKg = state.unidadesSelecionadas.includes('KG');
      computedConfiguracao.value.usarUnidadeKt = state.unidadesSelecionadas.includes('KT');
      computedConfiguracao.value.usarUnidadeLt = state.unidadesSelecionadas.includes('LT');
      computedConfiguracao.value.usarUnidadeMi = state.unidadesSelecionadas.includes('MI');
      computedConfiguracao.value.usarUnidadeMt = state.unidadesSelecionadas.includes('MT');
      computedConfiguracao.value.usarUnidadePc = state.unidadesSelecionadas.includes('PC');
      computedConfiguracao.value.usarUnidadePt = state.unidadesSelecionadas.includes('PT');
      computedConfiguracao.value.usarUnidadePr = state.unidadesSelecionadas.includes('PR');
      computedConfiguracao.value.usarUnidadeRl = state.unidadesSelecionadas.includes('RL');
      computedConfiguracao.value.usarUnidadeUn = state.unidadesSelecionadas.includes('UN');
      emit('atualizarConfiguracao');
    }

    function validarPermissoes() {
      let pularEtapa = true;
      if (state.permissaoTipoCusto) {
        pularEtapa = false;
      }

      if (state.permissaoTipoPreco) {
        pularEtapa = false;
      }

      if (state.permissaoUnidade) {
        pularEtapa = false;
      }

      if (state.permissaoEstoque) {
        pularEtapa = false;
      }

      if (state.permissaoTabelaPreco) {
        pularEtapa = false;
      }

      if (state.permissaoCategoria) {
        pularEtapa = false;
      }

      if (state.exibirMarca) {
        pularEtapa = false;
      }

      if (pularEtapa) {
        state.tiposCustoSelecionados = [] as string[];
        state.tiposPrecoSelecionados = [] as string[];
        state.unidadesSelecionadas = [] as string[];
        concluir();
      }
    }

    async function preencherPermissoes() {
      const permissaoTipoCusto = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_TIPOS_CUSTO');
      const permissaoTipoPreco = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_TIPOS_PRECO');
      const permissaoUnidade = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_UNIDADES');
      const permissaoEstoque = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_ESTOQUES');
      const permissaoTabelaPreco = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_TABELAS_PRECOS');
      const permissaoMarca = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_MARCAS');
      const permissaoCategoria = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_CATEGORIAS_PRODUTOS');

      if (permissaoTipoCusto !== undefined) {
        state.permissaoTipoCusto = permissaoTipoCusto[0].incluir;
      } else {
        state.permissaoTipoCusto = false;
      }
      if (permissaoTipoPreco !== undefined) {
        state.permissaoTipoPreco = permissaoTipoPreco[0].incluir;
      } else {
        state.permissaoTipoPreco = false;
      }
      if (permissaoUnidade !== undefined) {
        state.permissaoUnidade = permissaoUnidade[0].incluir;
      } else {
        state.permissaoUnidade = false;
      }
      if (permissaoEstoque !== undefined) {
        state.permissaoEstoque = permissaoEstoque[0].incluir;
      } else {
        state.permissaoEstoque = false;
      }
      if (permissaoTabelaPreco !== undefined) {
        state.permissaoTabelaPreco = permissaoTabelaPreco[0].incluir;
      } else {
        state.permissaoTabelaPreco = false;
      }
      if (permissaoMarca !== undefined) {
        state.permissaoMarca = permissaoMarca[0].incluir;
      } else {
        state.permissaoMarca = false;
      }
      if (permissaoCategoria !== undefined) {
        state.permissaoCategoria = permissaoCategoria[0].incluir;
      } else {
        state.permissaoCategoria = false;
      }
      validarPermissoes();
    }

    onBeforeMount(async () => {
      state.carregando = true;
      if (props.configuracao.etapaAtual === EEtapaConfiguracaoInicial.Produtos) {
        preencherOpcoesTipoCusto();
        preencherOpcoesTipoPreco();
        preencherOpcoesUnidade();
        await preencherPermissoes();
      }
      state.carregando = false;
    });

    return {
      props,
      state,
      EEtapaConfiguracaoInicial,
      computedConfiguracao,
      concluir,
      abrirTipoCusto,
      abrirTipoPreco,
      abrirEstoque,
      abrirTabelaPreco,
      abrirMarca,
      abrirCategoria,
    };
  },
});
