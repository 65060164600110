
import {
  Ref,
  computed, defineComponent, inject, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import {
  IEtapaProcessoVendas, IEtapaProcessoVendasPerfilUsuario, IEtapaProcessoVendasProximaEtapa,
  IEtapaProcessoVendasUsuario,
} from '@/models/Entidades/Cadastros/Vendas/IProcessoVendas';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import SelecionarPerfilUsuario from '@/components/MeuSistema/Usuarios/SelecionarPerfilUsuario.vue';
import SelecionarCor from '@/core/components/Tela/SelecionarCor.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarGenericoString from '@/core/components/Tela/SelecionarGenericoString.vue';
import { IOption, IOptionString } from '@/core/models/AntDesign/IOption';
import UtilitarioMatematico from '@/core/utilitarios/UtilitarioMatematico';
import { EStatusVenda } from '@/models/Enumeradores/Vendas/EStatusVenda';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import SelecionarGenerico from '@/core/components/Tela/SelecionarGenerico.vue';
import ServicoProcessoVendas from '@/servicos/Cadastros/Vendas/ServicoProcessoVendas';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'EtapaProcessoVendasModal',
  props: {
    visivel: {
      type: Boolean,
    },
    editavel: {
      type: Boolean,
      default: true,
    },
    ultimoCodigoProvisorio: {
      type: Number,
      required: true,
    },
    etapasAnteriores: {
      type: Array as () => IEtapaProcessoVendas[],
      required: true,
    },
    etapa: {
      type: Object as () => IEtapaProcessoVendas,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarPerfilUsuario,
    SelecionarUsuario,
    SelecionarCor,
    SelecionarGenericoString,
    SelecionarGenerico,
  },
  emits: ['update:visivel', 'update:ultimoCodigoProvisorio', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoSistema = new ServicoSistema();
    const servicoProcessoVendas = new ServicoProcessoVendas();
    const perfisUsuarios = inject('PROCESSO_VENDAS_PERFIS') as Ref<number[]>;
    const usuarios = inject('PROCESSO_VENDAS_USUARIOS') as Ref<number[]>;
    const etapasDisponiveis = inject('PROCESSO_VENDAS_ETAPAS') as Ref<IEtapaProcessoVendas[]>;
    const optionsEtapas = inject('PROCESSO_VENDAS_OPTIONS_ETAPAS') as Ref<IOptionString[]>;
    telaBase.identificadorRecurso = 'CADASTRO_PROCESSOS_VENDAS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_PROCESSOS_VENDAS';

    const state = reactive({
      etapa: {} as IEtapaProcessoVendas,
      optionsEtapasDisponiveis: [] as IOptionString[],
      statusDisponiveis: [] as IOption[],
      perfisUsuarios: [] as number[],
      usuarios: [] as number[],
      proximasEtapas: [] as string[],
      subprocessos: [] as string[],
      etapaExpiracao: '',
    });

    const computedUltimoCodigoProvisorio = computed({
      get: () => props.ultimoCodigoProvisorio,
      set: (valor: number) => {
        emit('update:ultimoCodigoProvisorio', valor);
      },
    });

    function limparTela() {
      state.etapa = {} as IEtapaProcessoVendas;
      state.optionsEtapasDisponiveis = [] as IOptionString[];
      state.statusDisponiveis = [] as IOption[];
      state.etapa.totalizador = true;
      state.etapa.expiracaoAutomatica = false;
      state.etapa.tempoExpiracao = 30;
      state.etapa.proximasEtapas = [] as IEtapaProcessoVendasProximaEtapa[];
      state.etapa.statusVenda = 1;
      state.etapa.notificarResponsavel = false;
      state.etapa.notificarParticipantes = false;
      state.etapa.enviarEmailCliente = false;
      state.etapa.bloquearEstoque = false;
      state.etapa.movimentarEstoque = false;
      state.etapa.executarFaturamento = false;
      state.etapa.gerarDocFiscal = false;
      state.perfisUsuarios = [] as number[];
      state.usuarios = [] as number[];
      state.proximasEtapas = [] as string[];
      state.subprocessos = [] as string[];
      state.etapaExpiracao = '';
    }

    function obterPerfisUsuarios() {
      state.etapa.perfisUsuarios = [] as IEtapaProcessoVendasPerfilUsuario[];
      state.perfisUsuarios.forEach((p) => {
        const perfil = {} as IEtapaProcessoVendasPerfilUsuario;
        perfil.codigoEtapaProcessoVendas = state.etapa.codigo;
        perfil.codigoPerfilUsuario = p;
        state.etapa.perfisUsuarios.push(perfil);
      });
    }

    function obterUsuarios() {
      state.etapa.usuarios = [] as IEtapaProcessoVendasUsuario[];
      state.usuarios.forEach((u) => {
        const usuario = {} as IEtapaProcessoVendasUsuario;
        usuario.codigoEtapaProcessoVendas = state.etapa.codigo;
        usuario.codigoUsuario = u;
        state.etapa.usuarios.push(usuario);
      });
    }

    function obterProximasEtapas() {
      state.etapa.proximasEtapas = [] as IEtapaProcessoVendasProximaEtapa[];
      state.proximasEtapas.forEach((e) => {
        const proximaEtapa = {} as IEtapaProcessoVendasProximaEtapa;
        proximaEtapa.codigoEtapaProcessoVendas = state.etapa.codigo;
        if (e.includes('P')) {
          proximaEtapa.codigoProximaEtapaProvisorio = e;
        } else {
          proximaEtapa.codigoProximaEtapa = Number(e);
        }
        state.etapa.proximasEtapas.push(proximaEtapa);
      });
    }

    function obterEtapaExpiracao() {
      if (state.etapa.expiracaoAutomatica) {
        if (state.etapaExpiracao.includes('P') || state.etapaExpiracao === '') {
          state.etapa.codigoEtapaExpiracaoProvisorio = state.etapaExpiracao;
        } else {
          state.etapa.codigoEtapaExpiracao = Number(state.etapaExpiracao);
        }
      } else {
        state.etapaExpiracao = '';
        state.etapa.codigoEtapaExpiracao = 0;
        state.etapa.codigoEtapaExpiracaoProvisorio = '';
      }
    }

    function validarCampos() {
      if (!UtilitarioGeral.valorValido(state.etapa.nome)) {
        apresentarMensagemAlerta('O nome é obrigatório!');
        return false;
      }
      if (!UtilitarioGeral.valorValido(state.etapa.cor)) {
        apresentarMensagemAlerta('A cor é obrigatória!');
        return false;
      }
      if (state.etapa.expiracaoAutomatica) {
        if (!UtilitarioGeral.valorValido(state.etapa.tempoExpiracao)) {
          apresentarMensagemAlerta('O tempo de expiração é obrigatório quando a expiração automática está ativa!');
          return false;
        }

        if (!UtilitarioGeral.valorValido(state.etapaExpiracao)) {
          apresentarMensagemAlerta('A etapa de expiração é obrigatória quando a expiração automática está ativa!');
          return false;
        }
      }

      return true;
    }

    function validarReferenciaCiclica(codigo: string, proximaEtapa: IEtapaProcessoVendas) {
      let retorno = true;
      proximaEtapa.proximasEtapas.forEach((pe) => {
        if (retorno) {
          let codigoProximaEtapa = String(pe.codigoProximaEtapa);
          if (!UtilitarioGeral.validaCodigo(pe.codigoProximaEtapa)) {
            codigoProximaEtapa = pe.codigoProximaEtapaProvisorio;
          }

          if (codigo === codigoProximaEtapa) {
            apresentarMensagemAlerta('Não é possível fazer referência cíclica!');
            retorno = false;
          }
          if (retorno) {
            const indexEtapa = etapasDisponiveis.value.findIndex((e) => String(e.codigo) === codigoProximaEtapa || e.codigoProvisorio === codigoProximaEtapa);
            const proximaEtapa = etapasDisponiveis.value[indexEtapa];
            retorno = validarReferenciaCiclica(codigo, proximaEtapa);
          }
        }
      });
      return retorno;
    }

    function validarProximasEtapas() {
      let retorno = true;

      state.proximasEtapas.forEach((pe) => {
        if (retorno) {
          const indexEtapa = etapasDisponiveis.value.findIndex((e) => String(e.codigo) === pe || e.codigoProvisorio === pe);
          const proximaEtapa = etapasDisponiveis.value[indexEtapa];
          if (proximaEtapa.statusVenda < state.etapa.statusVenda) {
            retorno = false;
            apresentarMensagemAlerta(`O status de venda da etapa ${proximaEtapa.nome} é menor que a da etapa atual!`);
          }
          if (state.etapa.movimentarEstoque && proximaEtapa.statusVenda < EStatusVenda.Orcamento) {
            retorno = false;
            apresentarMensagemAlerta(`Para movimentar o estoque o status de venda da etapa ${proximaEtapa.nome} deve ser pelo menos Orçamento!`);
          }
          if (state.etapa.executarFaturamento && proximaEtapa.statusVenda < EStatusVenda.Efetivada) {
            retorno = false;
            apresentarMensagemAlerta(`Para executar o Faturamento o status de venda da etapa ${proximaEtapa.nome} deve ser pelo menos Efetivada!`);
          }
          if (state.etapa.gerarDocFiscal && proximaEtapa.statusVenda < EStatusVenda.EmFaturamento) {
            retorno = false;
            apresentarMensagemAlerta(`Para gerar documento fiscal o status de venda da etapa ${proximaEtapa.nome} deve ser pelo menos Em faturamento!`);
          }
          let codigo = String(state.etapa.codigo);
          if (!UtilitarioGeral.validaCodigo(state.etapa.codigo)) {
            codigo = state.etapa.codigoProvisorio;
          }
          if (retorno) {
            retorno = validarReferenciaCiclica(codigo, proximaEtapa);
          }
        }
      });
      return retorno;
    }

    function salvar() {
      if (!validarCampos() || !validarProximasEtapas()) {
        return;
      }
      obterPerfisUsuarios();
      obterUsuarios();
      obterProximasEtapas();
      obterEtapaExpiracao();
      if (state.etapa.codigo === undefined) {
        state.etapa.codigo = 0;
      }
      state.etapa.alterada = true;
      emit('confirmacao', state.etapa);
      modalBase.computedVisivel = false;
    }

    function preencherEtapasDisponiveis() {
      state.optionsEtapasDisponiveis = [] as IOptionString[];
      let codigo = String(props.etapa.codigo);
      if (!UtilitarioGeral.validaCodigo(props.etapa.codigo)) {
        codigo = state.etapa.codigoProvisorio;
      }

      optionsEtapas.value.forEach((e) => {
        if (e.value !== codigo) {
          state.optionsEtapasDisponiveis.push(e);
        }
      });
    }

    async function preencherStatusDisponiveis() {
      state.statusDisponiveis = [] as IOption[];
      let ultimoStatus = 1;

      if (props.etapasAnteriores.length > 0) {
        props.etapasAnteriores.forEach((e) => {
          if (ultimoStatus < e.statusVenda) {
            ultimoStatus = e.statusVenda;
          }
        });
      }
      if (props.etapa.codigo === undefined) {
        state.etapa.statusVenda = ultimoStatus;
      }
      let status = await servicoSistema.obterListaStatusVendas();
      status = status.filter((s) => Number(s.identificador) >= ultimoStatus);
      status.forEach((s) => {
        const option = {} as IOption;
        option.value = Number(s.identificador);
        option.label = s.descricao;
        state.statusDisponiveis.push(option);
      });
    }

    async function preencherPerfisUsuarios() {
      if (!state.etapa.alterada) {
        state.etapa.perfisUsuarios = await servicoProcessoVendas.obterPerfisUsuariosEtapa(props.etapa.codigo, storeSistema.getters.codigoEmpresaOperacao());
      }
      state.perfisUsuarios = [] as number[];
      state.etapa.perfisUsuarios.forEach((p) => {
        state.perfisUsuarios.push(p.codigoPerfilUsuario);
      });
    }

    async function preencherUsuarios() {
      if (!state.etapa.alterada) {
        state.etapa.usuarios = await servicoProcessoVendas.obterUsuariosEtapa(props.etapa.codigo, storeSistema.getters.codigoEmpresaOperacao());
      }
      state.usuarios = [] as number[];
      state.etapa.usuarios.forEach((u) => {
        state.usuarios.push(u.codigoUsuario);
      });
    }

    function preencherProximasEtapas() {
      state.proximasEtapas = [] as string[];
      state.etapa.proximasEtapas.forEach((p) => {
        let codigo = '';
        if (UtilitarioGeral.validaCodigo(p.codigoProximaEtapa)) {
          codigo = String(p.codigoProximaEtapa);
        } else {
          codigo = p.codigoProximaEtapaProvisorio;
        }
        const indexEtapa = state.optionsEtapasDisponiveis.findIndex((e) => e.value === codigo);
        if (indexEtapa > -1) {
          state.proximasEtapas.push(codigo);
        }
      });
    }

    async function preencherEtapa() {
      await preencherPerfisUsuarios();
      await preencherUsuarios();
      await preencherProximasEtapas();
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        computedUltimoCodigoProvisorio.value += 1;
        await preencherStatusDisponiveis();
        if (props.etapa.codigo !== undefined) {
          state.etapa = UtilitarioGeral.clonarObjeto(props.etapa);
          preencherEtapasDisponiveis();
          await preencherEtapa();
        } else {
          state.etapa.codigoProvisorio = `P${computedUltimoCodigoProvisorio.value}`;
          preencherEtapasDisponiveis();
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      perfisUsuarios,
      usuarios,
      UtilitarioMatematico,
      salvar,
      EStatusVenda,
    };
  },
});
