
import {
  Ref, defineComponent, inject, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarGenericoString from '@/core/components/Tela/SelecionarGenericoString.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import { IEtapaProcessoVendas } from '@/models/Entidades/Cadastros/Vendas/IProcessoVendas';

export default defineComponent({
  name: 'SelecionarEtapaProcessoVendasModal',
  props: {
    visivel: {
      type: Boolean,
    },
    etapa: {
      type: Object as () => IEtapaProcessoVendas,
      required: true,
    },
  },
  components: {
    Icone,
    SelecionarGenericoString,
  },
  emits: ['update:visivel', 'update:icone', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const etapas = inject('PROCESSO_VENDAS_ETAPAS') as Ref<IEtapaProcessoVendas[]>;
    const optionsEtapas = inject('PROCESSO_VENDAS_OPTIONS_ETAPAS') as Ref<IOptionString[]>;

    const state = reactive({
      etapaSelecionada: '',
      optionsEtapas: [] as IOptionString[],
    });

    function confirmar() {
      emit('confirmacao', state.etapaSelecionada);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      state.optionsEtapas = [] as IOptionString[];
      optionsEtapas.value.forEach((i) => {
        const indexEtapa = etapas.value.findIndex((e) => String(e.codigo) === i.value || e.codigoProvisorio === i.value);
        if (i.label !== props.etapa.nome && etapas.value[indexEtapa].statusVenda === props.etapa.statusVenda) {
          state.optionsEtapas.push(i);
        }
      });
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      confirmar,
    };
  },
});
