import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IConfiguracaoInicial } from '@/models/Entidades/MeuSistema/IConfiguracaoInicial';

/**
 * Serviço da Configuração Inicial
 * Centraliza e organiza regras do funcionamento da configuração inicial.
 */

class ServicoConfiguracaoInicial {
    private endPoint = 'configuracao-inicial';

    private apiERP = new ApiERP();

    public async obterConfiguracaoInicial(empresa: number): Promise<IConfiguracaoInicial> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${empresa}`);
      return result.data;
    }

    public async obterCodigoPlanoContas(empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/plano-contas/${empresa}`);
      return result.data;
    }

    public async obterCodigoProcessoVendas(empresa: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/processo-vendas/${empresa}`);
      return result.data;
    }

    public async atualizarConfiguracaoInicial(configuracao: IConfiguracaoInicial): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}`, configuracao);
      return result.data;
    }
}

export default ServicoConfiguracaoInicial;
