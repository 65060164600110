
import { defineComponent, reactive, watch } from 'vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { ETipoPessoa } from '@/models/Enumeradores/Compartilhados/ETipoPessoa';
import { IEmpresa } from '@/models/Entidades/MeuSistema/Empresas/IEmpresa';
import ServicoEmpresa from '@/servicos/MeuSistema/ServicoEmpresa';
import ServicoUtilitario from '@/servicos/MeuSistema/ServicoUtilitario';
import ServicoEstado from '@/servicos/Cadastros/Localizacoes/ServicoEstado';
import ServicoCidade from '@/servicos/Cadastros/Localizacoes/ServicoCidade';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IOption, IOptionString } from '@/core/models/AntDesign/IOption';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IEmpresaSimplesNacional } from '@/models/Entidades/MeuSistema/Empresas/IEmpresaSimplesNacional';
import { IEndereco } from '@/models/Entidades/Cadastros/Localizacoes/IEndereco';
import GerenciarIESubstituicaoTributariaModal from '@/views/MeuSistema/Empresas/GerenciarIESubstituicaoTributariaModal.vue';
import { IEmpresaIeSubstituicaoTributaria } from '@/models/Entidades/MeuSistema/Empresas/IEmpresaIeSubstituicaoTributaria';
import { IDTOEstado } from '@/models/DTO/Cadastros/Localizacoes/IDTOEstado';
import { ERegimeTributario } from '@/models/Enumeradores/MeuSistema/Empresas/ERegimeTributario';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import storeSistema from '@/store/storeSistema';
import CampoCpf from '@/core/components/Tela/CampoCpf.vue';
import CampoCnpj from '@/core/components/Tela/CampoCnpj.vue';
import SelecionarCidade from '@/components/Cadastros/Localizacoes/Cidades/SelecionarCidade.vue';

export default defineComponent({
  name: 'EmpresaDadosCadastraisModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    CampoNumerico,
    Icone,
    Card,
    RequisicaoModal,
    GerenciarIESubstituicaoTributariaModal,
    CampoCpf,
    CampoCnpj,
    SelecionarCidade,
  },
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta, montaOpcoesComListaItemGenerico,
      montaOpcoesComListaItemGenericoTexto,
    } = useTelaBase();
    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);
    const servicoEmpresa = new ServicoEmpresa();
    const servicoUtilitario = new ServicoUtilitario();
    const servicoEstado = new ServicoEstado();
    const servicoCidade = new ServicoCidade();
    const servicoSistema = new ServicoSistema();
    let listaDtoEstados: IDTOEstado[] = [];

    telaBase.identificadorRecurso = 'PAINEL_EMPRESAS';
    telaBase.identificadorPermissao = 'AUT_PAINEL_EMPRESAS';

    const stateModal = reactive({
      empresa: {} as IEmpresa,
      exibirGerenciadorIESubstituicao: false,
      dataAdesaoSimples: {} as any,
      listaTipoPessoa: [] as IOption[],
      listaTipoAtividade: [] as IOption[],
      listaCNAES: [] as IOptionString[],
      listaRegimeTributario: [] as IOption[],
      listaRegimeTributarioEspecial: [] as IOption[],
      debounceCNAE: 0,
      listaArquivo: [],
      carrengadoLogo: false,
    });

    (async () => {
      const listaTipoPessoa = await servicoEmpresa.obterListaTipoPessoa();
      stateModal.listaTipoPessoa = montaOpcoesComListaItemGenerico(listaTipoPessoa);

      const listaTipoAtividade = await servicoEmpresa.obterListaTipoAtividade();
      stateModal.listaTipoAtividade = montaOpcoesComListaItemGenerico(listaTipoAtividade);

      const listaRegimeTributario = await servicoEmpresa.obterListaRegimeTributario();
      stateModal.listaRegimeTributario = montaOpcoesComListaItemGenerico(listaRegimeTributario);

      const listaRegimeTributarioEspecial = await servicoEmpresa.obterListaRegimeTributarioEspecial();
      stateModal.listaRegimeTributarioEspecial = montaOpcoesComListaItemGenerico(listaRegimeTributarioEspecial);

      listaDtoEstados = await servicoEstado.obterTodos();
    })();

    function objetoInicial() {
      stateModal.empresa = {} as IEmpresa;
      stateModal.empresa.simplesNacional = {} as IEmpresaSimplesNacional;
      stateModal.empresa.ieSubstituicaoTributaria = [] as IEmpresaIeSubstituicaoTributaria[];
      stateModal.empresa.endereco = {} as IEndereco;
    }

    function comportamentoInscricaoEstadual() {
      if (!stateModal.empresa.isentoInscricaoEstadual) {
        stateModal.empresa.inscricaoEstadual = '';
      }
    }

    function gerenciarIESubstituicao() {
      stateModal.exibirGerenciadorIESubstituicao = true;
    }

    function atualizarInscricoesSubstituicaoTributaria(listaInscricoes: IEmpresaIeSubstituicaoTributaria[]) {
      stateModal.empresa.ieSubstituicaoTributaria = listaInscricoes;
    }
    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      if (stateModal.empresa.tipoPessoa !== ETipoPessoa.Juridica) {
        stateModal.empresa.cnaePrincipal = '0000000';
      }
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoEmpresa.incluir(stateModal.empresa);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoEmpresa.alterar(stateModal.empresa);
      }
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar && stateModal.empresa.codigo === storeSistema.getters.codigoEmpresaOperacao()) {
          servicoSistema.preencherDadosSistema(true);
        }
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function pesquisarCNAE(valorPesquisa: any, filtrarPorCodigo: boolean) {
      stateModal.listaCNAES = [];
      const timeOut = filtrarPorCodigo ? 1 : 600;
      clearTimeout(stateModal.debounceCNAE);
      stateModal.debounceCNAE = setTimeout(async () => {
        const listaCNAES = await servicoUtilitario.consultaCNAE(valorPesquisa);
        stateModal.listaCNAES = montaOpcoesComListaItemGenericoTexto(listaCNAES);
        if (filtrarPorCodigo) {
          stateModal.empresa.cnaePrincipal = stateModal.listaCNAES[0].value;
        }
      }, timeOut);
    }

    async function pesquisarCidade(nomeCidade: string, uf: string) {
      stateModal.empresa.endereco.codigoCidade = 0;
      const cidade = await servicoCidade.obterCidadePorNomeUf(nomeCidade, uf);
      if (UtilitarioGeral.objetoValido(cidade)) {
        if (UtilitarioGeral.validaCodigo(cidade.codigo)) {
          stateModal.empresa.endereco.codigoCidade = cidade.codigo;
        }
      }
    }

    async function consultaCEP(cep: string) {
      const retornoConsultaCEP = await servicoUtilitario.consultaCEP(UtilitarioMascara.removerMascara(cep));
      if (UtilitarioGeral.objetoValido(retornoConsultaCEP)) {
        if (UtilitarioGeral.valorValido(retornoConsultaCEP.cidade)) {
          stateModal.empresa.endereco.logradouro = retornoConsultaCEP.logradouro;
          stateModal.empresa.endereco.bairro = retornoConsultaCEP.bairro;
          if (UtilitarioGeral.valorValido(retornoConsultaCEP.complemento)) {
            stateModal.empresa.endereco.complemento = retornoConsultaCEP.complemento;
          }
          await pesquisarCidade(retornoConsultaCEP.cidade, retornoConsultaCEP.uf);
        }
      }
    }

    function obtemTextoIESubstituicao(): string {
      let textoIE = '';
      if (UtilitarioGeral.validaLista(stateModal.empresa.ieSubstituicaoTributaria)) {
        if (stateModal.empresa.ieSubstituicaoTributaria.length === 1) {
          textoIE = '1 - Inscrição cadastrada';
        } else {
          textoIE = `${stateModal.empresa.ieSubstituicaoTributaria.length} - Inscrições cadastradas`;
        }
      } else {
        textoIE = 'Nenhuma inscrição cadastrada';
      }
      return textoIE;
    }

    function obtemSiglaEstado(codigoEstado: number): string {
      let siglaEstado = '';
      if (UtilitarioGeral.validaLista(listaDtoEstados)) {
        const estado = listaDtoEstados.find((c) => c.codigo === codigoEstado);
        if (estado !== undefined) {
          siglaEstado = estado.sigla;
        }
      }
      return siglaEstado;
    }

    async function consultaCNPJ(cnpj: string) {
      const retornoConsultaCnpj = await servicoUtilitario.consultaCNPJ(UtilitarioMascara.removerMascara(cnpj));
      if (UtilitarioGeral.objetoValido(retornoConsultaCnpj)) {
        if (UtilitarioGeral.valorValido(retornoConsultaCnpj.razaoSocial)) {
          stateModal.empresa.razaoSocial = retornoConsultaCnpj.razaoSocial;
          stateModal.empresa.nomeFantasia = retornoConsultaCnpj.nomeFantasia;
          await pesquisarCNAE(retornoConsultaCnpj.cnaePrincipal, true);
          stateModal.empresa.email = retornoConsultaCnpj.contato.email;
          const telefone = UtilitarioMascara.removerMascara(retornoConsultaCnpj.contato.telefonePrincipal);
          stateModal.empresa.telefone = UtilitarioMascara.mascararTelefoneFixoOuCelular(telefone);
          stateModal.empresa.contato = retornoConsultaCnpj.qsa[0].nome;
          stateModal.empresa.endereco.cep = UtilitarioMascara.removerMascara(retornoConsultaCnpj.endereco.cep);
          await consultaCEP(stateModal.empresa.endereco.cep);
          stateModal.empresa.endereco.logradouro = retornoConsultaCnpj.endereco.logadouro;
          stateModal.empresa.endereco.bairro = retornoConsultaCnpj.endereco.bairro;
          stateModal.empresa.endereco.complemento = retornoConsultaCnpj.endereco.complemento;
          stateModal.empresa.endereco.cep = UtilitarioMascara.mascararCEP(stateModal.empresa.endereco.cep);
          await pesquisarCidade(retornoConsultaCnpj.endereco.cidade, retornoConsultaCnpj.endereco.uf);
        }
      }
    }

    async function enviarLogo(arquivo: any) {
      stateModal.carrengadoLogo = true;
      const retornoArquivo = await servicoEmpresa.enviarLogo(arquivo, stateModal.empresa.codigo);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        stateModal.empresa.logo = retornoArquivo.linkArquivo;
      } else {
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      stateModal.carrengadoLogo = false;
    }

    async function removerLogo() {
      stateModal.carrengadoLogo = true;
      const retornoArquivo = await servicoEmpresa.removerLogo(stateModal.empresa.logo, stateModal.empresa.codigo);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        stateModal.empresa.logo = '';
      } else if (retornoArquivo.status === EStatusRetornoRequisicao.Alerta) {
        stateModal.empresa.logo = '';
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      stateModal.carrengadoLogo = false;
    }

    function verificaEmpresaSimples() {
      if (stateModal.empresa.regimeTributario === ERegimeTributario.SimplesNacional
        || stateModal.empresa.regimeTributario === ERegimeTributario.SimplesNacionalExcessoSubLimiteReceita) {
        return true;
      }
      return false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          stateModal.empresa = await servicoEmpresa.obter(props.operacao.codigoRegistro);
          await pesquisarCNAE(stateModal.empresa.cnaePrincipal, true);
          stateModal.empresa.cpfCnpj = UtilitarioMascara.mascararCPFOuCNPJ(stateModal.empresa.cpfCnpj);
          stateModal.empresa.telefone = UtilitarioMascara.mascararTelefoneFixoOuCelular(stateModal.empresa.telefone);
          stateModal.empresa.endereco.cep = UtilitarioMascara.mascararCEP(stateModal.empresa.endereco.cep);
          stateModal.dataAdesaoSimples = UtilitarioData.converterDataParaDayJs(stateModal.empresa.simplesNacional.dataAdesao);
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      stateModal,
      comportamentoInscricaoEstadual,
      salvar,
      objetoInicial,
      pesquisarCNAE,
      UtilitarioMascara,
      UtilitarioGeral,
      UtilitarioData,
      consultaCEP,
      ETipoPessoa,
      verificaEmpresaSimples,
      gerenciarIESubstituicao,
      atualizarInscricoesSubstituicaoTributaria,
      obtemTextoIESubstituicao,
      obtemSiglaEstado,
      listaDtoEstados,
      enviarLogo,
      removerLogo,
      consultaCNPJ,
    };
  },
});
