
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoCategoriaProduto from '@/servicos/Cadastros/Produtos/ServicoCategoriaProduto';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'SelecionarCategoria',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    descricaoSelecionada: {
      type: String,
      default: '',
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    ignorarCodigoCategoria: {
      type: Number,
      default: 0,
    },
    empresas: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:descricaoSelecionada', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida } = useTelaBase();

    let debounce = 0;
    const servicoCategoriaProduto = new ServicoCategoriaProduto();

    const state = reactive({
      listaCategorias: [] as IOption[],
      buscandoDados: false,
      emDigitacao: false,
    });

    async function obterCategoriaPorCodigo(valor: any) {
      if (state.emDigitacao) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else if (props.codigoSelecionado === 0) {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaCategorias)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaCategorias.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaCategorias.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaCategorias = [];
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(props.empresas)) {
        parametrosConsultaRapida.empresas = props.empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.valores = valor;
      } else {
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;
      }

      const listaCategorias = await servicoCategoriaProduto.consultaRapida(parametrosConsultaRapida);
      state.listaCategorias = montaOpcoesComListaConsultaRapida(listaCategorias);
      if (!UtilitarioGeral.validaLista(listaCategorias)) {
        if (props.varios) {
          emit('update:codigosSelecionados', undefined);
        } else {
          emit('update:codigoSelecionado', undefined);
        }
      }
    }

    async function pesquisarCategoria(valorPesquisa: any) {
      state.emDigitacao = true;

      state.buscandoDados = true;
      state.listaCategorias = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa, apenasAtivos: true, recursoAssociado: '',
        };

        if (UtilitarioGeral.validaLista(props.empresas)) {
          parametrosConsultaRapida.empresas = props.empresas;
        } else {
          parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
        }

        let listaCategorias = await servicoCategoriaProduto.consultaRapida(parametrosConsultaRapida);
        if (props.ignorarCodigoCategoria > 0) {
          listaCategorias = listaCategorias.filter((c) => c.codigo !== props.ignorarCodigoCategoria);
        }
        state.buscandoDados = false;
        state.listaCategorias = montaOpcoesComListaConsultaRapida(listaCategorias);
      }, 600);
    }

    function obterCategoria():any {
      obterCategoriaPorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedDescricaoSelecionada = computed({
      get: () => props.descricaoSelecionada,
      set: (val: string) => {
        emit('update:descricaoSelecionada', val);
      },
    });

    const computedCodigoSelecionado = computed({
      get: () => obterCategoria(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        const option = state.listaCategorias.find((c) => c.value === val);
        if (option !== undefined) {
          computedDescricaoSelecionada.value = option.label;
        }
      },
    });

    function obterCategorias():number[] {
      obterCategoriaPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterCategorias(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      state,
      pesquisarCategoria,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      computedDescricaoSelecionada,
      change,
    };
  },
});
