
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import SelecionarColaborador from '@/components/Cadastros/Pessoas/Colaboradores/SelecionarColaborador.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoDepartamentoColaborador from '@/servicos/Cadastros/Pessoas/ServicoDepartamentoColaborador';
import { IDepartamentoColaboradores } from '@/models/Entidades/Cadastros/Pessoas/Colaboradores/IDepartamentoColaboradores';
import { IDepartamentoColaboradoresEmpresa } from '@/models/Entidades/Cadastros/Pessoas/Colaboradores/IDepartamentoColaboradoresEmpresa';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IColaboradorDepartamentoColaborador } from '@/models/Entidades/Cadastros/Pessoas/Colaboradores/IColaboradorDepartamentoColaborador';
import { IDTODepartamentoColaboradorEmpresa } from '@/models/DTO/Cadastros/Pessoas/IDTODepartamentoColaboradorEmpresa';
import SelecionarDepartamento from '@/components/Cadastros/Pessoas/Colaboradores/SelecionarDepartamento.vue';

export default defineComponent({
  name: 'DepartamentoColaboradorModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    SelecionarEmpresaCadastroCompartilhado,
    SelecionarColaborador,
    SelecionarDepartamento,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasCadastroCompartilhado, preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoDepartamentoColaborador = new ServicoDepartamentoColaborador();
    telaBase.identificadorRecurso = 'DEPARTAMENTOS_COLABORADORES';
    telaBase.identificadorPermissao = 'PER_CADASTRO_DEPARTAMENTOS_COLABORADORES';

    const state = reactive({
      departamentoColaborador: {} as IDepartamentoColaboradores,
      colaboradoresDepartamento: [] as IDTODepartamentoColaboradorEmpresa[],
    });

    function objetoInicial() {
      state.departamentoColaborador = {} as IDepartamentoColaboradores;
      state.departamentoColaborador.codigo = 0;
      state.departamentoColaborador.ordem = 0;
      state.departamentoColaborador.colaboradores = [];
      state.departamentoColaborador.empresas = [];
      state.departamentoColaborador.ativo = true;
      state.colaboradoresDepartamento = [];
    }

    function montaApresentacaoDefinicoes(codigoEmpresa: number): string {
      if (telaBase.empresasSelecionadas.length === 1) {
        return 'DEFINIÇÕES DO DEPARTAMENTO';
      }
      const dadosEmpresa = storeSistema.state.empresasDisponiveis.find((c) => c.codigoEmpresa === codigoEmpresa);
      if (dadosEmpresa !== undefined) {
        return `DEFINIÇÕES DA EMPRESA: ${UtilitarioGeral.montaExibicaoEmpresa(dadosEmpresa.codigoExibicao, dadosEmpresa.nomeExibicao)}`;
      }

      return '';
    }
    function verificaDefinicaoColaboradores(codigoEmpresa: number, verificarResponsaveis: boolean) {
      const colaboradoresEmpresa = state.colaboradoresDepartamento.find((c) => c.codigoEmpresa === codigoEmpresa);
      if (colaboradoresEmpresa !== undefined) {
        if (verificarResponsaveis) {
          if (UtilitarioGeral.validaLista(colaboradoresEmpresa.responsaveis)) {
            colaboradoresEmpresa.responsaveis.forEach((codigoResponsavel) => {
              const index = colaboradoresEmpresa.colaboradores.findIndex((c) => c === codigoResponsavel);
              if (index !== -1) {
                colaboradoresEmpresa.colaboradores.splice(index, 1);
              }
            });
          }
        } else if (UtilitarioGeral.validaLista(colaboradoresEmpresa.colaboradores)) {
          colaboradoresEmpresa.colaboradores.forEach((codigoColaborador) => {
            const index = colaboradoresEmpresa.responsaveis.findIndex((c) => c === codigoColaborador);
            if (index !== -1) {
              colaboradoresEmpresa.responsaveis.splice(index, 1);
            }
          });
        }
      }
    }

    function preparaDefinicaoDepartamento(preencherPeloObjetoPrincipal: boolean) {
      const listaColaboradoresEmpresa:IDTODepartamentoColaboradorEmpresa[] = [];
      if (UtilitarioGeral.validaLista(telaBase.empresasSelecionadas)) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const colaboradoresDepartamento:IDTODepartamentoColaboradorEmpresa = {} as IDTODepartamentoColaboradorEmpresa;
          colaboradoresDepartamento.codigoEmpresa = codigoEmpresa;
          colaboradoresDepartamento.responsaveis = [];
          colaboradoresDepartamento.colaboradores = [];
          listaColaboradoresEmpresa.push(colaboradoresDepartamento);
        });

        for (let i = 0; i < listaColaboradoresEmpresa.length; (i += 1)) {
          if (preencherPeloObjetoPrincipal) {
            if (UtilitarioGeral.validaLista(state.departamentoColaborador.colaboradores)) {
              const listaResponsaveis = state.departamentoColaborador.colaboradores.filter((c) => c.codigoEmpresa === listaColaboradoresEmpresa[i].codigoEmpresa && c.responsavel === true);
              if (UtilitarioGeral.validaLista(listaResponsaveis)) {
                listaColaboradoresEmpresa[i].responsaveis = listaResponsaveis.map((c) => c.codigoColaborador);
              }
              const listaColaboradores = state.departamentoColaborador.colaboradores.filter((c) => c.codigoEmpresa === listaColaboradoresEmpresa[i].codigoEmpresa && c.responsavel === false);
              if (UtilitarioGeral.validaLista(listaColaboradores)) {
                listaColaboradoresEmpresa[i].colaboradores = listaColaboradores.map((c) => c.codigoColaborador);
              }
            }
          } else {
            const colaboradorEmpresa = state.colaboradoresDepartamento.find((c) => c.codigoEmpresa === listaColaboradoresEmpresa[i].codigoEmpresa);
            if (colaboradorEmpresa !== undefined) {
              listaColaboradoresEmpresa[i].responsaveis = colaboradorEmpresa.responsaveis;
              listaColaboradoresEmpresa[i].colaboradores = colaboradorEmpresa.colaboradores;
            }
          }
        }
      }
      state.colaboradoresDepartamento = listaColaboradoresEmpresa;
    }

    function empresaAlterada() {
      state.departamentoColaborador.codigoDepartamentoColaboradores = undefined;
      preparaDefinicaoDepartamento(false);
    }

    function preparaPersistenciaEmpresas() {
      const departamentoColaboradorEmpresas : IDepartamentoColaboradoresEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.departamentoColaborador.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            departamentoColaboradorEmpresas.push(empresaExistente);
          } else {
            const departamentoColaboradorEmpresa: IDepartamentoColaboradoresEmpresa = { codigo: 0, codigoDepartamentoColaboradores: state.departamentoColaborador.codigo, codigoEmpresa };
            departamentoColaboradorEmpresas.push(departamentoColaboradorEmpresa);
          }
        });
      }
      state.departamentoColaborador.empresas = departamentoColaboradorEmpresas;
    }

    function obtemColaborador(codigoColaborador:number, responsavel: boolean, codigoEmpresa:number):IColaboradorDepartamentoColaborador {
      const colaboradorExiste = state.departamentoColaborador.colaboradores.find((c) => c.codigoEmpresa === codigoEmpresa && c.codigoColaborador === codigoColaborador);
      if (colaboradorExiste !== undefined) {
        colaboradorExiste.responsavel = responsavel;
        return colaboradorExiste;
      }
      const novoColaboradorDepartamento: IColaboradorDepartamentoColaborador = {
        codigo: 0,
        codigoColaborador,
        codigoDepartamentoColaborador: state.departamentoColaborador.codigo,
        codigoEmpresa,
        responsavel,
      };
      return novoColaboradorDepartamento;
    }

    function preparaPersistenciaColaboradores() {
      const colaboradoresDepartamento : IColaboradorDepartamentoColaborador[] = [];
      if (UtilitarioGeral.validaLista(state.colaboradoresDepartamento)) {
        state.colaboradoresDepartamento.forEach((colaboradorDepartamento) => {
          if (UtilitarioGeral.validaLista(colaboradorDepartamento.responsaveis)) {
            colaboradorDepartamento.responsaveis.forEach((codigoColaborador) => {
              colaboradoresDepartamento.push(obtemColaborador(codigoColaborador, true, colaboradorDepartamento.codigoEmpresa));
            });
          }

          if (UtilitarioGeral.validaLista(colaboradorDepartamento.colaboradores)) {
            colaboradorDepartamento.colaboradores.forEach((codigoColaborador) => {
              colaboradoresDepartamento.push(obtemColaborador(codigoColaborador, false, colaboradorDepartamento.codigoEmpresa));
            });
          }
        });
      }
      state.departamentoColaborador.colaboradores = colaboradoresDepartamento;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      preparaPersistenciaEmpresas();
      preparaPersistenciaColaboradores();
      apresentarBarraProgresso();

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoDepartamentoColaborador.incluir(state.departamentoColaborador);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoDepartamentoColaborador.alterar(state.departamentoColaborador);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
          preparaDefinicaoDepartamento(false);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.departamentoColaborador = await servicoDepartamentoColaborador.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          if (state.departamentoColaborador.empresas.length > 0) {
            state.departamentoColaborador.empresas.forEach((cidadeEmpresa) => {
              telaBase.empresasSelecionadas.push(cidadeEmpresa.codigoEmpresa);
            });
          }
          preparaDefinicaoDepartamento(true);
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
          preparaDefinicaoDepartamento(false);
        }

        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      montaApresentacaoDefinicoes,
      preparaDefinicaoDepartamento,
      verificaDefinicaoColaboradores,
      empresaAlterada,
      EPermissaoDados,
    };
  },
});
