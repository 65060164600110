
import { defineComponent, reactive, computed } from 'vue';
import SelecionarEmpresa from '../Empresas/SelecionarEmpresa.vue';
import SelecionarUsuario from './SelecionarUsuario.vue';
import { IOption, IOptionString } from '@/core/models/AntDesign/IOption';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { IDTOUsuarioRegistroAtividade } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioRegistroAtividade';
import { IDTOFiltroUsuarioRegistroAtividade } from '@/models/DTO/MeuSistema/Usuarios/IDTOFiltroUsuarioRegistroAtividade';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { useGradeBase } from '@/core/composables/GradeBase';

export default defineComponent({
  name: 'RegistroAtividade',
  props: {
    empresas: {
      type: Array as () => number[],
      default: () => [],
    },
    apresentarEmpresas: {
      type: Boolean,
      required: true,
    },
    apresentarUsuarios: {
      type: Boolean,
      default: true,
    },
    codigoUsuario: {
      type: Number,
    },
    largura: {
      type: Number,
      required: true,
    },
    altura: {
      type: Number,
      required: true,
    },
  },
  components: {
    SelecionarEmpresa,
    SelecionarUsuario,
  },
  emits: ['update:empresas'],
  setup(props, { emit }) {
    const servicoSistema = new ServicoSistema();
    const { telaBase } = useTelaBase();

    const {
      gradeBase, preencheOrdenacaoSelecionada, ordenacaoAtiva,
    } = useGradeBase();

    const computedEmpresasSelecionadas = computed({
      get: () => props.empresas,
      set: (val: number[]) => {
        emit('update:empresas', val);
      },
    });

    const stateRegistroAtividade = reactive({
      listaRecursos: [] as IOptionString[],
      listaUsuarios: [] as IOption[],
      datas: [] as string[],
      filtros: {} as IDTOFiltroUsuarioRegistroAtividade,
      periodo: '',
      colunasGrade: [] as IColumn[],
      dados: [] as IDTOUsuarioRegistroAtividade [],
    });

    stateRegistroAtividade.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false,
      },
      {
        title: 'Data e Hora', dataIndex: 'dataHora', key: 'DataHora', position: 1, visible: true, ordering: true, fixed: 'left', align: 'left', width: 180,
      },
      {
        title: 'Recurso', dataIndex: 'recurso', key: 'Recurso', position: 2, visible: true, width: 200, ordering: true, fixed: 'left', align: 'left',
      },
      {
        title: 'Cod.Usuário', dataIndex: 'codigoUsuario', key: 'CodigoUsuario', position: 3, visible: props.apresentarUsuarios, ordering: true, align: 'right',
      },
      {
        title: 'Usuário', dataIndex: 'nomeUsuario', key: 'NomeUsuario', position: 4, visible: props.apresentarUsuarios, ordering: true, align: 'left',
      },
      {
        title: 'Detalhes', dataIndex: 'detalhe', key: 'detalhe', position: 5, visible: true, align: 'left', width: 500,
      },
      {
        title: 'Empresas', dataIndex: 'empresas', key: 'Empresas', position: 6, visible: props.apresentarEmpresas, ordering: true, align: 'left',
      },
    ];
    gradeBase.ordenacaoSelecionada = [];

    async function filtrar() {
      telaBase.carregando = true;
      stateRegistroAtividade.filtros.empresas = computedEmpresasSelecionadas.value;
      if (UtilitarioGeral.validaLista(stateRegistroAtividade.datas)) {
        if (UtilitarioGeral.valorValido(stateRegistroAtividade.datas[0])) {
          stateRegistroAtividade.filtros.dataInicial = UtilitarioData.converterDataHoraMinutoSegundoJson(stateRegistroAtividade.datas[0]);
        }
        if (UtilitarioGeral.valorValido(stateRegistroAtividade.datas[1])) {
          stateRegistroAtividade.filtros.dataFinal = UtilitarioData.converterDataHoraMinutoSegundoJson(stateRegistroAtividade.datas[1]);
        }
      }

      stateRegistroAtividade.filtros.ordenacao = Array<string>();
      gradeBase.ordenacaoSelecionada.forEach((item) => {
        stateRegistroAtividade.filtros.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });

      if (props.codigoUsuario !== undefined) {
        stateRegistroAtividade.filtros.usuarios = [];
        stateRegistroAtividade.filtros.usuarios.push(props.codigoUsuario);
      }

      stateRegistroAtividade.dados = await servicoSistema.obterRegistroAtividade(stateRegistroAtividade.filtros);
      telaBase.carregando = false;
    }

    async function pesquisarRecurso(valorPesquisa: any) {
      stateRegistroAtividade.listaRecursos = [];
      clearTimeout(telaBase.debounce);
      telaBase.debounce = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valorPesquisa)) {
          const listaRecursosAPI = await servicoSistema.obterRecursos(valorPesquisa, computedEmpresasSelecionadas.value);
          stateRegistroAtividade.listaRecursos = listaRecursosAPI.map((recurso) => ({
            label: recurso.descricao,
            value: recurso.identificador,
          }));
        }
      }, 600);
    }

    async function ordenarDados(key: string, sort: string) {
      await preencheOrdenacaoSelecionada(key, sort);
      await filtrar();
    }

    async function limparDados() {
      computedEmpresasSelecionadas.value = [];
      gradeBase.ordenacaoSelecionada = [];
      stateRegistroAtividade.listaRecursos = [];
      stateRegistroAtividade.listaUsuarios = [];
      stateRegistroAtividade.filtros.empresas = [];
      stateRegistroAtividade.filtros.usuarios = [];
      stateRegistroAtividade.dados = [];
      stateRegistroAtividade.datas = [];
      stateRegistroAtividade.filtros.dataInicial = '';
      stateRegistroAtividade.filtros.dataFinal = '';
      stateRegistroAtividade.filtros.detalhe = '';
      stateRegistroAtividade.filtros.recursos = [];
    }

    return {
      props,
      telaBase,
      stateRegistroAtividade,
      UtilitarioGeral,
      UtilitarioData,
      pesquisarRecurso,
      filtrar,
      ordenarDados,
      ordenacaoAtiva,
      limparDados,
      computedEmpresasSelecionadas,
    };
  },
});
