
import { defineComponent } from 'vue';
import Icone from '../../components/Icone.vue';
import MeusAtalhos from './MeusAtalhos.vue';

export default defineComponent({
  name: 'MeusAtalhosDesktop',
  components: {
    Icone,
    MeusAtalhos,
  },
  setup() {
    return {
    };
  },
});
