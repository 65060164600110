
import { defineComponent, reactive } from 'vue';
import Icone from '../../components/Icone.vue';
import storeSistema from '@/store/storeSistema';
import Notificacoes from './Notificacoes.vue';

export default defineComponent({
  components: {
    Icone,
    Notificacoes,
  },
  name: 'NotificacoesDesktop',
  setup() {
    const state = reactive({
      exibirNotificacoes: false,
    });

    async function abrirNotificacoes() {
      state.exibirNotificacoes = true;
    }

    return {
      state,
      storeSistema,
      abrirNotificacoes,
    };
  },
});
