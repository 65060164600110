
import { defineComponent, reactive } from 'vue';
import InlineSvg from 'vue-inline-svg';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import ERPLayoutVazio from '@/core/layout/ERPLayoutVazio.vue';
import ConfiguracaoInicialEtapas from '@/components/MeuSistema/ConfiguracaoInicial/ConfiguracaoInicialEtapas.vue';

export default defineComponent({
  name: 'ConfiguracaoInicialIndex',
  components: {
    InlineSvg,
    ERPLayoutVazio,
    ConfiguracaoInicialEtapas,
  },
  setup() {
    const { telaBase } = useTelaBase();

    const state = reactive({});

    return {
      state,
      telaBase,
      storeSistema,
    };
  },
});
