
import {
  computed, defineComponent, reactive,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';

export default defineComponent({
  name: 'SelecionarGenerico',
  components: {
  },
  props: {
    itens: {
      type: Array as () => IOption[],
      required: true,
    },
    varios: {
      type: Boolean,
      default: false,
    },
    valor: {
      type: Number || undefined,
    },
    valoresSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    radioButton: {
      type: Boolean,
      default: false,
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    mostrarBusca: {
      type: Boolean,
      default: true,
    },
    bordas: {
      type: Boolean,
      default: true,
    },
    tamanho: {
      type: String,
      default: 'default',
    },
  },
  emits: ['update:valor', 'update:valoresSelecionados', 'change'],
  setup(props, { emit }) {
    const state = reactive({});

    function obterItem():any {
      if (props.valor === 0) {
        const index = props.itens.findIndex((i) => i.value === 0);
        if (index === -1) {
          return undefined;
        }
        return 0;
      }
      return props.valor;
    }
    const computedValor = computed({
      get: () => obterItem(),
      set: (valor: number) => {
        emit('update:valor', valor);
      },
    });

    const computedValoresSelecionados = computed({
      get: () => props.valoresSelecionados,
      set: (valores: number[]) => {
        emit('update:valoresSelecionados', valores);
      },
    });

    function defineValorSelecionado(valor:number) {
      emit('update:valor', valor);
      emit('change', valor);
    }

    function defineValoresSelecionados(valores:number[]) {
      emit('update:valoresSelecionados', valores);
      emit('change', valores);
    }

    function defineValorMarcado(valor:number) {
      emit('change', valor);
    }

    return {
      props,
      state,
      computedValor,
      computedValoresSelecionados,
      defineValorSelecionado,
      defineValoresSelecionados,
      defineValorMarcado,
    };
  },
});
