
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SelecionarCasasDecimaisValor',
  props: {
    casasDecimais: {
      type: Number,
      default: 2,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:casasDecimais', 'change'],
  setup(props, { emit }) {
    const computedCasasDecimaisSelecionada = computed({
      get: () => props.casasDecimais,
      set: (valor: number) => {
        emit('update:casasDecimais', valor);
      },
    });

    function change() {
      emit('change', props.casasDecimais);
    }

    return {
      props,
      computedCasasDecimaisSelecionada,
      change,
    };
  },
});
