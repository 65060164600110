import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ant-btn-group ss-sombra-suave" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_fluxo = _resolveComponent("fluxo")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.modalBase.computedVisivel,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
    width: "97%",
    centered: "",
    "wrap-class-name": 'full-modal'
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.props.titulo), 1)
    ]),
    closeIcon: _withCtx(() => [
      _createVNode(_component_icone, {
        style: {"margin-top":"4px"},
        nome: "voltar"
      })
    ]),
    footer: _withCtx(() => []),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_tooltip, {
          placement: "top",
          title: "Aumentar o Zoom"
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              class: "ant-btn ant-btn-secondary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.zoomin && _ctx.zoomin(...args)))
            }, [
              _createVNode(_component_icone, {
                style: {"margin-top":"4px"},
                nome: "zoomin"
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_tooltip, {
          placement: "top",
          title: "Diminuir o Zoom"
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              class: "ant-btn ant-btn-secondary",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.zoomout && _ctx.zoomout(...args)))
            }, [
              _createVNode(_component_icone, {
                style: {"margin-top":"4px"},
                nome: "zoomout"
              })
            ])
          ]),
          _: 1
        }),
        _renderSlot(_ctx.$slots, "menusadicionais"),
        _createVNode(_component_a_tooltip, {
          placement: "top",
          title: "Adicionar"
        }, {
          default: _withCtx(() => [
            (_ctx.props.editavel)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "ant-btn ant-btn-primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.adicionar('')))
                }, [
                  _createVNode(_component_icone, { nome: "mais" })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_fluxo, {
        style: _normalizeStyle('zoom: ' + _ctx.state.zoom + '%; max-height: 100%; max-width: 100%;'),
        cards: _ctx.props.cards,
        editavel: _ctx.props.editavel,
        onSelecaoAlterada: _ctx.selecaoCardAlterada,
        onAdicionar: _ctx.adicionar,
        onEditar: _ctx.editar,
        onExcluir: _ctx.excluir
      }, null, 8, ["style", "cards", "editavel", "onSelecaoAlterada", "onAdicionar", "onEditar", "onExcluir"])
    ]),
    _: 3
  }, 8, ["visible"]))
}