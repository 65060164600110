
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { useRoute } from 'vue-router';
import InlineSvg from 'vue-inline-svg';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '../components/Icone.vue';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { EStatusRetornoRequisicao } from '../models/IRetornoRequisicao';
import router from '@/router';
import GerenteAutenticacao from '../gerentes/GerenteAutenticacao';
import { IDTOAssinaturaDetalhe } from '@/models/DTO/PainelAdministrativo/IDTOAssinaturaDetalhe';
import ServicoPAS from '@/servicos/ServicoPAS';
import { EStatusAssinatura } from '@/models/Enumeradores/MeuSistema/EStatusAssinatura';

export default defineComponent({
  name: 'EsqueciMinhaSenha',
  components: {
    InlineSvg,
    Icone,
  },
  setup() {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const gerenteAutenticacao = new GerenteAutenticacao();
    const route = useRoute();

    const state = reactive({
      chaveParceiroAmbiente: '',
      chaveParceiro: '',
      email: '',
      carregando: false,
    });

    async function atualizarUrlAmbiente() {
      let detalhesAssinatura:IDTOAssinaturaDetalhe = {} as IDTOAssinaturaDetalhe;
      if (storeSistema.state.configuracaoApp.tipoAmbiente === 2) {
        detalhesAssinatura = await new ServicoPAS().obterDetalhesAssinatura(state.chaveParceiro);
        if (!UtilitarioGeral.objetoValido(detalhesAssinatura) || detalhesAssinatura.codigoContratante === 0) {
          router.push({ name: 'AmbienteNaoEncontrado' });
          return;
        }
        storeSistema.mutations.atualizarUrlApiESignalR(detalhesAssinatura.urlApi);

        if (detalhesAssinatura.status !== EStatusAssinatura.Ativa && detalhesAssinatura.status !== EStatusAssinatura.Teste) {
          router.push({ name: 'AssinaturaInativa' });
        }

        gerenteAutenticacao.salvarIdentificadorContratante(detalhesAssinatura.codigoContratante.toString());
      }
    }

    async function enviarConfirmacaoEmail() {
      if (state.email === '') {
        apresentarMensagemAlerta('E-mail não preenchido!');
        return;
      }

      if (state.chaveParceiro === '' || !UtilitarioGeral.validaNumerico(state.chaveParceiro)) {
        apresentarMensagemAlerta('Código do contratante não informado!');
        return;
      }
      state.carregando = true;
      await atualizarUrlAmbiente();

      const servicoSistema = new ServicoSistema();
      const retorno = await servicoSistema.enviarEmailRedefinicaoSenha(state.email);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso('Entre em seu e-mail para continuar a redefinição de senha!');
        router.push({ name: 'Login' });
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
      state.carregando = false;
    }

    onBeforeMount(async () => {
      state.carregando = true;
      const contratante = String(route.query.contratante);
      const email = String(route.query.email);

      if (contratante !== 'undefined' && UtilitarioGeral.valorValido(contratante)) {
        state.chaveParceiroAmbiente = contratante;
      } else {
        state.chaveParceiroAmbiente = gerenteAutenticacao.obtemIdentificadorContratante();
      }
      if (email !== 'undefined' && UtilitarioGeral.valorValido(email)) {
        state.email = email;
      } else {
        state.email = '';
      }

      if (state.chaveParceiroAmbiente !== undefined && state.chaveParceiroAmbiente !== '') {
        state.chaveParceiro = state.chaveParceiroAmbiente;
      }
      state.carregando = false;
    });

    return {
      state,
      telaBase,
      storeSistema,
      enviarConfirmacaoEmail,
    };
  },
});
