
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { IConfiguracaoInicial } from '@/models/Entidades/MeuSistema/IConfiguracaoInicial';
import { EEtapaConfiguracaoInicial } from '@/models/Enumeradores/MeuSistema/EEtapaConfiguracaoInicial';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import storeSistema from '@/store/storeSistema';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IDTOUsuarioPermissoesDados } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoesDados';
import { IDTOUsuarioPermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoes';
import PlanoContaModal from '@/views/Cadastros/PlanosContas/PlanoContaModal.vue';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import SelecionarGenericoString from '@/core/components/Tela/SelecionarGenericoString.vue';
import FormaPagamentoModal from '@/views/Cadastros/Financeiro/FormasPagamento/FormaPagamentoModal.vue';
import CentroCustoModal from '@/views/Cadastros/PlanosContas/CentrosCustos/CentroCustoModal.vue';
import ContaModal from '@/views/Cadastros/Financeiro/Contas/ContaModal.vue';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';

export default defineComponent({
  name: 'ConfiguracaoInicialProdutos',
  props: {
    configuracao: {
      type: Object as () => IConfiguracaoInicial,
      required: true,
    },
  },
  components: {
    PlanoContaModal,
    FormaPagamentoModal,
    CentroCustoModal,
    ContaModal,
    SelecionarGenericoString,
  },
  emits: ['update:configuracao', 'atualizarConfiguracao'],
  setup(props, { emit }) {
    const servicoSistema = new ServicoSistema();

    const state = reactive({
      permissaoTipoDocumentoFinanceiro: false,
      permissaoFormaPagamento: false,
      permissaoPlanoContas: false,
      permissaoCentroCusto: false,
      permissaoConta: false,
      exibirPlanoContas: false,
      exibirFormaPagamento: false,
      exibirCentroCusto: false,
      exibirConta: false,
      optionsTipoDocumentoFinanceiro: [] as IOptionString[],
      tiposDocumentosFinanceiroSelecionados: [] as string[],
      carregando: false,
      concluindo: false,
      operacao: {} as ITelaOperacao,
    });

    const computedConfiguracao = computed({
      get: () => props.configuracao,
      set: (val: IConfiguracaoInicial) => {
        emit('update:configuracao', val);
      },
    });

    function abrirFormaPagamento() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_FORMAS_PAGAMENTO';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirFormaPagamento = true;
    }

    function abrirPlanoContas() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_PLANOS_CONTAS';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirPlanoContas = true;
    }

    function abrirCentroCusto() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_CENTROS_CUSTOS';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirCentroCusto = true;
    }

    function abrirConta() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_CONTAS';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirConta = true;
    }

    function adicionarOptionTipoDocumentoFinanceiro(sigla: string, descricao: string) {
      const option = {} as IOptionString;
      option.label = descricao;
      option.value = sigla;
      state.optionsTipoDocumentoFinanceiro.push(option);
      state.tiposDocumentosFinanceiroSelecionados.push(sigla);
    }

    function preencherOpcoesTipoDocumentoFinanceiro() {
      state.optionsTipoDocumentoFinanceiro = [] as IOptionString[];
      state.tiposDocumentosFinanceiroSelecionados = [] as string[];
      adicionarOptionTipoDocumentoFinanceiro('DIH', 'Dinheiro');
      adicionarOptionTipoDocumentoFinanceiro('C', 'Cheque');
      adicionarOptionTipoDocumentoFinanceiro('CTC', 'Cartão de Crédito');
      adicionarOptionTipoDocumentoFinanceiro('CTD', 'Cartão de Débito');
      adicionarOptionTipoDocumentoFinanceiro('VP', 'Vale Presente');
      adicionarOptionTipoDocumentoFinanceiro('B', 'Boleto');
      adicionarOptionTipoDocumentoFinanceiro('P', 'PIX');
      adicionarOptionTipoDocumentoFinanceiro('DEP', 'Depósito');
      adicionarOptionTipoDocumentoFinanceiro('TB', 'Transferência Bancária');
      adicionarOptionTipoDocumentoFinanceiro('CEC', 'Crédito do Cliente');
      adicionarOptionTipoDocumentoFinanceiro('CEF', 'Crédito Fornecedor');
    }

    function concluir() {
      state.carregando = true;
      computedConfiguracao.value.usarDinheiro = state.tiposDocumentosFinanceiroSelecionados.includes('DIH');
      computedConfiguracao.value.usarCheque = state.tiposDocumentosFinanceiroSelecionados.includes('C');
      computedConfiguracao.value.usarCartaoCredito = state.tiposDocumentosFinanceiroSelecionados.includes('CTC');
      computedConfiguracao.value.usarCartaoDebito = state.tiposDocumentosFinanceiroSelecionados.includes('CTD');
      computedConfiguracao.value.usarValePresente = state.tiposDocumentosFinanceiroSelecionados.includes('VP');
      computedConfiguracao.value.usarBoleto = state.tiposDocumentosFinanceiroSelecionados.includes('B');
      computedConfiguracao.value.usarPix = state.tiposDocumentosFinanceiroSelecionados.includes('P');
      computedConfiguracao.value.usarDeposito = state.tiposDocumentosFinanceiroSelecionados.includes('DEP');
      computedConfiguracao.value.usarTransferenciaBancaria = state.tiposDocumentosFinanceiroSelecionados.includes('TB');
      computedConfiguracao.value.usarCreditoCliente = state.tiposDocumentosFinanceiroSelecionados.includes('CEC');
      computedConfiguracao.value.usarCreditoFornecedor = state.tiposDocumentosFinanceiroSelecionados.includes('CEF');
      state.concluindo = true;
      emit('atualizarConfiguracao');
    }

    function validarPermissoes() {
      let pularEtapa = true;
      if (state.permissaoTipoDocumentoFinanceiro) {
        pularEtapa = false;
      }

      if (state.permissaoFormaPagamento) {
        pularEtapa = false;
      }

      if (state.permissaoPlanoContas) {
        pularEtapa = false;
      }

      if (state.permissaoCentroCusto) {
        pularEtapa = false;
      }

      if (state.permissaoConta) {
        pularEtapa = false;
      }

      if (pularEtapa) {
        state.tiposDocumentosFinanceiroSelecionados = [] as string[];
        concluir();
      }
    }

    async function preencherPermissoes() {
      const permissaoTipoDocumentoFinanceiro = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_TIPOS_DOCUMENTO_FINANCEIRO');
      const permissaoFormaPagamento = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_FORMAS_PAGAMENTO');
      const permissaoPlanoContas = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_PLANOS_CONTAS');
      const permissaoCentroCusto = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_CENTROS_CUSTOS');
      const permissaoConta = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_CONTAS');

      if (permissaoTipoDocumentoFinanceiro !== undefined) {
        state.permissaoTipoDocumentoFinanceiro = permissaoTipoDocumentoFinanceiro[0].incluir;
      } else {
        state.permissaoTipoDocumentoFinanceiro = false;
      }
      if (permissaoFormaPagamento !== undefined) {
        state.permissaoFormaPagamento = permissaoFormaPagamento[0].incluir;
      } else {
        state.permissaoFormaPagamento = false;
      }
      if (permissaoPlanoContas !== undefined) {
        state.permissaoPlanoContas = permissaoPlanoContas[0].incluir;
      } else {
        state.permissaoPlanoContas = false;
      }
      if (permissaoCentroCusto !== undefined) {
        state.permissaoCentroCusto = permissaoCentroCusto[0].incluir;
      } else {
        state.permissaoCentroCusto = false;
      }
      if (permissaoConta !== undefined) {
        state.permissaoConta = permissaoConta[0].incluir;
      } else {
        state.permissaoConta = false;
      }
      validarPermissoes();
    }

    onBeforeMount(async () => {
      state.carregando = true;
      if (props.configuracao.etapaAtual === EEtapaConfiguracaoInicial.Financeiro) {
        preencherOpcoesTipoDocumentoFinanceiro();
        await preencherPermissoes();
      }
      state.carregando = false;
    });

    return {
      props,
      state,
      EEtapaConfiguracaoInicial,
      computedConfiguracao,
      abrirFormaPagamento,
      abrirPlanoContas,
      abrirCentroCusto,
      abrirConta,
      concluir,
    };
  },
});
