import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IPerfilUsuario } from '@/models/Entidades/MeuSistema/Usuarios/PerfisUsuario/IPerfilUsuario';
import { IDTOControlePermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOControlePermissoes';
/**
 * Serviço de Perfil de Usuários
 * Fornece todas regras de negócios e lógicas relacionado aos Perfil de Usuários.
 */

class ServicoPerfilUsuario implements IServicoBase<IPerfilUsuario> {
  endPoint = 'perfis-usuarios';

  apiERP = new ApiERP();

  public requisicaoSistema(): void {
    this.apiERP = new ApiERP(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaTeste = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
    return listaTeste;
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    return this.apiERP.consultaRapida(this.endPoint, parametrosConsultaRapida);
  }

  public async obterTodos(empresas: Array<number>, apenasAtivos: boolean): Promise<IItemConsultaRapida[]> {
    const parametrosConsultaRapida: IParametrosConsultaRapida = {
      valor: '',
      recursoAssociado: '',
      apenasAtivos,
      quantidadeRegistros: 99999,
    };
    parametrosConsultaRapida.empresas = [];
    parametrosConsultaRapida.empresas = empresas;

    return this.apiERP.consultaRapida(this.endPoint, parametrosConsultaRapida);
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IPerfilUsuario): string {
    return objeto.descricao;
  }

  public async incluir(perfilUsuario: IPerfilUsuario): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}`, perfilUsuario);
    return result.data;
  }

  public async alterar(perfilUsuario: IPerfilUsuario): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${perfilUsuario.codigo}`, perfilUsuario);
    return result.data;
  }

  public async obter(codigo: number): Promise<IPerfilUsuario> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterPermissoesControle(codigo: number, empresas: number[]): Promise<IDTOControlePermissoes[]> {
    const parametrosAdicionais = this.apiERP.obterParametroEmpresas(empresas);

    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/permissoes/controle${parametrosAdicionais}`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }
}

export default ServicoPerfilUsuario;
