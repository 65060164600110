
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarTipoEstoque from '@/components/Cadastros/Estoques/SelecionarTipoEstoque.vue';
import SelecionarTipoProduto from '@/components/Cadastros/Produtos/SelecionarTipoProduto.vue';
import SelecionarEstoque from '@/components/Cadastros/Estoques/SelecionarEstoque.vue';
import SelecionarCentroCusto from '@/components/Cadastros/PlanosContas/SelecionarCentroCusto.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import {
  IEstoque, IEstoqueEmpresa, IEstoqueTipoProduto, IEstoqueUtilizador,
} from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoEstoque } from '@/models/Enumeradores/Cadastros/Estoques/ETipoEstoque';

export default defineComponent({
  name: 'EstoqueModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    SelecionarSimNao,
    MensagemSemDados,
    SelecionarEmpresaCadastroCompartilhado,
    SelecionarPessoa,
    SelecionarTipoEstoque,
    SelecionarCentroCusto,
    SelecionarTipoProduto,
    SelecionarEstoque,
    RequisicaoModal,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasCadastroCompartilhado, preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas,
      apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoEstoque = new ServicoEstoque();
    telaBase.identificadorRecurso = 'CADASTRO_ESTOQUES';
    telaBase.identificadorPermissao = 'PER_CADASTRO_ESTOQUES';

    const state = reactive({
      estoque: {} as IEstoque,
      tiposProdutosSelecionados: [] as number[],
      utilizadorCodigoPessoa: 0,
      utilizadorEntrada: false,
      utilizadorSaida: false,
    });

    function objetoInicial() {
      state.estoque = {} as IEstoque;
      state.estoque.codigo = 0;
      state.estoque.tipo = 1;
      state.estoque.estoqueNegativo = false;
      state.estoque.ajustarEstoque = false;
      state.estoque.movimentarEntrada = true;
      state.estoque.movimentarSaida = true;
      state.estoque.empresas = [];
      state.estoque.tiposProdutos = [];
      state.estoque.utilizadores = [];
      state.estoque.ativo = true;
      state.tiposProdutosSelecionados = [];
    }

    function preparaPersistenciaEmpresas() {
      const estoqueEmpresas: IEstoqueEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.estoque.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            estoqueEmpresas.push(empresaExistente);
          } else {
            const estoqueEmpresa: IEstoqueEmpresa = { codigo: 0, codigoEstoque: state.estoque.codigo, codigoEmpresa };
            estoqueEmpresas.push(estoqueEmpresa);
          }
        });
      }
      state.estoque.empresas = estoqueEmpresas;
    }

    function preparaPersistenciaTiposProdutos() {
      const estoqueTiposProduto: IEstoqueTipoProduto[] = [];
      if (state.tiposProdutosSelecionados.length > 0) {
        state.tiposProdutosSelecionados.forEach((tipoProduto) => {
          const estoqueTipoProdutoExistente = state.estoque.tiposProdutos.find((c) => c.tipoProduto === tipoProduto);
          if (estoqueTipoProdutoExistente !== undefined) {
            estoqueTiposProduto.push(estoqueTipoProdutoExistente);
          } else {
            const estoqueTipoProduto: IEstoqueTipoProduto = { codigo: 0, codigoEstoque: state.estoque.codigo, tipoProduto };
            estoqueTiposProduto.push(estoqueTipoProduto);
          }
        });
      }
      state.estoque.tiposProdutos = estoqueTiposProduto;
    }

    async function obterEstruturaOrganizacional(codigo: number) {
      state.estoque.estruturaOrganizacional = await servicoEstoque.obterEstruturaOrganizacional(codigo);
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      preparaPersistenciaEmpresas();
      preparaPersistenciaTiposProdutos();
      apresentarBarraProgresso();

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoEstoque.incluir(state.estoque);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoEstoque.alterar(state.estoque);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    function removerUtilizadorEstoque(index: number) {
      state.estoque.utilizadores.splice(index, 1);
    }

    function adicionarUtilizadorEstoque() {
      if (!UtilitarioGeral.validaCodigo(state.utilizadorCodigoPessoa)) {
        apresentarMensagemAlerta('Por favor selecione uma pessoa!');
        return;
      }

      if (UtilitarioGeral.validaLista(state.utilizadorCodigoPessoa)) {
        if (state.estoque.utilizadores.some((c) => c.codigoPessoa === state.utilizadorCodigoPessoa)) {
          apresentarMensagemAlerta('A pessoa já foi adicionada!');
          return;
        }
      }

      const estoqueUtilizador: IEstoqueUtilizador = {} as IEstoqueUtilizador;
      estoqueUtilizador.codigo = 0;
      estoqueUtilizador.codigoEstoque = state.estoque.codigo;
      estoqueUtilizador.codigoPessoa = state.utilizadorCodigoPessoa;
      estoqueUtilizador.saida = state.utilizadorSaida;
      estoqueUtilizador.entrada = state.utilizadorEntrada;

      state.estoque.utilizadores.push(estoqueUtilizador);
      state.utilizadorCodigoPessoa = 0;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.estoque = await servicoEstoque.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          if (UtilitarioGeral.validaLista(state.estoque.empresas)) {
            state.estoque.empresas.forEach((tipoPrecoEmpresa) => {
              telaBase.empresasSelecionadas.push(tipoPrecoEmpresa.codigoEmpresa);
            });
          }
          if (UtilitarioGeral.validaLista(state.estoque.tiposProdutos)) {
            state.estoque.tiposProdutos.forEach((tipoProduto) => {
              state.tiposProdutosSelecionados.push(tipoProduto.tipoProduto);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }

        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      ETipoEstoque,
      salvar,
      objetoInicial,
      EPermissaoDados,
      obterEstruturaOrganizacional,
      adicionarUtilizadorEstoque,
      removerUtilizadorEstoque,
    };
  },
});
