import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import storeSistema from '@/store/storeSistema';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IPlanoConta, IPlanoContaNivel } from '@/models/Entidades/Cadastros/PlanosContas/IPlanoConta';
import { IPlanoContaCategoria } from '@/models/Entidades/Cadastros/PlanosContas/IPlanoContaCategoria';
import { IDTOPlanoContaCategoriaCentroCusto } from '@/models/DTO/Cadastros/PlanosContas/IDTOPlanoContaCategoriaCentroCusto';
import { IDTORetornoNumeroCategoria } from '@/models/DTO/Cadastros/PlanosContas/IDTORetornoNumeroCategoria';
import { IDTOPlanoConta } from '@/models/DTO/Cadastros/PlanosContas/IDTOPlanoConta';
import { IDTOPlanoContaCategoriaContaContabilReferenciada } from '@/models/DTO/Cadastros/PlanosContas/IDTOPlanoContaCategoriaContaContabilReferenciada';

/**
 * Serviço de Planos de Contas
 * Fornece todas regras de negócios e lógicas relacionado a planos de conta.
 */

class ServicoPlanoContas implements IServicoBase<IPlanoConta> {
    endPoint = 'planos-contas';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOPlanoConta): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async obterPlanoContasConsultaRapida(codigoPlanoConta: number): Promise<IItemConsultaRapida> {
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        valor: codigoPlanoConta.toString(), filtrarPorCodigo: true, recursoAssociado: '',
      };
      parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();

      const listaPlanoContas = await this.consultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaLista(listaPlanoContas)) {
        return listaPlanoContas[0];
      }

      return {} as IItemConsultaRapida;
    }

    public async incluir(centroCusto: IPlanoConta): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, centroCusto);
      return result.data;
    }

    public async alterar(centroCusto: IPlanoConta): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${centroCusto.codigo}`, centroCusto);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IPlanoConta> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obterNiveis(codigo: number, empresa: number): Promise<IPlanoContaNivel[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/niveis/empresa/${empresa}`);
      return result.data;
    }

    public async obterCategorias(codigo: number, empresa: number, valorPequisa?: string): Promise<IPlanoContaCategoria[]> {
      let parametrosAdicionais = '';
      if (valorPequisa !== undefined) {
        parametrosAdicionais += `?valorPesquisa=${valorPequisa}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/categorias/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterCategoriascontaContabilReferenciada(codigo: number, empresa: number, valorPequisa?: string): Promise<IDTOPlanoContaCategoriaContaContabilReferenciada[]> {
      let parametrosAdicionais = '';
      if (valorPequisa !== undefined) {
        parametrosAdicionais += `?valorPesquisa=${valorPequisa}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/categorias/contas-contabeis-referenciadas/empresa/${codigo}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterMascara(codigo: number, empresa: number): Promise<string> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/mascara/empresa/${empresa}`);
      return result.data;
    }

    public async consultaRapidaCategoria(parametrosConsultaRapida: IParametrosConsultaRapida, codigoPlanoConta?:number, tipoPlanoConta?:number, tipoCategoria?:string, apresentarNumero?:boolean): Promise<IItemConsultaRapida[]> {
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);

      if (codigoPlanoConta !== undefined) {
        if (codigoPlanoConta > 0) {
          parametrosAdicionais += `&codigoPlanoConta=${codigoPlanoConta}`;
        }
      }

      if (tipoPlanoConta !== undefined) {
        if (tipoPlanoConta > 0) {
          parametrosAdicionais += `&tipoPlanoConta=${tipoPlanoConta}`;
        }
      }

      if (tipoCategoria !== undefined) {
        if (UtilitarioGeral.valorValido(tipoCategoria)) {
          parametrosAdicionais += `&tipoCategoria=${tipoCategoria}`;
        }
      }
      if (UtilitarioGeral.objetoValido(apresentarNumero)) {
        parametrosAdicionais += `&apresentarNumero=${apresentarNumero}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/categorias/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async proximoNumeroCategoria(codigo: number, codigoCategoriaPai: number): Promise<IDTORetornoNumeroCategoria> {
      let categoriaPaiParametro = 0;
      if (UtilitarioGeral.validaCodigo(codigoCategoriaPai)) {
        categoriaPaiParametro = codigoCategoriaPai;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/categorias/${categoriaPaiParametro}/proximo-numero`);
      return result.data;
    }

    public async incluirCategoria(categoria: IPlanoContaCategoria): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/categorias`, categoria);
      return result.data;
    }

    public async alterarCategoria(categoria: IPlanoContaCategoria): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/categorias/${categoria.codigo}`, categoria);
      return result.data;
    }

    public async obterCategoria(codigo: number, empresa: number): Promise<IPlanoContaCategoria> {
      const result: any = await this.apiERP.get(`${this.endPoint}/categorias/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluirCategoria(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/categorias/${codigo}`);
      return result.data;
    }

    public async obterCentrosCustos(codigo: number, empresa: number): Promise<IDTOPlanoContaCategoriaCentroCusto[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/categorias/${codigo}/centros-custos/empresa/${empresa}`);
      return result.data;
    }

    public async atualizarVinculoCategoriasPlanoContas(empresas: number[], categorias: IDTOPlanoContaCategoriaContaContabilReferenciada[]): Promise<IRetornoRequisicao> {
      let parametroEmpresas = '';

      empresas.forEach((empresa) => {
        parametroEmpresas += (parametroEmpresas !== '' ? '&' : '?');
        parametroEmpresas += `Empresas=${empresa}`;
      });

      const result: any = await this.apiERP.put(`${this.endPoint}/categorias/contas-contabeis-referenciadas${parametroEmpresas}`, categorias);
      return result.data;
    }
}
export default ServicoPlanoContas;
