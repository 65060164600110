import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IDTOTipoDocumentoFinanceiro } from '@/models/DTO/Cadastros/Financeiro/IDTOTipoDocumentoFinanceiro';
import { ITipoDocumentoFinanceiro } from '@/models/Entidades/Cadastros/Financeiro/ITipoDocumentoFinanceiro';

/**
 * Serviço de Tipos de Documentos Financeiros
 * Fornece todas regras de negócios e lógicas relacionado a tipo de Documentos financeiros.
 */

class ServicoTipoDocumentoFinanceiro implements IServicoBase<ITipoDocumentoFinanceiro> {
    endPoint = 'tipos-documentos-financeiros';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOTipoDocumentoFinanceiro): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, meioPagamento?: number, contasReceber?: boolean, contasPagar?: boolean, movimentacaoEntradaRecebimentos?: boolean, movimentacaoSaidaPagamentos?: boolean, tiposDocumentosEspecificos?: number[]): Promise<IItemConsultaRapida[]> {
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);

      if (meioPagamento !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `meioPagamento=${meioPagamento}`;
      }

      if (contasReceber !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `contasReceber=${contasReceber}`;
      }

      if (contasPagar !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `contasPagar=${contasPagar}`;
      }

      if (movimentacaoEntradaRecebimentos !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `movimentacaoEntradaRecebimentos=${movimentacaoEntradaRecebimentos}`;
      }

      if (movimentacaoSaidaPagamentos !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `movimentacaoSaidaPagamentos=${movimentacaoSaidaPagamentos}`;
      }

      if (tiposDocumentosEspecificos !== undefined) {
        tiposDocumentosEspecificos.forEach((codigoTipoDocumento) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `tiposDocumentosEspecificos=${codigoTipoDocumento}`;
        });
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(tipoDocumento: ITipoDocumentoFinanceiro): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, tipoDocumento);
      return result.data;
    }

    public async alterar(tipoDocumento: ITipoDocumentoFinanceiro): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${tipoDocumento.codigo}`, tipoDocumento);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<ITipoDocumentoFinanceiro> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }
}
export default ServicoTipoDocumentoFinanceiro;
