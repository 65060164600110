import ApiPAS from '@/core/conectores/ApiPAS';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTORetornoConsultaCNPJ } from '@/models/DTO/Integracoes/IDTORetornoConsultaCNPJ';
import { IDTORetornoValidacaoCPFCNPJ } from '@/models/DTO/Integracoes/IDTORetornoValidacaoCPFCNPJ';
import { IDTOAssinaturaDetalhe } from '@/models/DTO/PainelAdministrativo/IDTOAssinaturaDetalhe';
import { IDTONovaContratacao } from '@/models/DTO/PainelAdministrativo/IDTONovaContratacao';
import { IPlanoAssinatura } from '@/models/Entidades/MeuSistema/PlanoAssinatura/IPlanoAssinatura';
import { ICuradoriaCadastro } from '@/models/Entidades/PainelAdministrativo/ICuradoriaCadastro';
import { EStatusCuradoriaCadastro } from '@/models/Enumeradores/PainelAdministrativo/EStatusCuradoriaCadastro';
import storeSistema from '@/store/storeSistema';

class ServicoPAS {
    private apiPAS = new ApiPAS();

    public async obterDetalhesAssinatura(codigoContratante: string):Promise<IDTOAssinaturaDetalhe> {
      const result: any = await this.apiPAS.get(`painel-administrativo/controle-assinaturas/detalhes/${codigoContratante}`);
      return result.data;
    }

    public async verificarContratanteCpfCnpj(cpfCnpj: string):Promise<IRetornoRequisicao> {
      const result: any = await this.apiPAS.get(`painel-administrativo/controle-assinaturas/contratante/cpfcnpj/${cpfCnpj}`);
      return result.data;
    }

    public async verificarContratanteEmail(email: string):Promise<IRetornoRequisicao> {
      const result: any = await this.apiPAS.get(`painel-administrativo/controle-assinaturas/contratante/email/${email}`);
      return result.data;
    }

    public async obterTodosPlanosAtivos():Promise<IPlanoAssinatura[]> {
      const result: any = await this.apiPAS.get('painel-administrativo/planos-assinatura/todos/ativos');
      return result.data;
    }

    public async novaContratacao(objeto: IDTONovaContratacao):Promise<IRetornoRequisicao> {
      const result: any = await this.apiPAS.post('painel-administrativo/controle-assinaturas/nova', objeto);
      return result.data;
    }

    public async solicitarContatoContratacao(objeto: IDTONovaContratacao):Promise<IRetornoRequisicao> {
      const result: any = await this.apiPAS.post('painel-administrativo/controle-assinaturas/solicitacao-contato', objeto);
      return result.data;
    }

    public async validarCpfCnpj(valor: string):Promise<IDTORetornoValidacaoCPFCNPJ> {
      const result: any = await this.apiPAS.post(`utilitarios/validar-cpf-cnpj/${valor}`);
      return result.data;
    }

    public async consultaCNPJ(cnpj: string):Promise<IDTORetornoConsultaCNPJ> {
      let retornoConsultaCNPJ:IDTORetornoConsultaCNPJ = {} as IDTORetornoConsultaCNPJ;
      retornoConsultaCNPJ.cnpj = UtilitarioMascara.removerMascara(cnpj);
      if (UtilitarioGeral.validaValorComLimiteCaracteres(retornoConsultaCNPJ.cnpj, 14)) {
        const result: any = await this.apiPAS.get(`utilitarios/consulta-cnpj/${retornoConsultaCNPJ.cnpj}`);
        retornoConsultaCNPJ = result.data;
      }
      return retornoConsultaCNPJ;
    }

    public async solicitarCadastroCuradoria(recurso:string, objeto: any):Promise<IRetornoRequisicao> {
      const curadoriaCadastro:ICuradoriaCadastro = {} as ICuradoriaCadastro;
      curadoriaCadastro.codigo = 0;
      curadoriaCadastro.dataSolicitacao = '';
      curadoriaCadastro.codigoContratante = storeSistema.getters.codigoEmpresaOperacao();
      curadoriaCadastro.identificacao = storeSistema.getters.dadosIdentificacaoEmpresa();
      curadoriaCadastro.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      curadoriaCadastro.nomeUsuario = storeSistema.getters.nomeUsuarioAutenticado();
      curadoriaCadastro.recurso = recurso;
      curadoriaCadastro.dados = JSON.stringify(objeto);
      curadoriaCadastro.status = EStatusCuradoriaCadastro.Pendente;
      const result: any = await this.apiPAS.post('painel-administrativo/curadoria-cadastros', curadoriaCadastro);
      return result.data;
    }
}

export default ServicoPAS;
