import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_4 = { class: "ant-row" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_6 = { class: "ant-row" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_empresa_cadastro_compartilhado = _resolveComponent("selecionar-empresa-cadastro-compartilhado")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_categoria = _resolveComponent("selecionar-categoria")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_requisicao_modal = _resolveComponent("requisicao-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      keyboard: false,
      centered: "",
      visible: _ctx.modalBase.computedVisivel,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
      "wrap-class-name": "ss-modal-cadastros"
    }, {
      title: _withCtx(() => [
        _createTextVNode(" Cadastro de Categoria de Produtos "),
        _createVNode(_component_a_switch, {
          checked: _ctx.state.categoriaProduto.ativo,
          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.categoriaProduto.ativo) = $event)),
          style: {"float":"right"},
          "checked-children": "Ativa",
          "un-checked-children": "Inativa"
        }, null, 8, ["checked"])
      ]),
      closeIcon: _withCtx(() => [
        _createVNode(_component_icone, { nome: "voltar" })
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", null, [
          ((_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Incluir && _ctx.telaBase.permissaoDados.incluir)
                    || (_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Visualizar && _ctx.telaBase.permissaoDados.alterar))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("button", {
                  type: "button",
                  class: "ant-btn ant-btn-lg ant-btn-secondary",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.salvar(true)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvarNovo), 1)
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: "ant-btn ant-btn-lg ant-btn-primary ss-btn-acao-destaque",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.salvar(false)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvar), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_skeleton, {
          loading: _ctx.telaBase.carregando
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "vertical" }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_a_form_item, {
                      label: "Empresas",
                      class: "ss-margin-campos",
                      rules: [{ required: true }]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_selecionar_empresa_cadastro_compartilhado, {
                          identificadorRecurso: _ctx.telaBase.identificadorRecurso,
                          codigosSelecionados: _ctx.telaBase.empresasSelecionadas,
                          "onUpdate:codigosSelecionados": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.telaBase.empresasSelecionadas) = $event))
                        }, null, 8, ["identificadorRecurso", "codigosSelecionados"])
                      ]),
                      _: 1
                    })
                  ])
                ], 512), [
                  [_vShow, _ctx.telaBase.apresentarEmpresas]
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_a_form_item, {
                      label: "Descrição",
                      class: "ss-margin-campos",
                      rules: [{ required: true }]
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          maxlength: "250",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.categoriaProduto.descricao) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.state.categoriaProduto.descricao]
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_a_form_item, {
                      label: "Categoria Pai",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_selecionar_categoria, {
                          codigoSelecionado: _ctx.state.categoriaProduto.codigoCategoriaProdutoPai,
                          "onUpdate:codigoSelecionado": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.categoriaProduto.codigoCategoriaProdutoPai) = $event)),
                          ignorarCodigoCategoria: _ctx.state.categoriaProduto.codigo
                        }, null, 8, ["codigoSelecionado", "ignorarCodigoCategoria"])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_requisicao_modal, {
          visivel: _ctx.modalBase.exibirRequisicaoModal,
          "onUpdate:visivel": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modalBase.exibirRequisicaoModal) = $event)),
          apresentarBarraProgresso: _ctx.modalBase.apresentarBarraProgresso,
          fraseBarraProgresso: _ctx.modalBase.fraseBarraProgresso,
          retornoRequisicao: _ctx.modalBase.retornoRequisicao
        }, null, 8, ["visivel", "apresentarBarraProgresso", "fraseBarraProgresso", "retornoRequisicao"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}