
import { computed, defineComponent } from 'vue';
import { useSelecionarBase } from '@/core/composables/SelecionarBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoPlanoContas from '@/servicos/Cadastros/PlanosContas/ServicoPlanoContas';

export default defineComponent({
  name: 'SelecionarCategoriaPlanoConta',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    empresas: {
      type: Array as () => number[],
      default: () => [],
    },
    codigoPlanoConta: {
      type: Number,
      default: 0,
    },
    descricaoSelecionado: {
      type: String,
      default: '',
    },
    tipoPlanoConta: {
      type: Number,
      default: 1,
    },
    tipoCategoria: {
      type: String,
      default: 'A',
    },
    apresentarNumero: {
      type: Boolean,
      default: true,
    },
    omitirCategorias: {
      type: Array as () => number[],
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:descricaoSelecionado', 'change'],
  setup(props, { emit }) {
    const {
      selecionarBase, montaOpcoesComListaConsultaRapida, verificacaoPreBusca, verificacaoUltimaPesquisa, aguardarConclusaoCarregamento,
      comportamentoPadraoSemResultado, instanciaParametrosConsultaRapidaPorCodigo, instanciaParametrosConsultaRapidaPesquisa,
    } = useSelecionarBase(props, emit);

    let debounce = 0;
    const servicoPlanoContas = new ServicoPlanoContas();

    function preencheListaOpcoes():void {
      if (UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida)) {
        if (props.omitirCategorias !== undefined) {
          props.omitirCategorias.forEach((codigoCategoria) => {
            selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((c) => c.codigo !== codigoCategoria);
          });
        }
        selecionarBase.listaOpcoes = montaOpcoesComListaConsultaRapida(selecionarBase.listaItensConsultaRapida);
      }
    }

    async function obterCategoriaPlanoContaPorCodigo(valor: any) {
      if (!verificacaoPreBusca(valor)) return;
      selecionarBase.listaOpcoes = [];

      const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPorCodigo(valor, true);
      parametrosConsultaRapida.quantidadeRegistros = 9999999;
      parametrosConsultaRapida.apenasAtivos = true;
      selecionarBase.listaItensConsultaRapida = await servicoPlanoContas.consultaRapidaCategoria(parametrosConsultaRapida, props.codigoPlanoConta, props.tipoPlanoConta, props.tipoCategoria, props.apresentarNumero);
      preencheListaOpcoes();
      comportamentoPadraoSemResultado();
    }

    async function pesquisarCategoriaPlanoConta(valorPesquisa: any) {
      selecionarBase.emDigitacao = true;

      if (!verificacaoUltimaPesquisa(valorPesquisa)) return;

      selecionarBase.buscandoDados = true;
      selecionarBase.listaOpcoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPesquisa(valorPesquisa, true);
        if (valorPesquisa.length > 3) {
          parametrosConsultaRapida.quantidadeRegistros = 100;
        }
        parametrosConsultaRapida.apenasAtivos = true;
        selecionarBase.listaItensConsultaRapida = await servicoPlanoContas.consultaRapidaCategoria(parametrosConsultaRapida, props.codigoPlanoConta, props.tipoPlanoConta, props.tipoCategoria, props.apresentarNumero);
        preencheListaOpcoes();
        selecionarBase.valorUltimaPesquisa = valorPesquisa;
        selecionarBase.buscandoDados = false;
      }, 600);
    }

    function obterCategoriaPlanoConta():any {
      if (props.codigoSelecionado === 0) {
        return undefined;
      }
      obterCategoriaPlanoContaPorCodigo(props.codigoSelecionado);
      return props.codigoSelecionado;
    }

    const computedDescricaoSelecionado = computed({
      get: () => props.descricaoSelecionado,
      set: (val: string) => {
        emit('update:descricaoSelecionado', val);
      },
    });

    const computedCodigoSelecionado = computed({
      get: () => obterCategoriaPlanoConta(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        const option = selecionarBase.listaOpcoes.find((c) => c.value === val);
        if (option?.label !== undefined && option?.label !== '') {
          computedDescricaoSelecionado.value = option?.label;
        }
      },
    });

    function obterCategoriasPlanoConta():number[] {
      obterCategoriaPlanoContaPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterCategoriasPlanoConta(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      selecionarBase,
      aguardarConclusaoCarregamento,
      pesquisarCategoriaPlanoConta,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      computedDescricaoSelecionado,
      change,
    };
  },
});
