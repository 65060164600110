import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = {
  title: "Notificações",
  class: "ss-menu-recursos",
  to: "#"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notificacoes = _resolveComponent("notificacoes")!
  const _component_icone = _resolveComponent("icone")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_popover, {
      visible: _ctx.state.exibirNotificacoes,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.exibirNotificacoes) = $event)),
      placement: "bottomLeft",
      trigger: "hover",
      onClick: _ctx.abrirNotificacoes
    }, {
      content: _withCtx(() => [
        _createVNode(_component_notificacoes, { style: {"max-width":"400px"} })
      ]),
      default: _withCtx(() => [
        _createElementVNode("a", _hoisted_2, [
          _createVNode(_component_icone, {
            style: {"font-size":"16px"},
            nome: "notificacao"
          }),
          _withDirectives(_createVNode(_component_a_badge, {
            title: 'Notificações não lidas: ' + _ctx.storeSistema.getters.quantidadeNotificacoesNaoLidas(),
            class: "ss-quantidade-notificacoes",
            count: _ctx.storeSistema.getters.quantidadeNotificacoesNaoLidas(),
            id: "RS-Notificacoes"
          }, null, 8, ["title", "count"]), [
            [_vShow, _ctx.storeSistema.getters.quantidadeNotificacoesNaoLidas() > 0]
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onClick"])
  ]))
}