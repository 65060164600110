
import { computed, defineComponent, reactive } from 'vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import Icone from '../Icone.vue';

export default defineComponent({
  name: 'CampoSenha',
  components: {
    Icone,
  },
  props: {
    valor: {
      type: String,
      default: '',
    },
    tamanhoMaximo: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'keyEnter', 'blur'],
  setup(props, { emit }) {
    const state = reactive({
      esconder: true,
    });

    const computedValor = computed({
      get: () => props.valor,
      set: (valor: string) => {
        emit('update:valor', valor);
      },
    });

    function keyEnter() {
      emit('keyEnter');
    }

    function mostrar() {
      state.esconder = !state.esconder;
    }

    function blur() {
      emit('blur');
    }

    return {
      props,
      state,
      computedValor,
      UtilitarioMascara,
      keyEnter,
      blur,
      mostrar,
    };
  },
});
