export default class UtilitarioMatematico {
  static ObterNumeroAleatorio(): number {
    return Math.floor(Math.random() * 1000000);
  }

  static calcularValorPercentual(valorTotal: number, percentual: number): number {
    const valor = ((valorTotal / 100) * percentual);
    return parseFloat(valor.toFixed(2));
  }

  static calcularPorcentagem(valorTotal: number, valor: number): number {
    const porcentagem = ((valor * 100) / valorTotal);
    return parseFloat(porcentagem.toFixed(2));
  }

  static arredondarValor(valor: number, casasDecimais: number): number {
    const valorArredondado = valor.toFixed(casasDecimais);
    return Number(valorArredondado);
  }

  static valorDecimal2Casas(valor: number): number {
    return parseFloat(valor.toFixed(2));
  }
}
