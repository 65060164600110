
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import storeSistema from '@/store/storeSistema';
import { IParametrosConsultaEstoque } from '@/models/ParametrosRequisicao/Estoques/IParametrosConsultaEstoque';

export default defineComponent({
  name: 'SelecionarEstoque',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    estoques: {
      type: Array as () => number[],
    },
    ignorarEstoques: {
      type: Array as () => number[],
    },
    definicoesProdutos: {
      type: Array as () => number[],
    },
    pessoas: {
      type: Array as () => number[],
    },
    unidades: {
      type: Array as () => number[],
    },
    tiposEstoques: {
      type: Array as () => number[],
    },
    tiposProdutos: {
      type: Array as () => number[],
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida } = useTelaBase();
    const refSelecionarEstoque = ref<HTMLElement | null>(null);

    let debounce = 0;
    const servicoEstoque = new ServicoEstoque();

    const state = reactive({
      listaEstoques: [] as IOption[],
      buscandoDados: false,
      emDigitacao: false,
    });

    async function obterEstoquePorCodigo(valor: any) {
      if (state.emDigitacao) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }
      if (!(valor > 0)) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else if (props.codigoSelecionado === 0) {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaEstoques)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaEstoques.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaEstoques.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaEstoques = [];

      const parametrosConsultaRapida = {} as IParametrosConsultaEstoque;
      parametrosConsultaRapida.tipoMovimentoEstoque = 0;
      parametrosConsultaRapida.tiposEstoque = props.tiposEstoques;
      parametrosConsultaRapida.apenasAtivos = true;

      if (UtilitarioGeral.validaLista(props.empresas)) {
        parametrosConsultaRapida.empresas = props.empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      if (UtilitarioGeral.validaLista(props.estoques)) {
        parametrosConsultaRapida.estoques = props.estoques;
      }

      if (UtilitarioGeral.validaLista(props.definicoesProdutos)) {
        parametrosConsultaRapida.produtosDefinicoes = props.definicoesProdutos;
      }

      if (UtilitarioGeral.validaLista(props.pessoas)) {
        parametrosConsultaRapida.utilizadores = props.pessoas;
      }

      if (UtilitarioGeral.validaCodigo(props.unidades)) {
        parametrosConsultaRapida.unidades = props.unidades;
      }

      if (UtilitarioGeral.validaCodigo(props.tiposProdutos)) {
        parametrosConsultaRapida.tiposProdutos = props.tiposProdutos;
      }

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.filtrarPorCodigo = false;
        parametrosConsultaRapida.valores = valor;
        parametrosConsultaRapida.valor = '';
      } else {
        parametrosConsultaRapida.filtrarPorVariosCodigos = false;
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = String(valor);
        parametrosConsultaRapida.valores = [] as string[];
      }

      const listaEstoques = await servicoEstoque.consultaRapida(parametrosConsultaRapida);
      state.listaEstoques = montaOpcoesComListaConsultaRapida(listaEstoques);
      if (!UtilitarioGeral.validaLista(listaEstoques)) {
        if (props.varios) {
          emit('update:codigosSelecionados', undefined);
        } else {
          emit('update:codigoSelecionado', undefined);
        }
      }
    }

    async function pesquisarEstoque(valorPesquisa: any) {
      state.emDigitacao = true;

      state.buscandoDados = true;
      state.listaEstoques = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = {} as IParametrosConsultaEstoque;
        parametrosConsultaRapida.tipoMovimentoEstoque = 0;
        parametrosConsultaRapida.tiposEstoque = [1, 3];
        parametrosConsultaRapida.apenasAtivos = true;
        parametrosConsultaRapida.filtrarPorCodigo = false;
        parametrosConsultaRapida.filtrarPorVariosCodigos = false;
        parametrosConsultaRapida.valor = String(valorPesquisa);
        parametrosConsultaRapida.valores = [] as string[];
        parametrosConsultaRapida.quantidadeRegistros = 100;

        if (UtilitarioGeral.validaLista(props.empresas)) {
          parametrosConsultaRapida.empresas = props.empresas;
        } else {
          parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
        }

        if (UtilitarioGeral.validaLista(props.estoques)) {
          parametrosConsultaRapida.estoques = props.estoques;
        }

        if (UtilitarioGeral.validaLista(props.definicoesProdutos)) {
          parametrosConsultaRapida.produtosDefinicoes = props.definicoesProdutos;
        }

        if (UtilitarioGeral.validaLista(props.pessoas)) {
          parametrosConsultaRapida.utilizadores = props.pessoas;
        }

        if (UtilitarioGeral.validaCodigo(props.unidades)) {
          parametrosConsultaRapida.unidades = props.unidades;
        }
        parametrosConsultaRapida.empresas = props.empresas;
        let listaEstoques = await servicoEstoque.consultaRapida(parametrosConsultaRapida);
        if (props.ignorarEstoques !== undefined) {
          props.ignorarEstoques.forEach((codigoEstoque) => {
            listaEstoques = listaEstoques.filter((c) => c.codigo !== codigoEstoque);
          });
        }
        state.buscandoDados = false;
        state.listaEstoques = montaOpcoesComListaConsultaRapida(listaEstoques);
      }, 600);
    }

    function obterEstoque():any {
      obterEstoquePorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedCodigoSelecionado = computed({
      get: () => obterEstoque(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
      },
    });

    function obterEstoques():number[] {
      obterEstoquePorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterEstoques(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    function focus() {
      if (refSelecionarEstoque.value) {
        refSelecionarEstoque.value.focus();
      }
    }

    return {
      props,
      state,
      refSelecionarEstoque,
      pesquisarEstoque,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
      focus,
    };
  },
});
