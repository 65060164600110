
import {
  computed, defineComponent, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarCentroCusto from '@/components/Cadastros/PlanosContas/SelecionarCentroCusto.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { IDTOPlanoContaCategoriaCentroCusto } from '@/models/DTO/Cadastros/PlanosContas/IDTOPlanoContaCategoriaCentroCusto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'PlanoContaCategoriaCentrosCustoModal',
  props: {
    centrosCusto: {
      type: Object as () => IDTOPlanoContaCategoriaCentroCusto[],
      required: true,
    },
    visivel: {
      type: Boolean,
    },
  },
  components: {
    Icone,
    MensagemSemDados,
    SelecionarCentroCusto,
    CampoNumerico,
  },
  emits: ['update:centrosCusto', 'update:centroCustoSelecionado', 'update:visivel'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      centrosCusto: [] as IDTOPlanoContaCategoriaCentroCusto[],
      centroCustoSelecionado: 0,
      descricaoCentroCustoSelecionado: '',
      proporcao: 100.0,
    });

    const computedCentrosCusto = computed({
      get: () => props.centrosCusto,
      set: (valor: IDTOPlanoContaCategoriaCentroCusto[]) => {
        emit('update:centrosCusto', valor);
      },
    });

    function obterRestoProporcao() {
      if (state.centrosCusto.length === 0) {
        return 100;
      }

      let resto = 100;
      state.centrosCusto.forEach((c) => {
        resto -= c.proporcao;
      });
      return resto;
    }

    function limparTela() {
      if (!modalBase.computedVisivel) {
        state.centrosCusto = [] as IDTOPlanoContaCategoriaCentroCusto[];
      }
      state.centroCustoSelecionado = 0;
      state.descricaoCentroCustoSelecionado = '';
      state.proporcao = obterRestoProporcao();
    }

    function adicionarCentroCusto() {
      const centroCustoExistente = state.centrosCusto.find((c) => c.codigoCentroCusto === state.centroCustoSelecionado);
      if (UtilitarioGeral.valorValido(centroCustoExistente)) {
        apresentarMensagemAlerta('O centro de custo selecionado já foi adicionado!');
        return;
      }

      const centroCusto = {} as IDTOPlanoContaCategoriaCentroCusto;
      centroCusto.codigoCentroCusto = state.centroCustoSelecionado;
      centroCusto.descricaoCentroCusto = state.descricaoCentroCustoSelecionado;
      centroCusto.proporcao = state.proporcao;
      state.centrosCusto.push(centroCusto);
      limparTela();
    }

    function removerCentroCusto(index: number) {
      state.centrosCusto.splice(index, 1);
      state.proporcao = obterRestoProporcao();
    }

    function confirmar() {
      if (obterRestoProporcao() !== 0) {
        apresentarMensagemAlerta('A soma de todas as proporções deve ser 100%.');
        return;
      }
      computedCentrosCusto.value = state.centrosCusto;
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      if (modalBase.computedVisivel) {
        state.centrosCusto = UtilitarioGeral.instanciaObjetoLocal(props.centrosCusto);
        state.proporcao = obterRestoProporcao();
      }
    });

    return {
      props,
      modalBase,
      telaBase,
      state,
      computedCentrosCusto,
      adicionarCentroCusto,
      removerCentroCusto,
      confirmar,
    };
  },
});
