
import { defineComponent, reactive, watch } from 'vue';
import { useModalBase } from '@/core/composables/ModalBase';
import Icone from '@/core/components/Icone.vue';
import { IFormaPagamentoTipoDocumentoFinanceiroParcelamento } from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import { useTelaBase } from '@/core/composables/TelaBase';

export default defineComponent({
  name: 'FormaPagamentoParcelasModal',
  props: {
    visivel: {
      type: Boolean,
    },
    parcelamento: {
      type: Object as () => IFormaPagamentoTipoDocumentoFinanceiroParcelamento,
      required: true,
    },
  },
  components: {
    Icone,
  },
  emits: ['confirmar'],
  setup(props, { emit }) {
    const { modalBase } = useModalBase(props, emit);
    const { apresentarMensagemAlerta } = useTelaBase();

    const state = reactive({
      parcelamento: {} as IFormaPagamentoTipoDocumentoFinanceiroParcelamento,
    });

    function confirmar() {
      for (let i = 0; i < state.parcelamento.parcelas.length; i += 1) {
        if (i > 0) {
          if (state.parcelamento.parcelas[i].prazo < state.parcelamento.parcelas[i - 1].prazo) {
            apresentarMensagemAlerta(`O prazo da parcela ${(i + 1)} é menor que o da parcela ${i}!`);
            return;
          }
        }
      }
      state.parcelamento.prazoTotal = state.parcelamento.parcelas[state.parcelamento.parcelas.length - 1].prazo;
      emit('confirmar', state.parcelamento);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.parcelamento = props.parcelamento;
    });

    return {
      props,
      state,
      modalBase,
      confirmar,
    };
  },
});
