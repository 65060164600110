import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      onChange: _ctx.defineValorSelecionado,
      class: "ss-tamanho-completo"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select_option, { value: "@NUMERO_TITULO" }, {
          default: _withCtx(() => [
            _createTextVNode("Nº do Título")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: "@VENCIMENTO_TITULO" }, {
          default: _withCtx(() => [
            _createTextVNode("Vencimento do Título")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: "@VALOR_TITULO" }, {
          default: _withCtx(() => [
            _createTextVNode("Valor do Título")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: "@NOME_PESSOA" }, {
          default: _withCtx(() => [
            _createTextVNode("Nome da Pessoa")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: "@NOME_EMPRESA" }, {
          default: _withCtx(() => [
            _createTextVNode("Nome da Empresa")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: "@LOGO_EMPRESA" }, {
          default: _withCtx(() => [
            _createTextVNode("Logo da Empresa")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: "@LINK_BOLETO" }, {
          default: _withCtx(() => [
            _createTextVNode("Link do Boleto")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "onChange"])
  ]))
}