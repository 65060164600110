
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { IConfiguracaoInicial } from '@/models/Entidades/MeuSistema/IConfiguracaoInicial';
import { EEtapaConfiguracaoInicial } from '@/models/Enumeradores/MeuSistema/EEtapaConfiguracaoInicial';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import DepartamentoModal from '@/views/Cadastros/Pessoas/Colaboradores/DepartamentoModal.vue';
import UsuarioModal from '@/views/MeuSistema/Usuarios/UsuarioModal.vue';
import PessoaModal from '@/views/Cadastros/Pessoas/PessoaModal.vue';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import ServicoColaborador from '@/servicos/Cadastros/Pessoas/ServicoColaborador';
import storeSistema from '@/store/storeSistema';
import { IDTOUsuarioPermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoes';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IDTOUsuarioPermissoesDados } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoesDados';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import FuncaoModal from '@/views/Cadastros/Pessoas/Colaboradores/FuncaoModal.vue';

export default defineComponent({
  name: 'ConfiguracaoInicialEquipe',
  props: {
    configuracao: {
      type: Object as () => IConfiguracaoInicial,
      required: true,
    },
  },
  components: {
    DepartamentoModal,
    UsuarioModal,
    PessoaModal,
    FuncaoModal,
  },
  emits: ['update:configuracao', 'atualizarConfiguracao'],
  setup(props, { emit }) {
    const servicoSistema = new ServicoSistema();
    const servicoColaborador = new ServicoColaborador();

    const state = reactive({
      permisaoUsuario: false,
      permissaoDepartamento: false,
      permissaoColaborador: false,
      permissaoFuncao: false,
      exibirUsuario: false,
      exibirDepartamento: false,
      exibirColaborador: false,
      exibirFuncao: false,
      carregando: false,
      operacao: {} as ITelaOperacao,
    });

    const computedConfiguracao = computed({
      get: () => props.configuracao,
      set: (val: IConfiguracaoInicial) => {
        emit('update:configuracao', val);
      },
    });

    function abrirUsuario() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_USUARIOS';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirUsuario = true;
    }

    function abrirColaboradores() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_COLABORADORES';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirColaborador = true;
    }

    function abrirDepartamentos() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_DEPARTAMENTOS_COLABORADORES';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirDepartamento = true;
    }

    function abrirFuncao() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_FUNCOES_COLABORADORES';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirFuncao = true;
    }

    function concluir() {
      state.carregando = true;
      emit('atualizarConfiguracao');
    }

    function validarPermissoes() {
      let pularEtapa = true;
      if (state.permisaoUsuario) {
        pularEtapa = false;
      }

      if (state.permissaoColaborador) {
        pularEtapa = false;
      }

      if (state.permissaoDepartamento) {
        pularEtapa = false;
      }

      if (state.permissaoFuncao) {
        pularEtapa = false;
      }

      if (pularEtapa) {
        concluir();
      }
    }

    async function preencherPermissoes() {
      const permissaoUsuario = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_USUARIOS');
      const permissaoFuncao = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_FUNCOES_COLABORADORES');
      const permissaoColaborador = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_COLABORADORES');
      const permissaoDepartamento = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_DEPARTAMENTOS_COLABORADORES');

      if (permissaoUsuario !== undefined) {
        state.permisaoUsuario = permissaoUsuario[0].incluir;
      } else {
        state.permisaoUsuario = false;
      }
      if (permissaoColaborador !== undefined) {
        state.permissaoColaborador = permissaoColaborador[0].incluir;
      } else {
        state.permissaoColaborador = false;
      }
      if (permissaoDepartamento !== undefined) {
        state.permissaoDepartamento = permissaoDepartamento[0].incluir;
      } else {
        state.permissaoDepartamento = false;
      }
      if (permissaoFuncao !== undefined) {
        state.permissaoFuncao = permissaoFuncao[0].incluir;
      } else {
        state.permissaoFuncao = false;
      }
      validarPermissoes();
    }

    onBeforeMount(async () => {
      state.carregando = true;
      if (props.configuracao.etapaAtual === EEtapaConfiguracaoInicial.Equipe) {
        await preencherPermissoes();
      }
      state.carregando = false;
    });

    return {
      props,
      state,
      EEtapaConfiguracaoInicial,
      ETipoRelacaoPessoa,
      servicoColaborador,
      computedConfiguracao,
      abrirUsuario,
      abrirColaboradores,
      abrirDepartamentos,
      abrirFuncao,
      concluir,
    };
  },
});
