
import {
  defineComponent, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoEstado from '@/servicos/Cadastros/Localizacoes/ServicoEstado';
import { IEmpresaIeSubstituicaoTributaria } from '@/models/Entidades/MeuSistema/Empresas/IEmpresaIeSubstituicaoTributaria';
import { IOption } from '@/core/models/AntDesign/IOption';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOEstado } from '@/models/DTO/Cadastros/Localizacoes/IDTOEstado';

export default defineComponent({
  name: 'GerenciarIESubstituicaoTributariaModal',
  emits: ['atualizar-inscricoes-substituicao-tributaria'],
  props: {
    visivel: {
      type: Boolean,
    },
    inscricoesSubstituicaoTributaria: {
      type: Array as () => IEmpresaIeSubstituicaoTributaria[],
      required: true,
    },
    listaEstados: {
      type: Array as () => IDTOEstado[],
      required: true,
    },
  },
  components: {
  },
  setup(props, { emit }) {
    const { modalBase } = useModalBase(props, emit);

    const { apresentarMensagemAlerta } = useTelaBase();

    const servicoEstado = new ServicoEstado();

    const stateModal = reactive({
      inscricoes: [] as IEmpresaIeSubstituicaoTributaria[],
      listaEstados: [] as IOption[],
    });

    (async () => {
      let listaDtoEstados:IDTOEstado[] = [];
      if (UtilitarioGeral.validaLista(props.listaEstados)) {
        listaDtoEstados = props.listaEstados;
      } else {
        listaDtoEstados = await servicoEstado.obterTodos();
      }
      stateModal.listaEstados = listaDtoEstados.map((item) => ({
        label: item.sigla,
        value: item.codigo,
      }));
    })();

    watch(async () => modalBase.computedVisivel, async () => {
      if (modalBase.computedVisivel) {
        stateModal.inscricoes = props.inscricoesSubstituicaoTributaria;
      }
    });

    function obtemSiglaEstado(codigoEstado:number):string {
      let siglaEstado = '';
      if (UtilitarioGeral.validaLista(stateModal.listaEstados)) {
        const estado = stateModal.listaEstados.find((c) => c.value === codigoEstado);
        if (estado !== undefined) {
          siglaEstado = estado.label;
        }
      }
      return siglaEstado;
    }

    function salvarInscricoes() {
      let inscricoesValidas = true;
      for (let i = 0; i < stateModal.inscricoes.length; (i += 1)) {
        const inscricoes = stateModal.inscricoes.filter((c) => c.codigoEstado === stateModal.inscricoes[i].codigoEstado);
        if (inscricoes.length > 1) {
          inscricoesValidas = false;
          apresentarMensagemAlerta(`Você possui mais de uma inscrição para o estado: ${obtemSiglaEstado(stateModal.inscricoes[i].codigoEstado)}. Verifique por favor`);
          break;
        }

        if (!UtilitarioGeral.valorValido(stateModal.inscricoes[i].inscricaoEstadual)) {
          inscricoesValidas = false;
          apresentarMensagemAlerta(`Inscrição do estado: ${obtemSiglaEstado(stateModal.inscricoes[i].codigoEstado)} não foi preenchida!`);
          break;
        }
      }

      if (inscricoesValidas) {
        emit('atualizar-inscricoes-substituicao-tributaria', stateModal.inscricoes);
        modalBase.computedVisivel = false;
      }
    }

    function removerInscricao(item:IEmpresaIeSubstituicaoTributaria) {
      const index = stateModal.inscricoes.indexOf(item);
      if (index !== -1) {
        stateModal.inscricoes.splice(index, 1);
      }
    }

    function adicionarInscricao() {
      if (UtilitarioGeral.validaLista(stateModal.listaEstados)) {
        stateModal.inscricoes.push({
          codigo: 0,
          codigoEmpresa: 0,
          codigoEstado: stateModal.listaEstados[0].value,
          inscricaoEstadual: '',
        });
      } else {
        apresentarMensagemAlerta('Nenhum estado cadastrado ou localizado!');
      }
    }

    function fecharModal() {
      modalBase.computedVisivel = false;
    }
    return {
      modalBase,
      stateModal,
      removerInscricao,
      adicionarInscricao,
      salvarInscricoes,
      fecharModal,
    };
  },
});
