
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoFuncaoColaborador from '@/servicos/Cadastros/Pessoas/ServicoFuncaoColaborador';
import { IFuncaoColaborador } from '@/models/Entidades/Cadastros/Pessoas/Colaboradores/IFuncaoColaborador';
import { IFuncaoColaboradorEmpresa } from '@/models/Entidades/Cadastros/Pessoas/Colaboradores/IFuncaoColaboradorEmpresa';

export default defineComponent({
  name: 'FuncaoColaboradorModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    RequisicaoModal,
    SelecionarEmpresaCadastroCompartilhado,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas, defineEmpresasSelecionadasCadastroCompartilhado,
      preencherPermissoesDados, defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoFuncaoColaborador = new ServicoFuncaoColaborador();
    telaBase.identificadorRecurso = 'CADASTRO_FUNCOES_COLABORADORES';
    telaBase.identificadorPermissao = 'PER_CADASTRO_FUNCOES_COLABORADORES';

    const state = reactive({
      funcaoColaborador: {} as IFuncaoColaborador,
    });

    function objetoInicial() {
      state.funcaoColaborador = {} as IFuncaoColaborador;
      state.funcaoColaborador.empresas = [];
      state.funcaoColaborador.ativo = true;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      const funcaoColaboradorEmpresas : IFuncaoColaboradorEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.funcaoColaborador.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            funcaoColaboradorEmpresas.push(empresaExistente);
          } else {
            const funcaoColaboradorEmpresa: IFuncaoColaboradorEmpresa = { codigo: 0, codigoFuncaoColaborador: state.funcaoColaborador.codigo, codigoEmpresa };
            funcaoColaboradorEmpresas.push(funcaoColaboradorEmpresa);
          }
        });
      }
      state.funcaoColaborador.empresas = funcaoColaboradorEmpresas;

      apresentarBarraProgresso();

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoFuncaoColaborador.incluir(state.funcaoColaborador);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoFuncaoColaborador.alterar(state.funcaoColaborador);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);
        await defineEmpresasSelecionadasComPermissao();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados, true);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.funcaoColaborador = await servicoFuncaoColaborador.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          telaBase.empresasSelecionadas = [];
          if (state.funcaoColaborador.empresas.length > 0) {
            state.funcaoColaborador.empresas.forEach((colaboradorEmpresa) => {
              telaBase.empresasSelecionadas.push(colaboradorEmpresa.codigoEmpresa);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
    };
  },
});
