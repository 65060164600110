import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a74856a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_3 = { class: "ss-h3-nivel ss-alinhar-texto-centro" }
const _hoisted_4 = {
  class: "ant-col ss-div-valor",
  style: {"flex":"1 1 auto"}
}
const _hoisted_5 = { class: "ant-btn-group ss-margin-campos" }
const _hoisted_6 = {
  class: "ant-col ss-alinhar-centro ss-div-remover",
  style: {"flex":"0 0 35px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_card = _resolveComponent("card")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_card, { class: "ss-card-principal ss-margin-campos" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, "Nível " + _toDisplayString(_ctx.indice+1), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "ant-btn ss-botao ss-sombra-suave",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.diminuirValor()))
            }, "-"),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "ant-input ss-campo-valor",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedNivel.valor) = $event)),
              readonly: "true"
            }, null, 512), [
              [_directive_maska, '#'],
              [_vModelText, _ctx.computedNivel.valor]
            ]),
            _createElementVNode("button", {
              class: "ant-btn ss-botao ss-sombra-suave",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.aumentarValor()))
            }, "+")
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_a_tooltip, {
            placement: "top",
            title: "Remover"
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.removerNivel()))
              }, [
                _createVNode(_component_icone, { nome: "excluir" })
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}