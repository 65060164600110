
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AutenticacaoLayout',
  components: {
  },
  props: {
    msg: String,
  },
  setup() {
    return {
    };
  },
});
