import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = {
  title: "Meus Atalhos",
  class: "ss-menu-recursos",
  to: "#"
}
const _hoisted_3 = { style: {"display":"flex","font-size":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_meus_atalhos = _resolveComponent("meus-atalhos")!
  const _component_icone = _resolveComponent("icone")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_popover, {
      placement: "bottomLeft",
      trigger: "hover"
    }, {
      content: _withCtx(() => [
        _createVNode(_component_meus_atalhos)
      ]),
      default: _withCtx(() => [
        _createElementVNode("a", _hoisted_2, [
          _createElementVNode("i", _hoisted_3, [
            _createVNode(_component_icone, { nome: "estrela" })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}