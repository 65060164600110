
import { defineComponent, reactive } from 'vue';
import { ICardFluxo } from '@/core/models/IFluxo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import MensagemSemDados from '../Tela/MensagemSemDados.vue';
import Fluxo from './Fluxo.vue';
import Icone from '../Icone.vue';
import ModalFullScreenFluxo from './ModalFullScreenFluxo.vue';

export default defineComponent({
  name: 'PainelFluxo',
  components: {
    Fluxo,
    MensagemSemDados,
    Icone,
    ModalFullScreenFluxo,
  },
  props: {
    titulo: {
      type: String,
      default: 'Fluxo',
    },
    editavel: {
      type: Boolean,
      default: true,
    },
    largura: {
      type: Number,
      default: 0,
    },
    altura: {
      type: Number,
      default: 0,
    },
    cards: {
      type: Array as () => ICardFluxo[],
      required: true,
    },
  },
  emits: ['selecaoAlterada', 'adicionar', 'editar', 'excluir'],
  setup(props, { emit }) {
    const state = reactive({
      exibirExpandido: false,
      zoom: 100,
    });

    function expandir() {
      state.exibirExpandido = true;
    }

    function zoomin() {
      state.zoom += 10;
    }

    function zoomout() {
      state.zoom -= 10;
    }

    function selecaoCardAlterada(identificador: string) {
      emit('selecaoAlterada', identificador);
    }

    function adicionar(identificador: string) {
      emit('adicionar', identificador);
    }

    function editar(identificador: string) {
      emit('editar', identificador);
    }

    function excluir(identificador: string) {
      emit('excluir', identificador);
    }

    return {
      props,
      state,
      UtilitarioGeral,
      expandir,
      zoomin,
      zoomout,
      selecaoCardAlterada,
      adicionar,
      editar,
      excluir,
    };
  },
});
