
import {
  defineComponent, onBeforeMount, onMounted, h, ref,
} from 'vue';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Button, notification } from 'ant-design-vue';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { EStatusRetornoRequisicao } from '../models/IRetornoRequisicao';
import GerenteAutenticacao from '../gerentes/GerenteAutenticacao';
import router from '@/router';
import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import UtilitarioMascara from '../utilitarios/UtilitarioMascara';
import { INotificacao } from '@/models/Entidades/MeuSistema/Notificacoes/INotificacao';
import { ETipoNotificacao } from '@/models/Enumeradores/MeuSistema/Notificacoes/ETipoNotificacao';
import ServicoNotificacao from '@/servicos/MeuSistema/ServicoNotificacao';
import { ETipoApresentacaoMenu } from '@/models/Enumeradores/MeuSistema/ETipoApresentacaoMenu';
import { ETipoMenuItem } from '@/models/Enumeradores/MeuSistema/ETipoMenu';
import { IDTOAssinaturaDetalhe } from '@/models/DTO/PainelAdministrativo/IDTOAssinaturaDetalhe';
import ServicoPAS from '@/servicos/ServicoPAS';
import { EStatusAssinatura } from '@/models/Enumeradores/MeuSistema/EStatusAssinatura';
import Loading from './Loading.vue';

export default defineComponent({
  name: 'ERPLayout',
  components: {
    Loading,
  },
  props: {
    msg: String,
  },
  setup() {
    storeSistema.mutations.atualizarCarregando(true);
    const gerenteAutenticacao = new GerenteAutenticacao();
    const collapsed = ref(false);
    async function maisDetalhesNotificacao(notificacao: INotificacao) {
      notification.close(notificacao.codigo.toString());

      const retornoConfirmacao = await new ServicoNotificacao().confirmarLeitura(notificacao.codigo);
      if (retornoConfirmacao.status === EStatusRetornoRequisicao.Sucesso) {
        storeSistema.mutations.diminuirNotificacaoNaoLida();
      }
      router.push({ path: `/${notificacao.rotaRecurso}${notificacao.parametros}` });
    }
    async function iniciarSignalR() {
      try {
        const connection = new HubConnectionBuilder()
          .withUrl(storeSistema.state.configuracaoApp.urlHubSignalR, { accessTokenFactory: () => gerenteAutenticacao.obtemTokenAutenticado() })
          .configureLogging(LogLevel.Error)
          .build();

        await connection.start();
        connection.invoke('AutenticarGrupos');
        connection.on('ReceberNotificacao', (notificacao: INotificacao) => {
          let classNotificacao = '';
          if (notificacao.tipo === ETipoNotificacao.Normal) {
            classNotificacao = 'ss-box-notificacao-normal';
          } else if (notificacao.tipo === ETipoNotificacao.Importante) {
            classNotificacao = 'ss-box-notificacao-importante';
          } else if (notificacao.tipo === ETipoNotificacao.Urgente) {
            classNotificacao = 'ss-box-notificacao-urgente';
          }
          storeSistema.mutations.adicionarNotificacaoNaoLida();
          notification.open({
            message: notificacao.titulo,
            class: classNotificacao,
            description: notificacao.mensagem,
            btn: () => h(Button, {
              type: 'primary',
              size: 'small',
              onClick: () => maisDetalhesNotificacao(notificacao),
            }, {
              default: () => 'Mais detalhes',
            }),
            duration: 15,
            key: notificacao.codigo.toString(),
          });
        });
      } catch (err) {
        setTimeout(iniciarSignalR, 5000);
      }
    }

    const handleWindowSizeChange = () => {
      storeSistema.mutations.atualizarLarguraTela();
    };
    onMounted(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      handleWindowSizeChange();
    });
    onBeforeMount(async () => {
      if (!gerenteAutenticacao.existeIdentificadorContratante()) {
        router.push({ name: 'IdentificarAmbiente' });
      }

      if (!gerenteAutenticacao.verificaAutenticacao()) {
        router.push({ name: 'Login' });
      }

      let detalhesAssinatura:IDTOAssinaturaDetalhe = {} as IDTOAssinaturaDetalhe;
      if (storeSistema.state.configuracaoApp.tipoAmbiente === 2 && !UtilitarioGeral.valorValido(storeSistema.state.configuracaoApp.urlApi)) {
        detalhesAssinatura = await new ServicoPAS().obterDetalhesAssinatura(gerenteAutenticacao.obtemIdentificadorContratante());

        if (!UtilitarioGeral.objetoValido(detalhesAssinatura) || detalhesAssinatura.codigoContratante === 0) {
          router.push({ name: 'AmbienteNaoEncontrado' });
          return;
        }

        if (detalhesAssinatura.status !== EStatusAssinatura.Ativa && detalhesAssinatura.status !== EStatusAssinatura.Teste) {
          router.push({ name: 'AvisoAssinatura' });
        }
        storeSistema.mutations.atualizarUrlApiESignalR(detalhesAssinatura.urlApi);
      }

      if (gerenteAutenticacao.atualizarToken()) {
        const retornoAutenticacao = await new ServicoSistema().atualizarToken();
        if (retornoAutenticacao.autenticado) {
          gerenteAutenticacao.salvarDadosAutenticacao(retornoAutenticacao.token, retornoAutenticacao.dataExpiracao.toString());
        }
      }

      await new ServicoSistema().preencherDadosSistema(false);
      if (storeSistema.state.empresasDisponiveis === null || storeSistema.state.empresasDisponiveis === undefined) {
        router.push({ name: 'ErroRequisicao' });
      }
      await iniciarSignalR();
      storeSistema.mutations.atualizarCarregando(false);
    });

    function montarApresentacaoEmpresa(dadosEmpresa: IDTODadosEmpresa): string {
      const exibicao = UtilitarioGeral.montaExibicaoEmpresa(dadosEmpresa.codigoExibicao, dadosEmpresa.nomeExibicao);
      const textoCpfouCnpj = UtilitarioGeral.documentoIdentificacaoCPFouCNPJ(dadosEmpresa.cpfCnpj);
      const cpfCpnpj = UtilitarioMascara.mascararCPFOuCNPJ(dadosEmpresa.cpfCnpj);

      return `${exibicao} | ${textoCpfouCnpj}: ${cpfCpnpj}`;
    }
    function exibirFullScreen() {
      document.body.classList.toggle('fullscreen-enable');
      if (
        !document.fullscreenElement
      ) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        }
      } else {
        document.exitFullscreen();
      }
    }

    async function deslogar() {
      const servicoSistema = new ServicoSistema();
      const gerenteAutenticacao = new GerenteAutenticacao();
      const retornoAutenticacao = await servicoSistema.deslogar();
      if (!retornoAutenticacao.autenticado) {
        gerenteAutenticacao.removerDadosAutenticacao();
        router.push({ name: 'IdentificarAmbiente' });
      }
    }

    return {
      storeSistema,
      current: ['1'],
      exibirFullScreen,
      montarApresentacaoEmpresa,
      ETipoApresentacaoMenu,
      ETipoMenuItem,
      collapsed,
      deslogar,
      UtilitarioGeral,
    };
  },
});
