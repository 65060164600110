
import {
  defineComponent, Transition, reactive, onBeforeMount, computed,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import storeSistema from '@/store/storeSistema';
import { IMenu } from '@/models/Entidades/MeuSistema/IMenu';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'MenuMobileBolhas',
  props: {
    exibir: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Icone,
    Transition,
  },
  emits: ['update:exibir', 'abrirMenu'],
  setup(props, { emit }) {
    const state = reactive({
      menusDisponiveis: [] as IMenu[],
      menusPaginaAtual: [] as IMenu[],
      exibirBolhaAnterior: false,
      exibirBolhaProximo: false,
      exibirMenuRecursos: false,
      exibirNotificacoes: false,
      exibirFavoritos: false,
    });

    const computedExibir = computed({
      get: () => props.exibir,
      set: (valor: boolean) => {
        emit('update:exibir', valor);
      },
    });

    function abrirMenuRecursos(menu: number) {
      emit('abrirMenu', menu);
    }

    async function preencherMenusPagina(indexInicio: number, indexFim: number, exibirBolhaAnterior: boolean, exibirBolhaProximo: boolean, iniciando: boolean) {
      const menus = [] as IMenu[];

      state.menusDisponiveis.forEach((m, index) => {
        if (index >= indexInicio && index <= indexFim) {
          menus.push(UtilitarioGeral.clonarObjeto(m));
        }
      });
      await UtilitarioGeral.sleep(700);
      state.menusPaginaAtual = menus;
      state.exibirBolhaAnterior = exibirBolhaAnterior;
      state.exibirBolhaProximo = exibirBolhaProximo;
      await UtilitarioGeral.sleep(700);
      if (!iniciando) {
        computedExibir.value = true;
      }
    }

    function avancarPaginaMenus(iniciando: boolean) {
      let indexInicio = 0;
      let indexFim = 0;
      let exibirBolhaAnterior = false;
      let exibirBolhaProximo = false;
      computedExibir.value = false;

      if (state.menusPaginaAtual.length === 0) {
        indexInicio = 0;
        if (state.menusDisponiveis.length > 7) {
          indexFim = 5;
          exibirBolhaProximo = true;
        } else {
          indexFim = 6;
        }
      } else {
        indexInicio = state.menusDisponiveis.findIndex((m) => m.codigo === state.menusPaginaAtual[state.menusPaginaAtual.length - 1].codigo);
        indexInicio += 1;
        indexFim = indexInicio + 4;
        exibirBolhaAnterior = true;
        if ((indexFim + 1) >= state.menusDisponiveis.length) {
          indexFim += 1;
        } else {
          state.exibirBolhaProximo = true;
        }
      }
      preencherMenusPagina(indexInicio, indexFim, exibirBolhaAnterior, exibirBolhaProximo, iniciando);
    }

    function regredirPaginaMenus() {
      let indexInicio = 0;
      let indexFim = 0;
      let exibirBolhaAnterior = false;
      let exibirBolhaProximo = false;
      computedExibir.value = false;

      indexFim = state.menusDisponiveis.findIndex((m) => m.codigo === state.menusPaginaAtual[0].codigo);
      indexFim -= 1;
      indexInicio = indexFim - 5;
      exibirBolhaProximo = true;
      if (indexInicio !== 0) {
        exibirBolhaAnterior = true;
      }
      preencherMenusPagina(indexInicio, indexFim, exibirBolhaAnterior, exibirBolhaProximo, false);
    }

    onBeforeMount(async () => {
      state.menusDisponiveis = UtilitarioGeral.clonarObjeto(storeSistema.state.menus);
      state.menusDisponiveis.sort((a: IMenu, b: IMenu) => a.codigo - b.codigo);
      avancarPaginaMenus(true);
      computedExibir.value = false;
    });

    return {
      props,
      state,
      storeSistema,
      computedExibir,
      abrirMenuRecursos,
      avancarPaginaMenus,
      regredirPaginaMenus,
    };
  },
});
