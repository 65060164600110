
import {
  computed, defineComponent, ref,
} from 'vue';
import { useSelecionarBase } from '@/core/composables/SelecionarBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoTipoDocumentoFinanceiro from '@/servicos/Cadastros/Financeiro/ServicoTipoDocumentoFinanceiro';

export default defineComponent({
  name: 'SelecionarTipoDocumentoFinanceiro',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    descricaoSelecionado: {
      type: String,
      default: '',
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    omitirTiposDocumentos: {
      type: Array as () => number[],
    },
    tiposDocumentosEspecificos: {
      type: Array as () => number[],
      default: [] as number[],
    },
    meioPagamento: {
      type: Number,
      default: 0,
    },
    contasReceber: {
      type: Boolean,
      default: false,
    },
    contasPagar: {
      type: Boolean,
      default: false,
    },
    movimentacaoEntradaRecebimentos: {
      type: Boolean,
      default: false,
    },
    movimentacaoSaidaPagamentos: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:descricaoSelecionado', 'change'],
  setup(props, { emit }) {
    const {
      selecionarBase, montaOpcoesComListaConsultaRapida, verificacaoPreBusca, verificacaoUltimaPesquisa, aguardarConclusaoCarregamento,
      comportamentoPadraoSemResultado, instanciaParametrosConsultaRapidaPorCodigo, instanciaParametrosConsultaRapidaPesquisa,
    } = useSelecionarBase(props, emit);

    const refSelecionarTipoDocumentoFinanceiro = ref<HTMLElement | null>(null);

    let debounce = 0;
    const servicoTipoDocumentoFinanceiro = new ServicoTipoDocumentoFinanceiro();
    servicoTipoDocumentoFinanceiro.requisicaoSistema();

    function preencheListaOpcoes():void {
      if (UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida)) {
        if (props.omitirTiposDocumentos !== undefined) {
          props.omitirTiposDocumentos.forEach((codigoTipoDocumento) => {
            selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((c) => c.codigo !== codigoTipoDocumento);
          });
        }
        selecionarBase.listaOpcoes = montaOpcoesComListaConsultaRapida(selecionarBase.listaItensConsultaRapida);
      }
    }

    async function obterTipoDocumentoFinanceiroPorCodigo(valor: any) {
      if (!verificacaoPreBusca(valor)) return;

      selecionarBase.listaOpcoes = [];

      const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPorCodigo(valor, true);
      selecionarBase.listaItensConsultaRapida = await servicoTipoDocumentoFinanceiro.consultaRapida(parametrosConsultaRapida, props.meioPagamento, props.contasReceber, props.movimentacaoEntradaRecebimentos, props.contasPagar, props.movimentacaoSaidaPagamentos, props.tiposDocumentosEspecificos);
      preencheListaOpcoes();
      comportamentoPadraoSemResultado();
    }

    async function pesquisarTipoDocumentoFinanceiro(valorPesquisa: any) {
      selecionarBase.emDigitacao = true;

      if (!verificacaoUltimaPesquisa(valorPesquisa)) return;
      selecionarBase.buscandoDados = true;
      selecionarBase.listaOpcoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPesquisa(valorPesquisa, true);
        selecionarBase.listaItensConsultaRapida = await servicoTipoDocumentoFinanceiro.consultaRapida(parametrosConsultaRapida, props.meioPagamento, props.contasReceber, props.movimentacaoEntradaRecebimentos, props.contasPagar, props.movimentacaoSaidaPagamentos, props.tiposDocumentosEspecificos);
        preencheListaOpcoes();
        selecionarBase.valorUltimaPesquisa = valorPesquisa;
        selecionarBase.buscandoDados = false;
      }, 600);
    }

    function obterTipoDocumentoFinanceiro():any {
      obterTipoDocumentoFinanceiroPorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedDescricaoSelecionado = computed({
      get: () => props.descricaoSelecionado,
      set: (val: string) => {
        emit('update:descricaoSelecionado', val);
      },
    });

    const computedCodigoSelecionado = computed({
      get: () => obterTipoDocumentoFinanceiro(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        const itemConsulta = selecionarBase.listaItensConsultaRapida.find((c) => c.codigo === val);
        if (itemConsulta?.textoIdentificacao !== undefined && itemConsulta?.textoIdentificacao !== '') {
          computedDescricaoSelecionado.value = itemConsulta?.textoIdentificacao;
        }
      },
    });

    function obterTiposDocumentos():number[] {
      obterTipoDocumentoFinanceiroPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterTiposDocumentos(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    function focus() {
      if (refSelecionarTipoDocumentoFinanceiro.value) {
        refSelecionarTipoDocumentoFinanceiro.value.focus();
      }
    }

    return {
      props,
      selecionarBase,
      refSelecionarTipoDocumentoFinanceiro,
      pesquisarTipoDocumentoFinanceiro,
      aguardarConclusaoCarregamento,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      computedDescricaoSelecionado,
      change,
      focus,
      UtilitarioGeral,
    };
  },
});
