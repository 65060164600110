import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"padding-top":"10px"},
  class: "ant-btn-group ss-sombra-suave"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_fluxo = _resolveComponent("fluxo")!
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_modal_full_screen_fluxo = _resolveComponent("modal-full-screen-fluxo")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_tooltip, {
        placement: "top",
        title: "Expandir"
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            class: "ant-btn ant-btn-secondary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.expandir && _ctx.expandir(...args)))
          }, [
            _createVNode(_component_icone, {
              style: {"margin-top":"4px"},
              nome: "expandir"
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_a_tooltip, {
        placement: "top",
        title: "Aumentar o Zoom"
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            class: "ant-btn ant-btn-secondary",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.zoomin && _ctx.zoomin(...args)))
          }, [
            _createVNode(_component_icone, {
              style: {"margin-top":"4px"},
              nome: "zoomin"
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_a_tooltip, {
        placement: "top",
        title: "Diminuir o Zoom"
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            class: "ant-btn ant-btn-secondary",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.zoomout && _ctx.zoomout(...args)))
          }, [
            _createVNode(_component_icone, {
              style: {"margin-top":"4px"},
              nome: "zoomout"
            })
          ])
        ]),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "menusadicionais"),
      _createVNode(_component_a_tooltip, {
        placement: "top",
        title: "Adicionar"
      }, {
        default: _withCtx(() => [
          (_ctx.props.editavel)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "ant-btn ant-btn-primary",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.adicionar('')))
              }, [
                _createVNode(_component_icone, {
                  style: {"margin-top":"4px"},
                  nome: "mais"
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      style: _normalizeStyle('overflow-y: auto; overflow-x: auto;' + (_ctx.props.altura > 0 ? ' max-height: ' + _ctx.props.altura + 'px;' : '') +  (_ctx.props.largura > 0 ? ' max-width: ' + _ctx.props.largura + 'px; ' : ''))
    }, [
      (_ctx.props.cards.length > 0)
        ? (_openBlock(), _createBlock(_component_fluxo, {
            key: 0,
            style: _normalizeStyle('zoom: ' + _ctx.state.zoom + '%'),
            cards: _ctx.props.cards,
            editavel: _ctx.props.editavel,
            onSelecaoAlterada: _ctx.selecaoCardAlterada,
            onAdicionar: _ctx.adicionar,
            onEditar: _ctx.editar,
            onExcluir: _ctx.excluir
          }, null, 8, ["style", "cards", "editavel", "onSelecaoAlterada", "onAdicionar", "onEditar", "onExcluir"]))
        : (_openBlock(), _createBlock(_component_mensagem_sem_dados, {
            key: 1,
            mensagem: "Fluxo vazio."
          })),
      _createVNode(_component_modal_full_screen_fluxo, {
        visivel: _ctx.state.exibirExpandido,
        "onUpdate:visivel": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.exibirExpandido) = $event)),
        titulo: _ctx.props.titulo,
        cards: _ctx.props.cards,
        editavel: _ctx.props.editavel,
        onSelecaoAlterada: _ctx.selecaoCardAlterada,
        onAdicionar: _ctx.adicionar,
        onEditar: _ctx.editar,
        onExcluir: _ctx.excluir
      }, {
        menusadicionais: _withCtx(() => [
          _renderSlot(_ctx.$slots, "menusadicionais")
        ]),
        _: 3
      }, 8, ["visivel", "titulo", "cards", "editavel", "onSelecaoAlterada", "onAdicionar", "onEditar", "onExcluir"])
    ], 4)
  ]))
}