
import { defineComponent, reactive } from 'vue';
import Icone from '@/core/components/Icone.vue';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'MenuMobileGrade',
  components: {
    Icone,
  },
  emits: ['abrirMenu'],
  setup(props, { emit }) {
    const state = reactive({
    });

    function abrirMenuRecursos(menu: number) {
      emit('abrirMenu', menu);
    }

    return {
      state,
      storeSistema,
      abrirMenuRecursos,
    };
  },
});
