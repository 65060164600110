import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d4b3a36"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "ant-row",
  style: {"height":"100%"}
}
const _hoisted_2 = { class: "ant-col ant-col-xl-20 ant-col-xs-16" }
const _hoisted_3 = { class: "ss-titulo-etapa" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.modalBase.computedVisivel,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
    width: 500,
    destroyOnClose: "",
    "wrap-class-name": "ss-modal-vendas"
  }, {
    title: _withCtx(() => [
      _createTextVNode("Ordernar Etapas")
    ]),
    closeIcon: _withCtx(() => [
      _createVNode(_component_icone, { nome: "voltar" })
    ]),
    footer: _withCtx(() => []),
    default: _withCtx(() => [
      (_ctx.props.etapas.length === 0)
        ? (_openBlock(), _createBlock(_component_mensagem_sem_dados, {
            key: 0,
            mensagem: "Nenhuma etapa adicionada ainda."
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_draggable, {
        modelValue: _ctx.computedEtapas,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedEtapas) = $event)),
        "item-key": "nome",
        handle: ".ss-mover"
      }, {
        item: _withCtx(({ element }) => [
          (_openBlock(), _createElementBlock("div", {
            key: element.nome,
            class: "ss-div-etapa ss-mover ss-fadein ss-sombra-suave",
            style: _normalizeStyle('border-color:' + element.cor)
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(element.nome), 1)
              ])
            ])
          ], 4))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["visible"]))
}