
import {
  defineComponent, onMounted, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import BuscaAvancada from '@/core/components/BuscaAvancada/BuscaAvancada.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import { IDTOProdutoDefinicao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IParametrosConsultaProdutoDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Produtos/IParametrosConsultaProdutoDefinicao';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';

export default defineComponent({
  name: 'BuscaComSelecaoProdutoDefinicao',
  props: {
    titulo: {
      type: String,
      default: 'Selecionar produtos',
    },
    parametrosConsultaDefinicao: {
      type: Object as () => IParametrosConsultaProdutoDefinicao,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Icone,
    MensagemSemDados,
    BuscaAvancada,
  },
  emits: ['aplicarFiltros', 'confirmadoSelecaoProdutos'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemAlerta,
    } = useTelaBase();

    const {
      gradeBase, preenchePropriedadesNosFiltrosEOrdenacao, defineValoresIniciaisGrade,
      preencheOrdenacaoSelecionada, ordenacaoAtiva, ordenarDados, mudarPagina, mudarQuantidadeRegistrosPorPagina,
      preencheCodigosSelecionados, alterarQuantidadeRegistroTotal,
    } = useGradeBase();

    const servicoProduto = new ServicoProduto();

    const state = reactive({
      apresentarModal: false,
      produtos: [] as IDTOProdutoDefinicao[],
    });

    function apresentarSelecaoProdutos() {
      state.produtos = [];
      gradeBase.buscaAvancada.filtrosAdicionados = [];
      gradeBase.buscaAvancada.chaveFiltrosAdicionados = 0;
      gradeBase.ordenacaoSelecionada = [];
      gradeBase.codigosSelecionados = [];
      state.apresentarModal = true;
    }

    gradeBase.colunas = [];
    gradeBase.colunas = [
      {
        title: 'Código Definição', dataIndex: 'codigoDefinicao', key: 'CodigoDefinicao', position: 0, visible: false, ordering: true,
      },
      {
        title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInternoProduto', width: 80, ellipsis: true, position: 1, visible: true, ordering: true, align: 'right',
      },
      {
        title: 'Produto', dataIndex: 'produto', key: 'DescricaoProduto', width: 300, ellipsis: true, position: 2, visible: true, ordering: true, align: 'left',
      },
      {
        title: 'Unidade', dataIndex: 'unidade', key: 'UnidadeProduto', width: 70, ellipsis: true, position: 3, visible: true, ordering: true, align: 'center',
      },
      {
        title: 'Marca', dataIndex: 'marca', key: 'MarcaProduto', width: 100, ellipsis: true, position: 4, visible: true, ordering: true, align: 'left',
      },
    ];

    onMounted(async () => {
      gradeBase.propriedadesConsulta = await servicoProduto.obterPropriedadesConsultaDefinicoes();
      await preenchePropriedadesNosFiltrosEOrdenacao();
      await defineValoresIniciaisGrade();
    });

    async function buscarDados() {
      state.produtos = [];
      if (props.empresas.length === 0) {
        apresentarMensagemAlerta('Nenhuma empresa foi selecionada!');
        return;
      }

      gradeBase.filtrosAplicados = [];
      gradeBase.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });

      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.empresas = props.empresas;
      parametrosConsulta.numeroPagina = gradeBase.paginacao.current;
      parametrosConsulta.qtdeRegistrosPagina = gradeBase.paginacao.pageSize;
      parametrosConsulta.qtdeRegistrosTotal = gradeBase.paginacao.total;
      parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });

      gradeBase.buscandoDados = true;

      const listaPaginada = await servicoProduto.buscaAvancadaDefinicoes(parametrosConsulta, gradeBase.filtrosAplicados, props.parametrosConsultaDefinicao);

      if (listaPaginada !== undefined && listaPaginada !== null) {
        if (UtilitarioGeral.validaLista(listaPaginada.dados)) {
          state.produtos = listaPaginada.dados;
          gradeBase.paginacaoApi = listaPaginada.metaData;
        }
      }

      gradeBase.buscandoDados = false;
    }

    watch(async () => gradeBase.buscarDados, async () => {
      await buscarDados();
    });

    async function aplicarFiltros() {
      gradeBase.paginacao.current = 1;
      if (UtilitarioGeral.validaLista(gradeBase.buscaAvancada.filtrosAdicionados)) {
        await buscarDados();
      } else {
        state.produtos = [];
      }
    }

    function confirmarSelecaoProdutos() {
      if (UtilitarioGeral.validaLista(gradeBase.codigosSelecionados)) {
        emit('confirmadoSelecaoProdutos', gradeBase.codigosSelecionados);
        state.apresentarModal = false;
      } else {
        apresentarMensagemAlerta('Nenhum produto foi selecionado!');
      }
    }
    return {
      props,
      state,
      telaBase,
      gradeBase,
      UtilitarioMascara,
      buscarDados,
      apresentarSelecaoProdutos,
      mudarPagina,
      mudarQuantidadeRegistrosPorPagina,
      aplicarFiltros,
      preencheCodigosSelecionados,
      ordenacaoAtiva,
      preencheOrdenacaoSelecionada,
      alterarQuantidadeRegistroTotal,
      ordenarDados,
      confirmarSelecaoProdutos,
    };
  },
});

