
import {
  defineComponent, reactive, onMounted, ref, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import AlteracaoPrecoProdutoModal from './AlteracaoPrecoProdutoModal.vue';
import BuscaComSelecaoProdutoDefinicao from '@/components/Cadastros/Produtos/BuscaComSelecaoProdutoDefinicao.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ITipoPreco } from '@/models/Entidades/Cadastros/Precificacao/ITipoPreco';
import BuscaAvancada from '@/core/components/BuscaAvancada/BuscaAvancada.vue';
import { IColumn, ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IDTOProdutoDefinicao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicao';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import { IParametrosConsultaProdutoDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Produtos/IParametrosConsultaProdutoDefinicao';
import { ITabelaPrecoEstrutura, ITabelaPrecoItemProduto } from '@/models/Entidades/Cadastros/Precificacao/ITabelaPreco';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import ServicoTabelaPreco from '@/servicos/Cadastros/Precificacao/ServicoTabelaPreco';
import { useGradeBase } from '@/core/composables/GradeBase';
import { useModalBase } from '@/core/composables/ModalBase';

export default defineComponent({
  name: 'TabelaPrecoProdutos',
  props: {
    codigoTabelaPreco: {
      type: Number,
      required: true,
    },
    tiposPrecos: {
      type: Array as () => ITipoPreco[],
      required: true,
    },
    estruturas: {
      type: Array as () => ITabelaPrecoEstrutura[],
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Icone,
    MensagemSemDados,
    BuscaAvancada,
    AlteracaoPrecoProdutoModal,
    BuscaComSelecaoProdutoDefinicao,
    RequisicaoModal,
  },
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso,
    } = useTelaBase();

    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const {
      gradeBase, preenchePropriedadesNosFiltrosEOrdenacao, defineValoresIniciaisGrade,
      preencheOrdenacaoSelecionada, ordenacaoAtiva, ordenarDados, mudarPagina, mudarQuantidadeRegistrosPorPagina,
      preencheCodigosSelecionados, alterarQuantidadeRegistroTotal,
    } = useGradeBase();

    const chaveTipoPrecoValor = 'TIPO-PRECO-VALOR-';
    const chaveTipoPrecoMarkup = 'TIPO-PRECO-MARKUP-';
    const chaveTipoPrecoDesconto = 'TIPO-PRECO-DESC-MAX-';
    const servicoTabelaPreco = new ServicoTabelaPreco();
    const servicoProduto = new ServicoProduto();

    const refAlterarPreco = ref<InstanceType<typeof AlteracaoPrecoProdutoModal>>();
    const refBuscaProdutoDefinicao = ref<InstanceType<typeof BuscaComSelecaoProdutoDefinicao>>();

    const state = reactive({
      produtos: [] as IDTOProdutoDefinicao[],
      itensTabelaPreco: [] as ITabelaPrecoItemProduto[],
    });

    state.produtos = [];
    state.itensTabelaPreco = [];
    gradeBase.buscaAvancada.filtrosAdicionados = [];
    gradeBase.buscaAvancada.chaveFiltrosAdicionados = 0;
    gradeBase.ordenacaoSelecionada = [];

    function obterDescricaoTipoPreco(codigoTipoPreco:number) {
      const tipoPreco = props.tiposPrecos.find((c) => c.codigo === codigoTipoPreco);
      if (tipoPreco !== undefined) {
        return tipoPreco.descricao;
      }

      return '';
    }
    function preparaGradePrecos() {
      gradeBase.colunas = [];
      gradeBase.colunas = [
        {
          title: 'Ações', key: 'acoes', position: 0, visible: true, fixed: 'left', width: 50, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
        },
        {
          title: 'Código Definição', dataIndex: 'codigoDefinicao', key: 'CodigoDefinicao', position: 1, visible: false, ordering: true,
        },
        {
          title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInternoProduto', width: 100, ellipsis: true, position: 2, visible: true, ordering: true, fixed: 'left', align: 'right',
        },
        {
          title: 'Produto', dataIndex: 'produto', key: 'DescricaoProduto', width: 300, ellipsis: true, position: 3, visible: true, ordering: true, fixed: 'left', align: 'left',
        },
        {
          title: 'Unidade', dataIndex: 'unidade', key: 'UnidadeProduto', width: 70, ellipsis: true, position: 4, visible: true, ordering: true, fixed: 'left', align: 'center',
        },
        {
          title: 'Marca', dataIndex: 'marca', key: 'MarcaProduto', width: 100, ellipsis: true, position: 5, visible: true, ordering: true, align: 'left',
        },
      ];

      let position = 6;

      gradeBase.windowWidth = window.innerWidth;
      props.estruturas.forEach((estrutura) => {
        const colunaValorPreco:IColumn = {} as IColumn;
        colunaValorPreco.title = obterDescricaoTipoPreco(estrutura.codigoTipoPreco);
        colunaValorPreco.key = `${chaveTipoPrecoValor}${estrutura.codigoTipoPreco}`;
        colunaValorPreco.visible = true;
        colunaValorPreco.position = position;
        colunaValorPreco.width = 100;
        colunaValorPreco.align = 'right';
        position += 1;
        gradeBase.colunas.push(colunaValorPreco);

        const colunaMarkupPreco:IColumn = {} as IColumn;
        colunaMarkupPreco.title = '% Markup';
        colunaMarkupPreco.key = `${chaveTipoPrecoMarkup}${estrutura.codigoTipoPreco}`;
        colunaMarkupPreco.visible = true;
        colunaMarkupPreco.position = position;
        colunaMarkupPreco.width = 60;
        colunaMarkupPreco.align = 'center';
        position += 1;
        gradeBase.colunas.push(colunaMarkupPreco);

        const colunaDescontoPreco:IColumn = {} as IColumn;
        colunaDescontoPreco.title = '% Desconto Max';
        colunaDescontoPreco.key = `${chaveTipoPrecoDesconto}${estrutura.codigoTipoPreco}`;
        colunaDescontoPreco.visible = true;
        colunaDescontoPreco.position = position;
        colunaDescontoPreco.width = 90;
        colunaDescontoPreco.align = 'center';
        position += 1;
        gradeBase.colunas.push(colunaDescontoPreco);
        gradeBase.windowWidth += 250;
      });
    }

    onMounted(async () => {
      gradeBase.propriedadesConsulta = await servicoProduto.obterPropriedadesConsultaDefinicoes();
      await preenchePropriedadesNosFiltrosEOrdenacao();
      await defineValoresIniciaisGrade();
      preparaGradePrecos();
    });

    async function buscarDados() {
      state.produtos = [];
      if (props.empresas.length === 0) {
        apresentarMensagemAlerta('Nenhuma empresa foi selecionada!');
        return;
      }

      gradeBase.filtrosAplicados = [];
      gradeBase.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });

      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.empresas = props.empresas;
      parametrosConsulta.numeroPagina = gradeBase.paginacao.current;
      parametrosConsulta.qtdeRegistrosPagina = gradeBase.paginacao.pageSize;
      parametrosConsulta.qtdeRegistrosTotal = gradeBase.paginacao.total;
      parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });

      gradeBase.buscandoDados = true;
      const parametrosConsultaProdutoDefinicao: IParametrosConsultaProdutoDefinicao = {} as IParametrosConsultaProdutoDefinicao;
      parametrosConsultaProdutoDefinicao.apenasAtivos = false;
      parametrosConsultaProdutoDefinicao.presentesTabelaPreco = props.codigoTabelaPreco;

      const listaPaginada = await servicoProduto.buscaAvancadaDefinicoes(parametrosConsulta, gradeBase.filtrosAplicados, parametrosConsultaProdutoDefinicao);

      if (listaPaginada !== undefined && listaPaginada !== null) {
        if (UtilitarioGeral.validaLista(listaPaginada.dados)) {
          const codigoDefinicoes = listaPaginada.dados.map((produto: IDTOProdutoDefinicao) => produto.codigoDefinicao);

          state.itensTabelaPreco = await servicoTabelaPreco.obterProdutos(props.codigoTabelaPreco, props.empresas[0], codigoDefinicoes);
          if (UtilitarioGeral.validaLista(state.itensTabelaPreco)) {
            state.produtos = listaPaginada.dados;
            gradeBase.paginacaoApi = listaPaginada.metaData;
          }
        }
      }

      gradeBase.buscandoDados = false;
    }

    async function removerProdutoTabela(codigoProdutoDefinicao: number) {
      const retorno = await servicoTabelaPreco.removerProdutoTabela(props.codigoTabelaPreco, codigoProdutoDefinicao);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        state.produtos = state.produtos.filter((produto: IDTOProdutoDefinicao) => produto.codigoDefinicao !== codigoProdutoDefinicao);
        state.itensTabelaPreco = state.itensTabelaPreco.filter((item: ITabelaPrecoItemProduto) => item.codigoProdutoDefinicao !== codigoProdutoDefinicao);
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }

    async function removerProdutosTabela(todosProdutos: boolean) {
      let retorno:IRetornoRequisicao = {} as IRetornoRequisicao;
      if (todosProdutos) {
        retorno = await servicoTabelaPreco.removerProdutosTabela(props.codigoTabelaPreco, todosProdutos, []);
      } else if (UtilitarioGeral.validaLista(gradeBase.codigosSelecionados)) {
        retorno = await servicoTabelaPreco.removerProdutosTabela(props.codigoTabelaPreco, false, gradeBase.codigosSelecionados);
      } else {
        apresentarMensagemAlerta('Nenhum produto foi selecionado!');
        return;
      }

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        if (todosProdutos) {
          state.produtos = [];
        } else {
          let produtos = UtilitarioGeral.instanciaObjetoLocal(state.produtos);
          gradeBase.codigosSelecionados.forEach((codigoProdutoDefinicao) => {
            produtos = produtos.filter((produto: IDTOProdutoDefinicao) => produto.codigoDefinicao !== codigoProdutoDefinicao);
            state.produtos = produtos;
          });
        }
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }

    function confirmaExclusaoProduto(produtoDefinicao: IDTOProdutoDefinicao) {
      Modal.confirm({
        title: 'Deseja remover o produto:',
        content: `${produtoDefinicao.produto} da tabela de preço?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { removerProdutoTabela(produtoDefinicao.codigoDefinicao); },
      });
    }

    function confirmaExclusaoProdutos(todosProdutos:boolean) {
      Modal.confirm({
        title: 'Atenção',
        content: todosProdutos ? 'Você deseja remover todos os produtos da tabela de preço?' : 'Você deseja remover os produtos selecionados da tabela de preço?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { removerProdutosTabela(todosProdutos); },
      });
    }

    watch(async () => gradeBase.buscarDados, async () => {
      await buscarDados();
    });

    async function aplicarFiltros() {
      gradeBase.paginacao.current = 1;
      if (UtilitarioGeral.validaLista(gradeBase.buscaAvancada.filtrosAdicionados)) {
        await buscarDados();
      } else {
        state.produtos = [];
      }
    }

    function obtemPrecoItemTabela(codigoDefinicao:number, valorChaveTipoPreco: string):string {
      const codigoTipoPreco = Number(valorChaveTipoPreco.replace(chaveTipoPrecoValor, ''));
      const itemPrecoTabela = state.itensTabelaPreco.find((c) => c.codigoProdutoDefinicao === codigoDefinicao && c.codigoTipoPreco === codigoTipoPreco);
      const tipoPreco = props.tiposPrecos.find((c) => c.codigo === codigoTipoPreco);
      if (itemPrecoTabela !== undefined && tipoPreco !== undefined) {
        const precoFormatado = UtilitarioMascara.mascararValor(itemPrecoTabela.valor, tipoPreco.casasDecimais, true);
        return precoFormatado;
      }

      return 'R$ 0,00';
    }
    function obtemMarkupItemTabela(codigoDefinicao:number, valorChaveTipoPrecoMarkup: string):string {
      const codigoTipoPreco = Number(valorChaveTipoPrecoMarkup.replace(chaveTipoPrecoMarkup, ''));
      const itemPrecoTabela = state.itensTabelaPreco.find((c) => c.codigoProdutoDefinicao === codigoDefinicao && c.codigoTipoPreco === codigoTipoPreco);
      const tipoPreco = props.tiposPrecos.find((c) => c.codigo === codigoTipoPreco);
      if (itemPrecoTabela !== undefined && tipoPreco !== undefined) {
        const markupFormatado = UtilitarioMascara.mascararValor(itemPrecoTabela.markup, 2);
        return markupFormatado;
      }

      return '0,00';
    }
    function obtemDescontoItemTabela(codigoDefinicao:number, valorChaveTipoPrecoDesconto: string):string {
      const codigoTipoPreco = Number(valorChaveTipoPrecoDesconto.replace(chaveTipoPrecoDesconto, ''));
      const itemPrecoTabela = state.itensTabelaPreco.find((c) => c.codigoProdutoDefinicao === codigoDefinicao && c.codigoTipoPreco === codigoTipoPreco);
      const tipoPreco = props.tiposPrecos.find((c) => c.codigo === codigoTipoPreco);
      if (itemPrecoTabela !== undefined && tipoPreco !== undefined) {
        const descontoFormatado = UtilitarioMascara.mascararValor(itemPrecoTabela.descontoMaximo, 2);
        return descontoFormatado;
      }

      return '0,00';
    }

    function iniciarAlteracaoPreco(produtoDefinicao: IDTOProdutoDefinicao) {
      if (refAlterarPreco.value !== undefined) {
        const itensTabelaPreco = state.itensTabelaPreco.filter((c) => c.codigoProdutoDefinicao === produtoDefinicao.codigoDefinicao);
        refAlterarPreco.value.apresentarAlteracaoPreco(produtoDefinicao, itensTabelaPreco);
      }
    }

    function precosAtualizados(precos: ITabelaPrecoItemProduto[]) {
      if (UtilitarioGeral.validaLista(precos)) {
        precos.forEach((produtoPreco) => {
          const index = state.itensTabelaPreco.findIndex((c) => c.codigo === produtoPreco.codigo);
          if (index >= 0) {
            state.itensTabelaPreco[index].codigoTipoCusto = produtoPreco.codigoTipoCusto;
            state.itensTabelaPreco[index].markup = produtoPreco.markup;
            state.itensTabelaPreco[index].descontoMaximo = produtoPreco.descontoMaximo;
            state.itensTabelaPreco[index].valor = produtoPreco.valor;
          }
        });
      }
    }

    function defineParametrosConsultaNovosProdutos(): IParametrosConsultaProdutoDefinicao {
      const parametrosConsultaProdutoDefinicao: IParametrosConsultaProdutoDefinicao = {} as IParametrosConsultaProdutoDefinicao;
      parametrosConsultaProdutoDefinicao.apenasAtivos = true;
      parametrosConsultaProdutoDefinicao.ausentesTabelaPreco = props.codigoTabelaPreco;
      return parametrosConsultaProdutoDefinicao;
    }

    function apresentarSelecaoNovosProdutos() {
      if (refBuscaProdutoDefinicao.value !== undefined) {
        refBuscaProdutoDefinicao.value.apresentarSelecaoProdutos();
      }
    }

    async function adicionarNovosProdutos(codigosDefinicoes: number[]) {
      if (UtilitarioGeral.validaLista(codigosDefinicoes)) {
        let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
        apresentarBarraProgresso('Por favor aguarde, estamos adicionando os produtos na tabela de preços.');

        const produtosTabelaPrecos:ITabelaPrecoItemProduto[] = [];

        codigosDefinicoes.forEach((codigoDefinicao) => {
          props.estruturas.forEach((estrutura) => {
            const itemProduto:ITabelaPrecoItemProduto = {} as ITabelaPrecoItemProduto;
            itemProduto.codigo = 0;
            itemProduto.codigoProdutoDefinicao = codigoDefinicao;
            itemProduto.codigoTabelaPreco = props.codigoTabelaPreco;
            itemProduto.codigoTipoCusto = estrutura.codigoTipoCusto;
            itemProduto.codigoTipoPreco = estrutura.codigoTipoPreco;
            itemProduto.markup = estrutura.markupPadrao;
            itemProduto.descontoMaximo = estrutura.descontoMaximoPadrao;
            itemProduto.valor = 0;
            produtosTabelaPrecos.push(itemProduto);
          });
        });

        retorno = await servicoTabelaPreco.adicionarPrecosProdutos(props.codigoTabelaPreco, produtosTabelaPrecos);
        ocultarBarraProgresso();

        if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
          apresentarMensagemSucesso(retorno.mensagem);
        } else {
          apresentarRetornoRequisicao(retorno);
        }
      }
    }

    async function adicionarTodosProdutosAusentesNaTabela() {
      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.empresas = props.empresas;
      parametrosConsulta.numeroPagina = 1;
      parametrosConsulta.qtdeRegistrosPagina = 1000000;
      parametrosConsulta.qtdeRegistrosTotal = 1000000;
      parametrosConsulta.ordenacao = Array<string>();

      const parametrosConsultaProdutoDefinicao: IParametrosConsultaProdutoDefinicao = {} as IParametrosConsultaProdutoDefinicao;
      parametrosConsultaProdutoDefinicao.apenasAtivos = true;
      parametrosConsultaProdutoDefinicao.ausentesTabelaPreco = props.codigoTabelaPreco;

      const listaPaginada = await servicoProduto.buscaAvancadaDefinicoes(parametrosConsulta, [], parametrosConsultaProdutoDefinicao);
      if (UtilitarioGeral.validaLista(listaPaginada.dados)) {
        const codigoDefinicoes = listaPaginada.dados.map((produto: IDTOProdutoDefinicao) => produto.codigoDefinicao);
        await adicionarNovosProdutos(codigoDefinicoes);
      } else {
        apresentarMensagemAlerta('Todos os produtos já se encontram na tabela de preço!');
      }
    }

    return {
      props,
      state,
      telaBase,
      modalBase,
      gradeBase,
      ECustomRenderRow,
      UtilitarioMascara,
      mudarQuantidadeRegistrosPorPagina,
      mudarPagina,
      confirmaExclusaoProduto,
      confirmaExclusaoProdutos,
      preparaGradePrecos,
      aplicarFiltros,
      ordenacaoAtiva,
      preencheCodigosSelecionados,
      chaveTipoPrecoValor,
      chaveTipoPrecoMarkup,
      chaveTipoPrecoDesconto,
      obtemPrecoItemTabela,
      obtemMarkupItemTabela,
      obtemDescontoItemTabela,
      preencheOrdenacaoSelecionada,
      alterarQuantidadeRegistroTotal,
      ordenarDados,
      refAlterarPreco,
      refBuscaProdutoDefinicao,
      iniciarAlteracaoPreco,
      precosAtualizados,
      defineParametrosConsultaNovosProdutos,
      apresentarSelecaoNovosProdutos,
      adicionarTodosProdutosAusentesNaTabela,
      adicionarNovosProdutos,
    };
  },
});
