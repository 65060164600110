
import { computed, defineComponent, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IFormaPagamentoTipoDocumentoFinanceiroParcelamento, IFormaPagamentoTipoDocumentoFinanceiro, IFormaPagamentoTipoDocumentoFinanceiroParcela } from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarCategoriaPlanoConta from '../../PlanosContas/SelecionarCategoriaPlanoConta.vue';
import SelecionarConta from '../SelecionarConta.vue';
import SelecionarStatusTituloFinanceiro from '@/components/Financeiro/TitulosFinanceiros/SelecionarStatusTituloFinanceiro.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import FormaPagamentoCopiarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/FormasPagamento/FormaPagamentoCopiarTipoDocumentoFinanceiro.vue';
import SelecionarTipoDocumentoFinanceiroModal from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiroModal.vue';
import ServicoTipoDocumentoFinanceiro from '@/servicos/Cadastros/Financeiro/ServicoTipoDocumentoFinanceiro';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import Icone from '@/core/components/Icone.vue';
import FormaPagamentoParcelasModal from '@/views/Cadastros/Financeiro/FormasPagamento/FormaPagamentoParcelasModal.vue';
import { ETipoOperacao } from '@/models/Enumeradores/Fiscal/ETipoOperacao';

export default defineComponent({
  name: 'FormaPagamentoTiposPagamento',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    tipoOperacao: {
      type: Number,
      required: true,
    },
    tiposDocumentosFinanceiro: {
      type: Array as () => IFormaPagamentoTipoDocumentoFinanceiro[],
      required: true,
    },
    codigosTiposUtilizados: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Card,
    CampoNumerico,
    SelecionarCategoriaPlanoConta,
    SelecionarConta,
    SelecionarStatusTituloFinanceiro,
    SelecionarTipoDocumentoFinanceiroModal,
    MensagemSemDados,
    FormaPagamentoCopiarTipoDocumentoFinanceiro,
    Icone,
    FormaPagamentoParcelasModal,
  },
  emits: ['update:tiposDocumentosFinanceiro', 'update:codigosTiposUtilizados'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const servicoTipoDocumentoFinanceiro = new ServicoTipoDocumentoFinanceiro();

    const state = reactive({
      indexTipoDocumento: -1,
      indexParcelamento: -1,
      parcelamentoSelecionado: {} as IFormaPagamentoTipoDocumentoFinanceiroParcelamento,
      tipoDocumentoSelecionado: 0,
      carregando: false,
      exibirSelecaoTipoDocumento: false,
      exibirCopiarTipoDocumento: false,
      exibirParcelas: false,
    });

    const computedTiposPagamento = computed({
      get: () => props.tiposDocumentosFinanceiro,
      set: (valor: IFormaPagamentoTipoDocumentoFinanceiro[]) => {
        emit('update:tiposDocumentosFinanceiro', valor);
      },
    });

    const computedCodigosTiposUtilizados = computed({
      get: () => props.codigosTiposUtilizados,
      set: (valor: number[]) => {
        emit('update:codigosTiposUtilizados', valor);
      },
    });

    function alterarCarteira(index: number) {
      if (computedTiposPagamento.value[index].gerarCarteira) {
        computedTiposPagamento.value[index].gerarCaixaDiario = false;
      }
    }

    function alterarCaixa(index: number) {
      if (computedTiposPagamento.value[index].gerarCaixaDiario) {
        computedTiposPagamento.value[index].gerarCarteira = false;
      }
    }

    async function adicionarTipoDocumento(codigoTipoDocumento: number, codigoTipoDocumentoOrigem: number) {
      const indexTipo = computedCodigosTiposUtilizados.value.findIndex((t) => t === codigoTipoDocumento);
      if (indexTipo > -1) {
        apresentarMensagemAlerta('Esse tipo de documento já foi definido!');
        return;
      }

      state.carregando = true;
      let tipoDocumentoFinanceiro = {} as IFormaPagamentoTipoDocumentoFinanceiro;
      if (codigoTipoDocumentoOrigem > 0 && codigoTipoDocumentoOrigem !== undefined) {
        const indexOrigem = computedTiposPagamento.value.findIndex((t) => t.codigoTipoDocumentoFinanceiro === codigoTipoDocumentoOrigem);
        tipoDocumentoFinanceiro = UtilitarioGeral.clonarObjeto(computedTiposPagamento.value[indexOrigem]);
        const tipoDocumentoDb = await servicoTipoDocumentoFinanceiro.obter(codigoTipoDocumento, props.empresa);
        tipoDocumentoFinanceiro.codigoTipoDocumentoFinanceiro = codigoTipoDocumento;
        tipoDocumentoFinanceiro.descricaoTipoDocumentoFinanceiro = tipoDocumentoDb.descricao;
      } else {
        const tipoDocumentoDb = await servicoTipoDocumentoFinanceiro.obter(codigoTipoDocumento, props.empresa);
        tipoDocumentoFinanceiro = {} as IFormaPagamentoTipoDocumentoFinanceiro;
        tipoDocumentoFinanceiro.codigoTipoDocumentoFinanceiro = codigoTipoDocumento;
        tipoDocumentoFinanceiro.descricaoTipoDocumentoFinanceiro = tipoDocumentoDb.descricao;
        tipoDocumentoFinanceiro.tipoMovimentoCaixa = 1;
        tipoDocumentoFinanceiro.tipoTituloCarteira = 1;
        tipoDocumentoFinanceiro.statusCarteiras = 1;
        tipoDocumentoFinanceiro.calcularComissao = true;
        tipoDocumentoFinanceiro.gerarCaixaDiario = false;
        tipoDocumentoFinanceiro.gerarCarteira = false;
        tipoDocumentoFinanceiro.valorMinParcela = 0;
        tipoDocumentoFinanceiro.parcelamentos = [] as IFormaPagamentoTipoDocumentoFinanceiroParcelamento[];
      }

      computedTiposPagamento.value.push(tipoDocumentoFinanceiro);
      computedCodigosTiposUtilizados.value.push(tipoDocumentoFinanceiro.codigoTipoDocumentoFinanceiro);
      state.carregando = false;
    }

    function adicioanrVariosTiposDocumentos(codigos: number[]) {
      codigos.forEach((c) => {
        adicionarTipoDocumento(c, state.tipoDocumentoSelecionado);
      });
      state.tipoDocumentoSelecionado = 0;
    }

    function removerTipoDocumento(index: number) {
      Modal.confirm({
        title: 'Realmente deseja excluir o tipo de documento?',
        content: computedTiposPagamento.value[index].descricaoTipoDocumentoFinanceiro,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => {
          const indexCodigo = computedCodigosTiposUtilizados.value.findIndex((t) => t === computedTiposPagamento.value[index].codigoTipoDocumentoFinanceiro);
          computedTiposPagamento.value.splice(index, 1);
          computedCodigosTiposUtilizados.value.splice(indexCodigo, 1);
        },
      });
    }

    function abrirCopiarTipoDocumento(index:number) {
      state.tipoDocumentoSelecionado = computedCodigosTiposUtilizados.value[index];
      state.exibirCopiarTipoDocumento = true;
    }

    function abrirAdicionarTipoDocumento() {
      state.exibirSelecaoTipoDocumento = true;
    }

    function editarTiposDocumento(targetKey: string | MouseEvent, action: string) {
      if (action === 'add') {
        state.exibirSelecaoTipoDocumento = true;
      } else {
        removerTipoDocumento(Number(targetKey));
      }
    }

    function adicionarParcelamento(index: number) {
      const parcelamentoNovo = {} as IFormaPagamentoTipoDocumentoFinanceiroParcelamento;
      parcelamentoNovo.codigoFormaPagamentoTipoDocumentoFinanceiro = computedTiposPagamento.value[index].codigo;
      parcelamentoNovo.ordem = computedTiposPagamento.value[index].parcelamentos.length + 1;
      parcelamentoNovo.qtdParcelas = parcelamentoNovo.ordem;
      parcelamentoNovo.parcelas = [] as IFormaPagamentoTipoDocumentoFinanceiroParcela[];
      for (let i = 0; i < parcelamentoNovo.qtdParcelas; i += 1) {
        const parcela = {} as IFormaPagamentoTipoDocumentoFinanceiroParcela;
        parcela.prazo = 30 * (i + 1);
        parcela.ordem = i;
        parcelamentoNovo.parcelas.push(parcela);
        parcelamentoNovo.prazoTotal = parcela.prazo;
      }
      computedTiposPagamento.value[index].parcelamentos.push(parcelamentoNovo);
    }

    function removerParcelamento(indexTipo: number, indexParcela: number) {
      computedTiposPagamento.value[indexTipo].parcelamentos.splice(indexParcela, 1);
    }

    function abrirParcelamento(indexTipo: number, indexParcelamento: number) {
      state.indexTipoDocumento = indexTipo;
      state.indexParcelamento = indexParcelamento;
      state.parcelamentoSelecionado = computedTiposPagamento.value[state.indexTipoDocumento].parcelamentos[state.indexParcelamento];
      state.exibirParcelas = true;
    }

    function atualizarParcelamento(parcelamento: IFormaPagamentoTipoDocumentoFinanceiroParcelamento) {
      computedTiposPagamento.value[state.indexTipoDocumento].parcelamentos[state.indexParcelamento] = parcelamento;
    }

    return {
      props,
      state,
      telaBase,
      computedTiposPagamento,
      computedCodigosTiposUtilizados,
      alterarCarteira,
      alterarCaixa,
      adicionarTipoDocumento,
      adicioanrVariosTiposDocumentos,
      editarTiposDocumento,
      abrirCopiarTipoDocumento,
      abrirAdicionarTipoDocumento,
      adicionarParcelamento,
      removerParcelamento,
      abrirParcelamento,
      atualizarParcelamento,
      ETipoOperacao,
    };
  },
});
