
import { defineComponent, reactive, watch } from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IPlanoConta, IPlanoContaEmpresa, IPlanoContaNivel } from '@/models/Entidades/Cadastros/PlanosContas/IPlanoConta';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import ApresentarNivelPlanoContas from '@/components/Cadastros/PlanosContas/ApresentarNivelPlanoContas.vue';
import SelecionarTipoPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarTipoPlanoConta.vue';
import ServicoPlanoContas from '@/servicos/Cadastros/PlanosContas/ServicoPlanoContas';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IPlanoContaCategoria } from '@/models/Entidades/Cadastros/PlanosContas/IPlanoContaCategoria';
import PlanoContaCategoriaModal from './PlanoContaCategoriaModal.vue';

export default defineComponent({
  name: 'PlanoContaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    Icone,
    SelecionarEmpresaCadastroCompartilhado,
    ApresentarNivelPlanoContas,
    SelecionarTipoPlanoConta,
    MensagemSemDados,
    PlanoContaCategoriaModal,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas, defineEmpresasSelecionadasCadastroCompartilhado,
      preencherPermissoesDados, defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso, apresentarMensagemErro,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoPlanoContas = new ServicoPlanoContas();

    telaBase.identificadorRecurso = 'CADASTRO_PLANOS_CONTAS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_PLANOS_CONTAS';
    let debounce = 0;
    const state = reactive({
      planoContas: {} as IPlanoConta,
      mascaraFormatada: '',
      filtroCategorias: '',
      carregandoCategorias: false,
      exibirModalCategoria: false,
      operacaoCategoria: {} as ITelaOperacao,
    });

    function objetoInicial() {
      state.planoContas = {} as IPlanoConta;
      state.planoContas.tipo = 1;
      state.planoContas.ativo = true;
      state.planoContas.niveis = [];
      state.planoContas.categorias = [];
      state.planoContas.empresas = [];
      state.mascaraFormatada = '';
    }

    async function obterCategorias() {
      state.carregandoCategorias = true;
      state.planoContas.categorias = [] as IPlanoContaCategoria[];
      state.planoContas.categorias = await servicoPlanoContas.obterCategorias(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      state.carregandoCategorias = false;
    }

    function filtrarCategorias() {
      state.carregandoCategorias = true;
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        state.planoContas.categorias = await servicoPlanoContas.obterCategorias(props.operacao.codigoRegistro, props.operacao.empresaSelecionada, state.filtroCategorias);
        state.carregandoCategorias = false;
      }, 600);
    }

    function obterGrupoMascara(valor: number) {
      let grupo = '';
      for (let contador = 1; contador <= valor; contador += 1) {
        grupo += '1';
      }
      return grupo;
    }

    function formatarMascara() {
      state.mascaraFormatada = '';
      state.planoContas.niveis.forEach((n, index) => {
        state.mascaraFormatada += obterGrupoMascara(n.valor);
        if (index + 1 !== state.planoContas.niveis.length) {
          state.mascaraFormatada += '.';
        }
      });
    }

    function adicionarNivel() {
      const nivel = {} as IPlanoContaNivel;
      nivel.codigoPlanoConta = state.planoContas.codigo;
      nivel.valor = 1;
      state.planoContas.niveis.push(nivel);
      formatarMascara();
    }

    function removerNivel(nivel: number) {
      state.planoContas.niveis.splice(nivel, 1);
      formatarMascara();
    }

    function atualizarOrdem() {
      state.planoContas.niveis.forEach((n, index) => {
        state.planoContas.niveis[index].nivel = index + 1;
      });
    }

    async function editarCategoria(codigo: number) {
      state.operacaoCategoria = {} as ITelaOperacao;
      state.operacaoCategoria.codigoRegistro = codigo;
      state.operacaoCategoria.empresaSelecionada = telaBase.empresasSelecionadas[0];
      state.operacaoCategoria.tipoPermissaoDados = EPermissaoDados.Visualizar;
      state.operacaoCategoria.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.exibirModalCategoria = true;
    }

    async function duplicarCategoria(codigo: number) {
      state.operacaoCategoria = {} as ITelaOperacao;
      state.operacaoCategoria.codigoRegistro = 0;
      state.operacaoCategoria.empresaSelecionada = telaBase.empresasSelecionadas[0];
      state.operacaoCategoria.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoCategoria.codigoRegistroDuplicar = codigo;
      state.operacaoCategoria.codigoRegistroPai = 0;
      state.operacaoCategoria.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.exibirModalCategoria = true;
    }

    async function criarCategoriaFilha(codigo: number) {
      state.operacaoCategoria = {} as ITelaOperacao;
      state.operacaoCategoria.codigoRegistro = 0;
      state.operacaoCategoria.empresaSelecionada = telaBase.empresasSelecionadas[0];
      state.operacaoCategoria.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoCategoria.codigoRegistroDuplicar = 0;
      state.operacaoCategoria.codigoRegistroPai = codigo;
      state.operacaoCategoria.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.exibirModalCategoria = true;
    }

    async function criarCategoria() {
      state.operacaoCategoria = {} as ITelaOperacao;
      state.operacaoCategoria.empresaSelecionada = telaBase.empresasSelecionadas[0];
      state.operacaoCategoria.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoCategoria.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.exibirModalCategoria = true;
    }

    async function excluirCategoria(codigo: number, nome: string) {
      Modal.confirm({
        title: 'Deseja remover a categoria:',
        content: `${nome}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoPlanoContas.excluirCategoria(codigo);
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            apresentarMensagemSucesso(retorno.mensagem);
            const indice = state.planoContas.categorias.findIndex((c) => c.codigo === codigo);
            state.planoContas.categorias.splice(indice, 1);
            filtrarCategorias();
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    function preparaPersistenciaEmpresas() {
      const planoContasEmpresas : IPlanoContaEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.planoContas.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            planoContasEmpresas.push(empresaExistente);
          } else {
            const planoContaEmpresa: IPlanoContaEmpresa = { codigo: 0, codigoPlanoConta: state.planoContas.codigo, codigoEmpresa };
            planoContasEmpresas.push(planoContaEmpresa);
          }
        });
      }
      state.planoContas.empresas = planoContasEmpresas;
    }
    async function salvar(salvarNovo: boolean) {
      preparaPersistenciaEmpresas();
      atualizarOrdem();
      state.planoContas.mascara = state.mascaraFormatada.replaceAll('1', '#');
      state.planoContas.categorias = [];
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoPlanoContas.incluir(state.planoContas);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoPlanoContas.alterar(state.planoContas);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      telaBase.empresasSelecionadas = [];
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);
        await defineEmpresasSelecionadasComPermissao();

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          modalBase.textoBotaoSalvar = 'Salvar alterações';
          modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          state.planoContas = await servicoPlanoContas.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          state.planoContas.niveis = await servicoPlanoContas.obterNiveis(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          telaBase.empresasSelecionadas = [];
          if (state.planoContas.empresas.length > 0) {
            state.planoContas.empresas.forEach((planoContaEmpresa) => {
              telaBase.empresasSelecionadas.push(planoContaEmpresa.codigoEmpresa);
            });
          }
          obterCategorias();
          formatarMascara();
        } else {
          modalBase.textoBotaoSalvar = 'Concluir cadastro';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }

        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      apresentarRetornoRequisicao,
      objetoInicial,
      EPermissaoDados,
      adicionarNivel,
      removerNivel,
      formatarMascara,
      filtrarCategorias,
      excluirCategoria,
      editarCategoria,
      criarCategoria,
      duplicarCategoria,
      criarCategoriaFilha,
      obterCategorias,
    };
  },
});
