
import { defineComponent, reactive } from 'vue';
import { IVersaoSistema } from '@/models/Entidades/MeuSistema/IVersaoSistema';
import Card from '@/core/components/Tela/Card.vue';

export default defineComponent({
  name: 'SobreVersaoSistema',
  props: {
    versao: {
      type: Object as () => IVersaoSistema,
      required: true,
    },
  },
  components: {
    Card,
  },
  setup(props) {
    const state = reactive({});

    return {
      props,
      state,
    };
  },
});
