
import { computed, defineComponent, reactive } from 'vue';
import Icone from '../../components/Icone.vue';
import Notificacoes from './Notificacoes.vue';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'NotificacoesMobile',
  components: {
    Icone,
    Notificacoes,
  },
  props: {
    visualizar: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:visualizar', 'abrirNotificacoes'],
  setup(props, { emit }) {
    const state = reactive({
    });

    const computedVisualizar = computed({
      get: () => props.visualizar,
      set: (val: boolean) => {
        emit('update:visualizar', val);
      },
    });

    function abrirNotificacoes() {
      computedVisualizar.value = true;
      emit('abrirNotificacoes');
    }

    return {
      props,
      state,
      storeSistema,
      computedVisualizar,
      abrirNotificacoes,
    };
  },
});
