
import {
  defineComponent, onBeforeMount, onMounted, h, ref, reactive,
} from 'vue';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Button, notification } from 'ant-design-vue';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { EStatusRetornoRequisicao } from '../models/IRetornoRequisicao';
import GerenteAutenticacao from '../gerentes/GerenteAutenticacao';
import router from '@/router';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { INotificacao } from '@/models/Entidades/MeuSistema/Notificacoes/INotificacao';
import { ETipoNotificacao } from '@/models/Enumeradores/MeuSistema/Notificacoes/ETipoNotificacao';
import ServicoNotificacao from '@/servicos/MeuSistema/ServicoNotificacao';
import { ETipoApresentacaoMenu } from '@/models/Enumeradores/MeuSistema/ETipoApresentacaoMenu';
import { ETipoMenuItem } from '@/models/Enumeradores/MeuSistema/ETipoMenu';
import { IDTOAssinaturaDetalhe } from '@/models/DTO/PainelAdministrativo/IDTOAssinaturaDetalhe';
import ServicoPAS from '@/servicos/ServicoPAS';
import { EStatusAssinatura } from '@/models/Enumeradores/MeuSistema/EStatusAssinatura';
import Loading from './Loading.vue';
import Icone from '@/core/components/Icone.vue';
import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import NotificacoesDesktop from './Notificacoes/NotificacoesDesktop.vue';
import MeusAtalhosDesktop from './MeusAtalhos/MeusAtalhosDesktop.vue';
import NotificacoesMobile from './Notificacoes/NotificacoesMobile.vue';
import MeusAtalhosMobile from './MeusAtalhos/MeusAtalhosMobile.vue';
import MenuMobileBolhas from './Menu/MenuMobileBolhas.vue';
import MenuMobileGrade from './Menu/MenuMobileGrade.vue';
import { obterItem, salvarItem } from '@/core/gerentes/GerenteLocalStorage';
import ServicoUsuario from '@/servicos/MeuSistema/ServicoUsuario';
import { useTelaBase } from '../composables/TelaBase';
import SobreModal from '../views/Sobre/SobreModal.vue';

export default defineComponent({
  name: 'ERPLayout',
  components: {
    Loading,
    Icone,
    NotificacoesDesktop,
    MeusAtalhosDesktop,
    NotificacoesMobile,
    MeusAtalhosMobile,
    MenuMobileGrade,
    MenuMobileBolhas,
    SobreModal,
  },
  props: {
    msg: String,
  },
  setup() {
    storeSistema.mutations.atualizarCarregando(true);
    const gerenteAutenticacao = new GerenteAutenticacao();
    const servicoUsuario = new ServicoUsuario();
    servicoUsuario.requisicaoSistema();
    const collapsed = ref(false);
    const exibirConfiguracoes = ref(false);
    const exibirTributacoes = ref(false);
    const { apresentarMensagemErro } = useTelaBase();

    let cacheMenu = 0;

    const state = reactive({
      menuGrade: true,
      menuSelecionado: 0,
      exibirModulos: false,
      exibirMenuRecursos: false,
      exibirNotificacoes: false,
      exibirFavoritos: false,
      exibirSobre: false,
      carregandoImagem: false,
    });

    async function maisDetalhesNotificacao(notificacao: INotificacao) {
      notification.close(notificacao.codigo.toString());

      const retornoConfirmacao = await new ServicoNotificacao().confirmarLeitura(notificacao.codigo);
      if (retornoConfirmacao.status === EStatusRetornoRequisicao.Sucesso) {
        storeSistema.mutations.diminuirNotificacaoNaoLida();
      }
      if (UtilitarioGeral.valorValido(notificacao.parametros)) {
        if (notificacao.parametros.includes('?')) {
          // Extraindo todas as queries da string
          const urlParams = new URLSearchParams(notificacao.parametros);
          const queryObject: Record<string, string> = {};

          // Iterando sobre todas as entradas da query string e adicionando ao objeto
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of urlParams.entries()) {
            queryObject[key] = value;
          }
          router.push({ path: `/${notificacao.rotaRecurso}`, query: queryObject });
        } else {
          router.push({ path: `/${notificacao.rotaRecurso}${notificacao.parametros}` });
        }
      } else {
        router.push({ path: `/${notificacao.rotaRecurso}` });
      }
    }

    async function iniciarSignalR() {
      try {
        const connection = new HubConnectionBuilder()
          .withUrl(storeSistema.state.configuracaoApp.urlHubSignalR, { accessTokenFactory: () => gerenteAutenticacao.obtemTokenAutenticado() })
          .configureLogging(LogLevel.Error)
          .build();

        await connection.start();
        connection.invoke('AutenticarGrupos');
        connection.on('ReceberNotificacao', (notificacao: INotificacao) => {
          let classNotificacao = '';
          if (notificacao.tipo === ETipoNotificacao.Normal) {
            classNotificacao = 'ss-box-notificacao-normal';
          } else if (notificacao.tipo === ETipoNotificacao.Importante) {
            classNotificacao = 'ss-box-notificacao-importante';
          } else if (notificacao.tipo === ETipoNotificacao.Urgente) {
            classNotificacao = 'ss-box-notificacao-urgente';
          }
          storeSistema.mutations.adicionarNotificacaoNaoLida();
          notification.open({
            message: notificacao.titulo,
            class: classNotificacao,
            description: notificacao.mensagem,
            btn: () => h(Button, {
              type: 'primary',
              size: 'small',
              onClick: () => maisDetalhesNotificacao(notificacao),
            }, {
              default: () => 'Mais detalhes',
            }),
            duration: 15,
            key: notificacao.codigo.toString(),
          });
        });
      } catch (err) {
        setTimeout(iniciarSignalR, 5000);
      }
    }

    const handleWindowSizeChange = () => {
      storeSistema.mutations.atualizarLarguraTela();
    };

    onMounted(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      handleWindowSizeChange();
    });

    function obterTipoMenu() {
      state.menuGrade = obterItem(`USAR_MENU_GRADE_${storeSistema.getters.codigoUsuarioAutenticado}`) as boolean;
    }

    onBeforeMount(async () => {
      if (!gerenteAutenticacao.existeIdentificadorContratante()) {
        router.push({ name: 'IdentificarAmbiente' });
      }

      if (!gerenteAutenticacao.verificaAutenticacao()) {
        router.push({ name: 'Login' });
      }

      obterTipoMenu();
      let detalhesAssinatura:IDTOAssinaturaDetalhe = {} as IDTOAssinaturaDetalhe;
      if (storeSistema.state.configuracaoApp.tipoAmbiente === 2 && !UtilitarioGeral.valorValido(storeSistema.state.configuracaoApp.urlApi)) {
        detalhesAssinatura = await new ServicoPAS().obterDetalhesAssinatura(gerenteAutenticacao.obtemIdentificadorContratante());

        if (!UtilitarioGeral.objetoValido(detalhesAssinatura) || detalhesAssinatura.codigoContratante === 0) {
          router.push({ name: 'AmbienteNaoEncontrado' });
          return;
        }

        if (detalhesAssinatura.status !== EStatusAssinatura.Ativa && detalhesAssinatura.status !== EStatusAssinatura.Teste) {
          router.push({ name: 'AssinaturaInativa' });
        }
        storeSistema.mutations.atualizarUrlApiESignalR(detalhesAssinatura.urlApi);
      }

      if (gerenteAutenticacao.atualizarToken()) {
        const retornoAutenticacao = await new ServicoSistema().atualizarToken();
        if (retornoAutenticacao.autenticado) {
          gerenteAutenticacao.salvarDadosAutenticacao(retornoAutenticacao.token, retornoAutenticacao.dataExpiracao.toString());
        }
      }

      await new ServicoSistema().preencherDadosSistema(false);
      if (storeSistema.state.empresasDisponiveis === null || storeSistema.state.empresasDisponiveis === undefined) {
        router.push({ name: 'ErroRequisicao' });
      }
      await iniciarSignalR();
      storeSistema.mutations.atualizarCarregando(false);
    });

    function exibirFullScreen() {
      document.body.classList.toggle('fullscreen-enable');
      if (
        !document.fullscreenElement
      ) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        }
      } else {
        document.exitFullscreen();
      }
    }

    function atualizarCollapsed() {
      collapsed.value = !collapsed.value;
      storeSistema.mutations.atualizarMenuCollapse();
    }

    function montarApresentacaoEmpresa(dadosEmpresa: IDTODadosEmpresa) {
      const exibicao = UtilitarioGeral.montaExibicaoEmpresa(dadosEmpresa.codigoExibicao, dadosEmpresa.nomeExibicao);
      return `${exibicao}`;
    }

    function montarApresentacaoCpfCnpjEmpresa(dadosEmpresa: IDTODadosEmpresa) {
      const textoCpfouCnpj = UtilitarioGeral.documentoIdentificacaoCPFouCNPJ(dadosEmpresa.cpfCnpj);
      const cpfCpnpj = UtilitarioMascara.mascararCPFOuCNPJ(dadosEmpresa.cpfCnpj);

      return `${textoCpfouCnpj}: ${cpfCpnpj}`;
    }

    async function deslogar() {
      const servicoSistema = new ServicoSistema();
      const gerenteAutenticacao = new GerenteAutenticacao();
      const retornoAutenticacao = await servicoSistema.deslogar();
      if (!retornoAutenticacao.autenticado) {
        gerenteAutenticacao.removerDadosAutenticacao();
        router.push({ name: 'IdentificarAmbiente' });
      }
    }

    function alterarTipoMenu() {
      clearTimeout(cacheMenu);
      cacheMenu = setTimeout(async () => {
        await salvarItem(`USAR_MENU_GRADE_${storeSistema.getters.codigoUsuarioAutenticado}`, state.menuGrade);
      }, 600);
    }

    function abrirMenuModulos() {
      state.exibirModulos = !state.exibirModulos;
      state.exibirMenuRecursos = false;
    }

    function abrirMenuRecursos(menu: number) {
      state.menuSelecionado = menu;
      state.exibirModulos = false;
      state.exibirMenuRecursos = true;
      state.exibirNotificacoes = false;
      state.exibirFavoritos = false;
      state.exibirSobre = false;
    }

    function abrirNotificacoes() {
      state.exibirModulos = false;
      state.exibirMenuRecursos = false;
      state.exibirFavoritos = false;
      state.exibirSobre = false;
      state.menuSelecionado = 0;
    }

    function abrirFavoritos() {
      state.exibirModulos = false;
      state.exibirMenuRecursos = false;
      state.exibirNotificacoes = false;
      state.exibirSobre = false;
      state.menuSelecionado = 0;
    }

    function abrirSobre() {
      state.exibirModulos = false;
      state.exibirMenuRecursos = false;
      state.exibirNotificacoes = false;
      state.exibirSobre = true;
      state.menuSelecionado = 0;
    }

    function fecharMenuRecursos() {
      state.exibirModulos = false;
      state.exibirMenuRecursos = false;
      state.exibirNotificacoes = false;
      state.exibirFavoritos = false;
      state.menuSelecionado = 0;
    }

    function voltarMenuModulos() {
      state.exibirModulos = true;
      state.exibirMenuRecursos = false;
      state.exibirNotificacoes = false;
      state.exibirFavoritos = false;
      state.menuSelecionado = 0;
    }

    async function alterarAvatar(arquivo:any) {
      state.carregandoImagem = true;
      const retornoArquivo = await servicoUsuario.enviarImagem(arquivo, true);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        storeSistema.mutations.atualizarImagemUsuarioAutenticado(retornoArquivo.linkArquivo);
      } else {
        apresentarMensagemErro(retornoArquivo.mensagem);
      }
      state.carregandoImagem = false;
    }

    async function definirEmpresaEmOperacao(empresa:IDTODadosEmpresa) {
      storeSistema.mutations.atualizarCarregando(true);
      storeSistema.mutations.definirEmpresaEmOperacao(empresa);
      await new ServicoSistema().preencherDadosSistema(false);
      storeSistema.mutations.atualizarCarregando(false);
      router.push({ name: 'MinhaDashboard' });
      if (storeSistema.state.empresasDisponiveis === null || storeSistema.state.empresasDisponiveis === undefined) {
        router.push({ name: 'ErroRequisicao' });
      }
    }

    return {
      storeSistema,
      current: ['1'],
      exibirFullScreen,
      ETipoApresentacaoMenu,
      ETipoMenuItem,
      collapsed,
      state,
      UtilitarioGeral,
      exibirConfiguracoes,
      exibirTributacoes,
      deslogar,
      montarApresentacaoEmpresa,
      montarApresentacaoCpfCnpjEmpresa,
      atualizarCollapsed,
      alterarTipoMenu,
      abrirMenuModulos,
      abrirMenuRecursos,
      abrirNotificacoes,
      abrirFavoritos,
      abrirSobre,
      fecharMenuRecursos,
      voltarMenuModulos,
      alterarAvatar,
      definirEmpresaEmOperacao,
    };
  },
});
