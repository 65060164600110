
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import SelecionarCasasDecimaisValor from '@/core/components/Tela/SelecionarCasasDecimaisValor.vue';
import SelecionarTipoItem from '@/components/Compartilhados/SelecionarTipoItem.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoTipoPreco from '@/servicos/Cadastros/Precificacao/ServicoTipoPreco';
import { ITipoPreco, ITipoPrecoEmpresa } from '@/models/Entidades/Cadastros/Precificacao/ITipoPreco';

export default defineComponent({
  name: 'TipoPrecoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    SelecionarCasasDecimaisValor,
    SelecionarEmpresaCadastroCompartilhado,
    SelecionarTipoItem,
    RequisicaoModal,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasCadastroCompartilhado, preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoTipoPreco = new ServicoTipoPreco();
    telaBase.identificadorRecurso = 'CADASTRO_TIPOS_PRECO';
    telaBase.identificadorPermissao = 'PER_CADASTRO_TIPOS_PRECO';

    const state = reactive({
      TipoPreco: {} as ITipoPreco,
    });

    function objetoInicial() {
      state.TipoPreco = {} as ITipoPreco;
      state.TipoPreco.codigo = 0;
      state.TipoPreco.casasDecimais = 2;
      state.TipoPreco.empresas = [];
      state.TipoPreco.ativo = true;
      telaBase.empresasSelecionadas = [];
    }

    function preparaPersistenciaEmpresas() {
      const TipoPrecoEmpresas: ITipoPrecoEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.TipoPreco.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            TipoPrecoEmpresas.push(empresaExistente);
          } else {
            const TipoPrecoEmpresa: ITipoPrecoEmpresa = { codigo: 0, codigoTipoPreco: state.TipoPreco.codigo, codigoEmpresa };
            TipoPrecoEmpresas.push(TipoPrecoEmpresa);
          }
        });
      }
      state.TipoPreco.empresas = TipoPrecoEmpresas;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      preparaPersistenciaEmpresas();
      apresentarBarraProgresso();

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoTipoPreco.incluir(state.TipoPreco);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoTipoPreco.alterar(state.TipoPreco);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.TipoPreco = await servicoTipoPreco.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          if (state.TipoPreco.empresas.length > 0) {
            state.TipoPreco.empresas.forEach((tipoPrecoEmpresa) => {
              telaBase.empresasSelecionadas.push(tipoPrecoEmpresa.codigoEmpresa);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
    };
  },
});
