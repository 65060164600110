
import {
  computed, defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';

export default defineComponent({
  name: 'SelecionarIconeProcessoVendas',
  props: {
    visivel: {
      type: Boolean,
    },
    icone: {
      type: String,
      required: true,
    },
  },
  components: {
    Icone,
  },
  emits: ['update:visivel', 'update:icone', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      icone: '',
    });

    const computedIcone = computed({
      get: () => props.icone,
      set: (valor: string) => {
        emit('update:icone', valor);
      },
    });

    function confirmar() {
      computedIcone.value = state.icone;
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      computedIcone,
      confirmar,
    };
  },
});
