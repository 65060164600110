
import {
  defineComponent, provide, reactive, ref, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import {
  IEtapaProcessoVendas, IEtapaProcessoVendasProximaEtapa, IProcessoVendas, IProcessoVendasEmpresa, IProcessoVendasPerfilUsuario, IProcessoVendasUsuario,
} from '@/models/Entidades/Cadastros/Vendas/IProcessoVendas';
import ServicoProcessoVendas from '@/servicos/Cadastros/Vendas/ServicoProcessoVendas';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import SelecionarPerfilUsuario from '@/components/MeuSistema/Usuarios/SelecionarPerfilUsuario.vue';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import storeSistema from '@/store/storeSistema';
import SelecionarIconeProcessoVendas from './SelecionarIconeProcessoVendas.vue';
import { ICardFluxo, ICardFluxoDetalhe } from '@/core/models/IFluxo';
import EtapaProcessoVendasModal from './EtapaProcessoVendasModal.vue';
import SelecionarEtapaProcessoVendasModal from './SelecionarEtapaProcessoVendasModal.vue';
import { EStatusVenda } from '@/models/Enumeradores/Vendas/EStatusVenda';
import PainelFluxo from '@/core/components/Fluxo/PainelFluxo.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import ProcessoVendasOrdenacaoEtapasModal from './ProcessoVendasOrdenacaoEtapasModal.vue';

export default defineComponent({
  name: 'ProcessoVendasModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    PainelFluxo,
    SelecionarEmpresaCadastroCompartilhado,
    Icone,
    SelecionarUsuario,
    SelecionarPerfilUsuario,
    SelecionarIconeProcessoVendas,
    EtapaProcessoVendasModal,
    SelecionarEtapaProcessoVendasModal,
    ProcessoVendasOrdenacaoEtapasModal,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas, defineEmpresasSelecionadasCadastroCompartilhado,
      preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro, defineTextoPadraoBotoes,
    } = useModalBase(props, emit);
    const servicoSistema = new ServicoSistema();
    const servicoProcessoVendas = new ServicoProcessoVendas();
    const perfisUsuarios = ref<number[]>([]);
    const usuarios = ref<number[]>([]);
    const etapasDisponiveis = ref<IEtapaProcessoVendas[]>([]);
    const optionsEtapas = ref<IOptionString[]>([]);
    provide('PROCESSO_VENDAS_PERFIS', perfisUsuarios);
    provide('PROCESSO_VENDAS_USUARIOS', usuarios);
    provide('PROCESSO_VENDAS_ETAPAS', etapasDisponiveis);
    provide('PROCESSO_VENDAS_OPTIONS_ETAPAS', optionsEtapas);
    telaBase.identificadorRecurso = 'CADASTRO_PROCESSOS_VENDAS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_PROCESSOS_VENDAS';

    const state = reactive({
      processo: {} as IProcessoVendas,
      fluxoEtapas: [] as ICardFluxo[],
      etapasAnteriores: [] as IEtapaProcessoVendas[],
      etapaSelecionada: {} as IEtapaProcessoVendas,
      ultimoCodigoProvisorio: 1,
      possuiVendas: false,
      exibirSelecaoIcones: false,
      exibirDetalheEtapa: false,
      exibirSelecionarEtapa: false,
      exibirOrdenacaoEtapas: false,
      carregandoEtapas: false,
      telaMobile: false,
    });

    function limparTela() {
      perfisUsuarios.value = [] as number[];
      usuarios.value = [] as number[];
      etapasDisponiveis.value = [] as IEtapaProcessoVendas[];
      optionsEtapas.value = [] as IOptionString[];
      state.processo = {} as IProcessoVendas;
      state.fluxoEtapas = [] as ICardFluxo[];
      state.processo.icone = '/static/img/menu/vendas/painel-vendas-padrao.png';
      state.processo.ativo = true;
      state.processo.perfisUsuarios = [] as IProcessoVendasPerfilUsuario[];
      state.processo.usuarios = [] as IProcessoVendasUsuario[];
      state.processo.etapas = [] as IEtapaProcessoVendas[];
      state.processo.empresas = [] as IProcessoVendasEmpresa[];
      state.etapasAnteriores = [] as IEtapaProcessoVendas[];
      state.etapaSelecionada = {} as IEtapaProcessoVendas;
      state.carregandoEtapas = false;
      state.possuiVendas = false;
      state.exibirSelecaoIcones = false;
      state.exibirDetalheEtapa = false;
      state.exibirSelecionarEtapa = false;
      state.exibirOrdenacaoEtapas = false;
    }

    function zerarCodigosEtapas() {
      state.processo.etapas.forEach((e, indexE) => {
        state.ultimoCodigoProvisorio += 1;
        state.processo.etapas[indexE].codigoProvisorio = `P${state.ultimoCodigoProvisorio}`;
        const indexOption = optionsEtapas.value.findIndex((o) => o.value === String(e.codigo));
        optionsEtapas.value[indexOption].value = state.processo.etapas[indexE].codigoProvisorio;

        state.processo.etapas.forEach((ei, indexEi) => {
          if (ei.codigoEtapaExpiracao === e.codigo) {
            state.processo.etapas[indexEi].codigoEtapaExpiracao = 0;
            state.processo.etapas[indexEi].codigoEtapaExpiracaoProvisorio = state.processo.etapas[indexE].codigoProvisorio;
          }

          ei.proximasEtapas.forEach((pe, indexPe) => {
            if (pe.codigoProximaEtapa === e.codigo) {
              state.processo.etapas[indexEi].proximasEtapas[indexPe].codigo = 0;
              state.processo.etapas[indexEi].proximasEtapas[indexPe].codigoEtapaProcessoVendas = 0;
              state.processo.etapas[indexEi].proximasEtapas[indexPe].codigoProximaEtapa = 0;
              state.processo.etapas[indexEi].proximasEtapas[indexPe].codigoProximaEtapaProvisorio = state.processo.etapas[indexE].codigoProvisorio;
            }
          });
        });
        state.processo.etapas[indexE].codigo = 0;
      });
    }

    function duplicarRegistro() {
      state.processo.codigo = 0;
      state.processo.empresas.forEach((e, index) => {
        state.processo.empresas[index].codigo = 0;
        state.processo.empresas[index].codigoProcessoVendas = 0;
      });

      state.processo.perfisUsuarios.forEach((p, index) => {
        state.processo.perfisUsuarios[index].codigo = 0;
        state.processo.perfisUsuarios[index].codigoProcessoVendas = 0;
      });

      state.processo.usuarios.forEach((p, index) => {
        state.processo.usuarios[index].codigo = 0;
        state.processo.usuarios[index].codigoProcessoVendas = 0;
      });

      state.processo.usuarios.forEach((p, index) => {
        state.processo.usuarios[index].codigo = 0;
        state.processo.usuarios[index].codigoProcessoVendas = 0;
      });
      zerarCodigosEtapas();
      const telaOperacao: ITelaOperacao = props.operacao;
      telaOperacao.codigoRegistro = 0;
      telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      modalBase.computedOperacao = telaOperacao;
      apresentarMensagemAlerta('Cadastro alterado para inserção!');
    }

    function alterarIcone() {
      state.exibirSelecaoIcones = true;
    }

    function obterPerfisUsuarios() {
      state.processo.perfisUsuarios = [] as IProcessoVendasPerfilUsuario[];
      perfisUsuarios.value.forEach((p) => {
        const perfil = {} as IProcessoVendasPerfilUsuario;
        perfil.codigoProcessoVendas = state.processo.codigo;
        perfil.codigoPerfilUsuario = p;
        state.processo.perfisUsuarios.push(perfil);
      });
    }

    function obterUsuarios() {
      state.processo.usuarios = [] as IProcessoVendasUsuario[];
      usuarios.value.forEach((u) => {
        const usuario = {} as IProcessoVendasUsuario;
        usuario.codigoProcessoVendas = state.processo.codigo;
        usuario.codigoUsuario = u;
        state.processo.usuarios.push(usuario);
      });
    }

    function excluirNaoVinculadosEtapas() {
      state.processo.etapas.forEach((e, indexE) => {
        e.proximasEtapas.forEach((pee, indexPee) => {
          let codigo = '';
          if (UtilitarioGeral.validaCodigo(pee.codigoProximaEtapa)) {
            codigo = String(pee.codigoProximaEtapa);
          } else {
            codigo = pee.codigoProximaEtapaProvisorio;
          }
          const indexPe = optionsEtapas.value.findIndex((eD) => eD.value === codigo);
          if (indexPe === -1) {
            state.processo.etapas[indexE].proximasEtapas.splice(indexPee, 1);
          }
        });
      });
    }

    function atualizarOrdemEtapas() {
      state.processo.etapas.forEach((e, index) => {
        state.processo.etapas[index].ordem = index;
      });
    }

    function abrirOrdenacaoEtapas() {
      state.exibirOrdenacaoEtapas = true;
    }

    async function atualizarMenuSistema() {
      const menus = await servicoSistema.obterMenu(storeSistema.getters.codigoEmpresaOperacao());
      storeSistema.mutations.atualizarMenus(menus);
    }

    async function salvar(salvarNovo: boolean) {
      if (state.processo.etapas.length === 0) {
        apresentarMensagemAlerta('Ao menos uma etapa deve ser adicionada!');
        return;
      }

      atualizarOrdemEtapas();
      obterPerfisUsuarios();
      obterUsuarios();
      excluirNaoVinculadosEtapas();

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      state.processo.empresas = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresa = {} as IProcessoVendasEmpresa;
          empresa.codigo = 0;
          empresa.codigoProcessoVendas = state.processo.codigo;
          empresa.codigoEmpresa = codigoEmpresa;
          state.processo.empresas.push(empresa);
        });
      }
      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoProcessoVendas.incluir(state.processo);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoProcessoVendas.alterar(state.processo);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        atualizarMenuSistema();
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          limparTela();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    function obterCardFluxo(etapa: IEtapaProcessoVendas) {
      const card = {} as ICardFluxo;
      card.identificador = String(etapa.codigo);
      if (!UtilitarioGeral.validaCodigo(etapa.codigo)) {
        card.identificador = etapa.codigoProvisorio;
      }
      card.titulo = etapa.nome;
      card.cor = etapa.cor;
      card.incluirFilho = true;
      card.excluir = true;
      card.detalhes = [] as ICardFluxoDetalhe[];
      if (etapa.notificarParticipantes || etapa.notificarResponsavel) {
        const detalhe = {} as ICardFluxoDetalhe;
        detalhe.icone = 'notificacao';
        detalhe.texto = 'Lançar Notificação';
        card.detalhes.push(detalhe);
      }
      if (etapa.bloquearEstoque || etapa.movimentarEstoque) {
        const detalhe = {} as ICardFluxoDetalhe;
        detalhe.icone = 'caixa';
        detalhe.texto = 'Movimentar Estoque';
        card.detalhes.push(detalhe);
      }
      if (etapa.executarFaturamento) {
        const detalhe = {} as ICardFluxoDetalhe;
        detalhe.icone = 'saco-de-dinheiro';
        detalhe.texto = 'Executar Faturamento';
        card.detalhes.push(detalhe);
      }
      if (etapa.gerarDocFiscal) {
        const detalhe = {} as ICardFluxoDetalhe;
        detalhe.icone = 'documento-aprovado';
        detalhe.texto = 'Gerar documento fiscal';
        card.detalhes.push(detalhe);
      }
      if (card.detalhes.length === 0) {
        const detalhe = {} as ICardFluxoDetalhe;
        detalhe.icone = 'informacoes';
        detalhe.texto = 'Nenhum gatilho nessa etapa';
        card.detalhes.push(detalhe);
      }
      return card;
    }

    function preencherFluxoFilhos(proximasEtapas: IEtapaProcessoVendasProximaEtapa[]) {
      const cards = [] as ICardFluxo[];
      proximasEtapas.forEach((pe) => {
        const indexEtapa = state.processo.etapas.findIndex((e) => (e.codigo === pe.codigoProximaEtapa && e.codigo) || (e.codigoProvisorio === pe.codigoProximaEtapaProvisorio && UtilitarioGeral.valorValido(pe.codigoProximaEtapaProvisorio)));
        const card = obterCardFluxo(state.processo.etapas[indexEtapa]);
        card.filhos = preencherFluxoFilhos(state.processo.etapas[indexEtapa].proximasEtapas);
        cards.push(card);
      });
      return cards;
    }

    function preencherFluxo() {
      state.fluxoEtapas = [] as ICardFluxo[];

      state.processo.etapas.forEach((e) => {
        if (!servicoProcessoVendas.validarEtapaReferenciada(UtilitarioGeral.validaCodigo(e.codigo) ? String(e.codigo) : e.codigoProvisorio, state.processo.etapas)) {
          const card = obterCardFluxo(e);
          card.filhos = preencherFluxoFilhos(e.proximasEtapas);
          state.fluxoEtapas.push(card);
        }
      });
    }

    function atualizarEtapasDisponiveis() {
      const etapas = [] as IOptionString[];

      state.processo.etapas.forEach((e) => {
        const option = {} as IOptionString;
        option.label = `${e.nome}`;
        if (e.codigo !== undefined && e.codigo > 0) {
          option.value = String(e.codigo);
        } else {
          option.value = e.codigoProvisorio;
        }
        etapas.push(option);
      });
      optionsEtapas.value = etapas;
      etapasDisponiveis.value = state.processo.etapas;
      preencherFluxo();
    }

    function adicionarEtapa(codigoAnterior: string) {
      state.etapasAnteriores = [] as IEtapaProcessoVendas[];
      if (codigoAnterior !== undefined && codigoAnterior !== '') {
        const index = state.processo.etapas.findIndex((e) => String(e.codigo) === codigoAnterior || e.codigoProvisorio === codigoAnterior);
        state.etapasAnteriores.push(state.processo.etapas[index]);
      }
      state.etapaSelecionada = {} as IEtapaProcessoVendas;
      state.exibirDetalheEtapa = true;
    }

    function editarEtapa(codigo: string) {
      const index = state.processo.etapas.findIndex((e) => String(e.codigo) === codigo || e.codigoProvisorio === codigo);
      state.etapasAnteriores = [] as IEtapaProcessoVendas[];
      state.processo.etapas.forEach((e) => {
        e.proximasEtapas.forEach((pe) => {
          if (String(pe.codigoProximaEtapa) === codigo || pe.codigoProximaEtapaProvisorio === codigo) {
            state.etapasAnteriores.push(e);
          }
        });
      });
      state.etapaSelecionada = state.processo.etapas[index];
      state.exibirDetalheEtapa = true;
    }

    function atualizarEtapa(etapa: IEtapaProcessoVendas) {
      const codigoEtapa = servicoProcessoVendas.obterCodigoEtapa(etapa);
      const index = state.processo.etapas.findIndex((e) => String(e.codigo) === codigoEtapa || e.codigoProvisorio === codigoEtapa);
      if (index > -1) {
        state.processo.etapas[index] = etapa;
      } else {
        state.processo.etapas.push(etapa);
        if (state.etapasAnteriores.length > 0) {
          const etapaAnterior = state.etapasAnteriores[0];
          let codigo = String(etapaAnterior.codigo);
          if (!UtilitarioGeral.validaCodigo(etapaAnterior.codigo)) {
            codigo = etapaAnterior.codigoProvisorio;
          }

          const indexEtapaAnterior = state.processo.etapas.findIndex((e) => String(e.codigo) === codigo || e.codigoProvisorio === codigo);
          const proximaEtapa = {} as IEtapaProcessoVendasProximaEtapa;
          proximaEtapa.codigoProximaEtapaProvisorio = etapa.codigoProvisorio;
          proximaEtapa.codigoEtapaProcessoVendas = etapaAnterior.codigo;
          state.processo.etapas[indexEtapaAnterior].proximasEtapas.push(proximaEtapa);
        }
      }
      atualizarEtapasDisponiveis();
    }

    function removerEtapaLista(index: number) {
      const codigo = servicoProcessoVendas.obterCodigoEtapa(state.processo.etapas[index]);
      state.processo.etapas.forEach((e, indexE) => {
        e.proximasEtapas.forEach((pe, indexPE) => {
          if (String(pe.codigoProximaEtapa) === codigo || pe.codigoProximaEtapaProvisorio === codigo) {
            state.processo.etapas[indexE].proximasEtapas.splice(indexPE, 1);
          }
        });
      });
      state.processo.etapas.splice(index, 1);
      atualizarEtapasDisponiveis();
    }

    async function transferirVendasEtapaExcluida(etapaDestino: string) {
      const indexEtapaOrigem = state.processo.etapas.findIndex((e) => e.codigo === state.etapaSelecionada.codigo);
      const indexEtapaDestino = state.processo.etapas.findIndex((e) => String(e.codigo) === etapaDestino || e.codigoProvisorio === etapaDestino);

      state.processo.etapas[indexEtapaDestino].codigoEtapaTransferencia = state.etapaSelecionada.codigo;
      removerEtapaLista(indexEtapaOrigem);
      state.etapaSelecionada = {} as IEtapaProcessoVendas;
      atualizarEtapasDisponiveis();
    }

    async function excluirEtapa(codigo: string) {
      const index = state.processo.etapas.findIndex((e) => String(e.codigo) === codigo || e.codigoProvisorio === codigo);
      if (state.processo.etapas[index].statusVenda === EStatusVenda.Cancelada || state.processo.etapas[index].statusVenda === EStatusVenda.Finalizada) {
        if (state.processo.etapas[index].codigo > 0) {
          const retorno = await servicoProcessoVendas.validarVendasEtapa(state.processo.etapas[index].codigo, storeSistema.getters.codigoEmpresaOperacao());
          if (retorno) {
            state.etapaSelecionada = state.processo.etapas[index];
            state.exibirSelecionarEtapa = true;
            return;
          }
        }
      }
      if (codigo.includes('P')) {
        removerEtapaLista(index);
      } else {
        Modal.confirm({
          title: `Realmente deseja excluir a Etapa ${state.processo.etapas[index].nome}?`,
          content: 'Você perderá o histórico das alterações de vendas realizadas por essa etapa.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          autoFocusButton: null,
          onOk: async () => {
            removerEtapaLista(index);
          },
        });
      }
    }

    async function preencherPerfisUsuarios() {
      state.processo.perfisUsuarios = await servicoProcessoVendas.obterPerfisUsuariosProcesso(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      perfisUsuarios.value = [] as number[];
      state.processo.perfisUsuarios.forEach((p) => {
        perfisUsuarios.value.push(p.codigoPerfilUsuario);
      });
    }

    async function preencherUsuarios() {
      state.processo.usuarios = await servicoProcessoVendas.obterUsuariosProcesso(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      usuarios.value = [] as number[];
      state.processo.usuarios.forEach((u) => {
        usuarios.value.push(u.codigoUsuario);
      });
    }

    async function preencherEtapas() {
      state.carregandoEtapas = true;
      state.processo.etapas = await servicoProcessoVendas.obterEtapas(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      atualizarEtapasDisponiveis();
      state.carregandoEtapas = false;
    }

    async function preencherProcesso() {
      state.processo = await servicoProcessoVendas.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
      state.possuiVendas = await servicoProcessoVendas.validarVendasAndamento(state.processo.codigo, props.operacao.empresaSelecionada);
      await preencherPerfisUsuarios();
      await preencherUsuarios();
      preencherEtapas();
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);
        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          await preencherProcesso();
          telaBase.empresasSelecionadas = [];
          if (state.processo.empresas.length > 0) {
            state.processo.empresas.forEach((empresa) => {
              telaBase.empresasSelecionadas.push(empresa.codigoEmpresa);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }
      }

      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      verificaConceitoParaApresentarEmpresas();
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      perfisUsuarios,
      usuarios,
      etapasDisponiveis,
      optionsEtapas,
      duplicarRegistro,
      alterarIcone,
      apresentarRetornoRequisicao,
      salvar,
      editarEtapa,
      atualizarEtapa,
      atualizarEtapasDisponiveis,
      transferirVendasEtapaExcluida,
      adicionarEtapa,
      excluirEtapa,
      abrirOrdenacaoEtapas,
    };
  },
});
