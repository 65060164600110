
import { defineComponent, reactive, onBeforeMount } from 'vue';
import CampoTelefone from '../components/Tela/CampoTelefone.vue';
import CampoCpf from '../components/Tela/CampoCpf.vue';
import CampoCnpj from '../components/Tela/CampoCnpj.vue';
import Icone from '../components/Icone.vue';
import MensagemSemDados from '../components/Tela/MensagemSemDados.vue';
import ServicoPAS from '@/servicos/ServicoPAS';
import { IPlanoAssinatura, IPlanoAssinaturaValor } from '@/models/Entidades/MeuSistema/PlanoAssinatura/IPlanoAssinatura';
import UtilitarioMascara from '../utilitarios/UtilitarioMascara';
import { IDTONovaContratacao } from '@/models/DTO/PainelAdministrativo/IDTONovaContratacao';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { EStatusRetornoRequisicao } from '../models/IRetornoRequisicao';
import router from '@/router';
import storeSistema from '@/store/storeSistema';

export enum ETipoUso {
  Pessoal = 1,
  Empresarial = 2,
}

export default defineComponent({
  name: 'Contratacao',
  components: {
    Icone,
    CampoTelefone,
    CampoCpf,
    CampoCnpj,
    MensagemSemDados,
  },
  setup() {
    const servicoPAS = new ServicoPAS();
    const state = reactive({
      passoAtivo: 1,
      novaContratacao: {} as IDTONovaContratacao,
      tipoUso: 0,
      planosAssinaturas: [] as IPlanoAssinatura[],
      planosAssinaturasTipoUso: [] as IPlanoAssinatura[],
      mensagemValidacaoNome: '',
      mensagemValidacaoEmail: '',
      mensagemValidacaoTelefone: '',
      mensagemValidacaoCpfCnpj: '',
      aguardandoServidor: false,
      contratanteEncontrado: 0,
      emailEncontrado: '',
    });

    state.novaContratacao.nome = '';
    state.novaContratacao.email = '';
    state.novaContratacao.cpfCnpj = '';
    state.novaContratacao.recorrenciaAssinatura = 1;

    function limparContratanteEncontrado() {
      state.mensagemValidacaoNome = '';
      state.mensagemValidacaoCpfCnpj = '';
      state.mensagemValidacaoEmail = '';
      state.mensagemValidacaoTelefone = '';
      state.contratanteEncontrado = 0;
      state.emailEncontrado = '';
    }

    async function verificaEmail() {
      const retornoValidacao = await UtilitarioGeral.validarEmail(state.novaContratacao.email);
      if (retornoValidacao) {
        state.aguardandoServidor = true;
        const verificarContratante = await servicoPAS.verificarContratanteEmail(state.novaContratacao.email);
        state.aguardandoServidor = false;
        if (verificarContratante.status !== EStatusRetornoRequisicao.Sucesso) {
          state.mensagemValidacaoEmail = 'Você já possui uma assinatura ou cadastro conosco por favor entre em contato com um de nossos consultores.';
          state.contratanteEncontrado = verificarContratante.codigoRegistro;
          state.emailEncontrado = state.novaContratacao.email;
          return false;
        }
      } else {
        state.mensagemValidacaoEmail = 'Este e-mail é inválido.';
        return false;
      }
      return true;
    }

    function verificarTelefone() {
      if (!UtilitarioGeral.valorValido(state.novaContratacao.telefone)) {
        state.mensagemValidacaoTelefone = 'O telefone informado é inválido.';
        return false;
      }

      if (state.novaContratacao.telefone.length < 10 || state.novaContratacao.telefone.length > 11) {
        state.mensagemValidacaoTelefone = 'O telefone informado é inválido.';
        return false;
      }

      return true;
    }

    async function verificaCpfCnpj() {
      state.aguardandoServidor = true;
      const retornoValidacao = await servicoPAS.validarCpfCnpj(state.novaContratacao.cpfCnpj);
      if (retornoValidacao.sucesso) {
        if (state.tipoUso === ETipoUso.Empresarial) {
          state.novaContratacao.consultaCNPJ = await servicoPAS.consultaCNPJ(state.novaContratacao.cpfCnpj);
          if (!UtilitarioGeral.valorValido(state.novaContratacao.consultaCNPJ.razaoSocial)) {
            state.mensagemValidacaoCpfCnpj = 'CNPJ não encontrado base da Receita Federal!';
            return;
          }
        }

        const verificarContratante = await servicoPAS.verificarContratanteCpfCnpj(state.novaContratacao.cpfCnpj);
        if (verificarContratante.status === EStatusRetornoRequisicao.Sucesso) {
          state.passoAtivo += 1;
        } else {
          state.mensagemValidacaoCpfCnpj = 'Você já possui uma assinatura ou cadastro conosco por favor entre em contato com um de nossos consultores.';
          state.contratanteEncontrado = verificarContratante.codigoRegistro;
          state.emailEncontrado = verificarContratante.mensagem;
        }
      } else {
        state.mensagemValidacaoCpfCnpj = retornoValidacao.mensagem;
      }
      state.aguardandoServidor = false;
    }

    async function iniciarProcesso() {
      limparContratanteEncontrado();
      if (!UtilitarioGeral.valorValido(state.novaContratacao.nome)) {
        state.mensagemValidacaoNome = 'O nome deve ser informado.';
        return;
      }

      const verificacaoEmail = await verificaEmail();
      if (!verificacaoEmail) {
        return;
      }

      const verificacaoTelefone = await verificarTelefone();
      if (!verificacaoTelefone) {
        return;
      }
      state.passoAtivo += 1;
    }

    function definirTipoUso(tipo:ETipoUso) {
      state.tipoUso = tipo;
      state.planosAssinaturasTipoUso = [];
      state.planosAssinaturas.forEach((planoAssinatura) => {
        if (state.tipoUso === ETipoUso.Pessoal) {
          if (planoAssinatura.tipo === 1 || planoAssinatura.tipo === 2) {
            state.planosAssinaturasTipoUso.push(planoAssinatura);
          }
        } else if (planoAssinatura.tipo === 3 || planoAssinatura.tipo === 4 || planoAssinatura.tipo === 5 || planoAssinatura.tipo === 6) {
          state.planosAssinaturasTipoUso.push(planoAssinatura);
        }
      });
      state.passoAtivo += 1;
    }

    function obterPrecoPlano(tipoRecorrencia: number, valores:IPlanoAssinaturaValor[]):string {
      const planoAssinaturaValor = valores.find((c) => c.recorrencia === tipoRecorrencia);
      if (planoAssinaturaValor !== undefined) {
        if (planoAssinaturaValor.valor > 0) {
          return UtilitarioMascara.mascararValor(planoAssinaturaValor.valor, 2, true);
        }
      }

      return 'Gratuito';
    }

    function obterTextoAcaoPlano(tipoRecorrencia: number, valores:IPlanoAssinaturaValor[]):string {
      const planoAssinaturaValor = valores.find((c) => c.recorrencia === tipoRecorrencia);
      if (planoAssinaturaValor !== undefined) {
        if (planoAssinaturaValor.valor > 0) {
          return 'Contratar';
        }
      }

      return 'Comece já';
    }

    function obterTextoRecorrenciaPlano(tipoRecorrencia: number, valores:IPlanoAssinaturaValor[]):string {
      let textoRecorrencia = '';
      const planoAssinaturaValor = valores.find((c) => c.recorrencia === tipoRecorrencia);
      if (planoAssinaturaValor !== undefined) {
        if (planoAssinaturaValor.valor > 0) {
          switch (tipoRecorrencia) {
            case 1:
              textoRecorrencia += '/ Mês';
              break;

            case 2:
              textoRecorrencia += '/ 3 Meses';
              break;

            case 3:
              textoRecorrencia += '/ 6 Meses';
              break;

            case 4:
              textoRecorrencia += '/ 12 Meses';
              break;

            default:
              break;
          }
        }
      }

      return textoRecorrencia;
    }

    async function definePlanoSelecionado(codigoPlanoAssinatura:number) {
      state.novaContratacao.codigoPlanoAssinatura = codigoPlanoAssinatura;
      state.passoAtivo += 1;
    }

    async function concluirContratacao() {
      state.aguardandoServidor = true;
      const retornoContratacao = await servicoPAS.novaContratacao(state.novaContratacao);
      if (retornoContratacao.status === EStatusRetornoRequisicao.Sucesso) {
        state.aguardandoServidor = false;
        state.passoAtivo += 1;
      }
    }

    async function solicitarContatoPlanoSelecionado(codigoPlanoAssinatura:number) {
      state.aguardandoServidor = true;
      state.novaContratacao.codigoPlanoAssinatura = codigoPlanoAssinatura;
      const retornoContratacao = await servicoPAS.solicitarContatoContratacao(state.novaContratacao);
      if (retornoContratacao.status === EStatusRetornoRequisicao.Sucesso) {
        state.aguardandoServidor = false;
        state.passoAtivo = 7;
      }
    }

    function redefinirSenha() {
      router.push({ name: 'EsqueciMinhaSenha', query: { contratante: state.contratanteEncontrado, email: state.emailEncontrado } });
    }

    onBeforeMount(async () => {
      if (storeSistema.state.configuracaoApp.tipoAmbiente === 1) {
        router.push({ name: 'Login' });
      }

      state.planosAssinaturas = [] as IPlanoAssinatura[];
      state.planosAssinaturas = await servicoPAS.obterTodosPlanosAtivos();
    });

    return {
      state,
      ETipoUso,
      limparContratanteEncontrado,
      definirTipoUso,
      verificaCpfCnpj,
      obterPrecoPlano,
      obterTextoAcaoPlano,
      obterTextoRecorrenciaPlano,
      definePlanoSelecionado,
      solicitarContatoPlanoSelecionado,
      redefinirSenha,
      verificaEmail,
      iniciarProcesso,
      concluirContratacao,
      UtilitarioGeral,
    };
  },
});
