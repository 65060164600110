import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import storeSistema from '@/store/storeSistema';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IContaContabilReferenciada } from '@/models/Entidades/Cadastros/PlanosContas/IContaContabilReferenciada';
import { IDTOContaContabilReferenciada } from '@/models/DTO/Cadastros/PlanosContas/IDTOContaContabilReferenciada';

/**
 * Serviço de Contas Contábeis Referenciadas
 * Fornece todas regras de negócios e lógicas relacionado a contas contábeis referenciadas.
 */

class ServicoContaContabilReferenciada implements IServicoBase<IContaContabilReferenciada> {
    endPoint = 'contas-contabeis-referenciadas';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOContaContabilReferenciada): string {
      return objeto.nome;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async obterContaContabilReferenciadaConsultaRapida(codigoContaContabilReferenciada: number, empresas?: number[]): Promise<IItemConsultaRapida> {
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        valor: codigoContaContabilReferenciada.toString(), filtrarPorCodigo: true, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(empresas)) {
        parametrosConsultaRapida.empresas = empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      const listaContasContabeisReferenciadas = await this.consultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaLista(listaContasContabeisReferenciadas)) {
        return listaContasContabeisReferenciadas[0];
      }

      return {} as IItemConsultaRapida;
    }

    public async incluir(contaContabil: IContaContabilReferenciada): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, contaContabil);
      return result.data;
    }

    public async alterar(contaContabil: IContaContabilReferenciada): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${contaContabil.codigo}`, contaContabil);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IContaContabilReferenciada> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }
}
export default ServicoContaContabilReferenciada;
