
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { IConfiguracaoInicial } from '@/models/Entidades/MeuSistema/IConfiguracaoInicial';
import { EEtapaConfiguracaoInicial } from '@/models/Enumeradores/MeuSistema/EEtapaConfiguracaoInicial';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import ProcessoVendasModal from '@/views/Cadastros/Vendas/ProcessosVendas/ProcessoVendasModal.vue';
import storeSistema from '@/store/storeSistema';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IDTOUsuarioPermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoes';
import { IDTOUsuarioPermissoesDados } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoesDados';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import PessoaModal from '@/views/Cadastros/Pessoas/PessoaModal.vue';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import ServicoCliente from '@/servicos/Cadastros/Pessoas/ServicoCliente';

export default defineComponent({
  name: 'ConfiguracaoInicialVendas',
  props: {
    configuracao: {
      type: Object as () => IConfiguracaoInicial,
      required: true,
    },
  },
  components: {
    ProcessoVendasModal,
    PessoaModal,
  },
  emits: ['update:configuracao', 'atualizarConfiguracao'],
  setup(props, { emit }) {
    const servicoSistema = new ServicoSistema();
    const servicoCliente = new ServicoCliente();

    const state = reactive({
      permissaoProcessoVendas: false,
      permissaoCliente: false,
      exibirProcessoVendas: false,
      exibirCliente: false,
      carregando: false,
      carregandoTela: false,
      concluindo: false,
      codigoProcessoVendas: 0,
      operacao: {} as ITelaOperacao,
    });

    const computedConfiguracao = computed({
      get: () => props.configuracao,
      set: (val: IConfiguracaoInicial) => {
        emit('update:configuracao', val);
      },
    });

    function abrirProcessoVendas() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_PROCESSOS_VENDAS';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirProcessoVendas = true;
    }

    function abrirCliente() {
      state.operacao = {} as ITelaOperacao;
      state.operacao.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      state.operacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacao.listaPermissoes = [] as IDTOUsuarioPermissoes[];
      state.operacao.listaPermissoesDados = [] as IDTOUsuarioPermissoesDados[];
      const permissaoDados = {} as IDTOUsuarioPermissoesDados;
      permissaoDados.codigoEmpresa = state.operacao.empresaSelecionada;
      permissaoDados.identificadorPermissao = 'PER_CADASTRO_CLIENTES';
      permissaoDados.incluir = true;
      permissaoDados.alterar = true;
      permissaoDados.excluir = true;
      permissaoDados.imprimir = true;
      state.operacao.listaPermissoesDados.push(permissaoDados);
      state.exibirCliente = true;
    }

    function concluir() {
      state.carregando = true;
      state.concluindo = true;
      emit('atualizarConfiguracao');
    }

    function validarPermissoes() {
      let pularEtapa = true;
      if (state.permissaoProcessoVendas) {
        pularEtapa = false;
      }

      if (state.permissaoCliente) {
        pularEtapa = false;
      }

      if (pularEtapa) {
        concluir();
      }
    }

    async function preencherPermissoes() {
      const permissaoProcessoVendas = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_PROCESSOS_VENDAS');
      const permissaoCliente = await servicoSistema.obterPermissoesDadosUsuario('PER_CADASTRO_CLIENTES');

      if (permissaoProcessoVendas !== undefined) {
        state.permissaoProcessoVendas = permissaoProcessoVendas[0].incluir;
      } else {
        state.permissaoProcessoVendas = false;
      }

      if (permissaoCliente !== undefined) {
        state.permissaoCliente = permissaoCliente[0].incluir;
      } else {
        state.permissaoCliente = false;
      }
      validarPermissoes();
    }

    onBeforeMount(async () => {
      state.carregando = true;
      if (props.configuracao.etapaAtual === EEtapaConfiguracaoInicial.Vendas) {
        await preencherPermissoes();
      }
      state.carregando = false;
    });

    return {
      props,
      state,
      EEtapaConfiguracaoInicial,
      ETipoRelacaoPessoa,
      servicoCliente,
      computedConfiguracao,
      abrirProcessoVendas,
      abrirCliente,
      concluir,
    };
  },
});
