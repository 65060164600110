
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';

export default defineComponent({
  name: 'SelecionarStatusTituloFinanceiro',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    valor: {
      type: Number || undefined,
    },
    valores: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    apenasPendenteBaixado: {
      type: Boolean,
      default: false,
    },
    mostrarNenhum: {
      type: Boolean,
      default: false,
    },
    controleAlcada: {
      type: Boolean,
      default: false,
    },
    mostrarTodos: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'update:valores', 'change'],
  setup(props, { emit }) {
    const state = reactive({
      listaItens: [] as IOption[],
    });

    function obterListaStatus() {
      const itens = [] as IOption[];

      if (props.mostrarNenhum) {
        itens.push({ value: 0, label: 'Nenhum' });
      }
      if (props.controleAlcada) {
        itens.push({ value: 8, label: 'Aprovação Pendente' });
        if (props.mostrarTodos) {
          itens.push({ value: 1, label: 'Pendente' });
        }
      } else {
        itens.push({ value: 1, label: 'Pendente' });
      }

      itens.push({ value: 2, label: 'Baixado' });
      if (!props.apenasPendenteBaixado) {
        if (!props.controleAlcada || props.mostrarTodos) {
          itens.push({ value: 3, label: 'Baixado parcialmente' });
        }
        itens.push({ value: 4, label: 'Baixado pelo cartão' });
        itens.push({ value: 5, label: 'Baixado com crédito' });
        itens.push({ value: 6, label: 'Renegociado' });
        itens.push({ value: 7, label: 'Excluído' });
      }
      return itens;
    }

    (async () => {
      state.listaItens = obterListaStatus();
    })();

    function obterTipoAtividade():any {
      if (props.valor !== undefined) {
        if (props.valor > 0) {
          return props.valor;
        }
        return undefined;
      }
      return props.valor;
    }

    const computedValor = computed({
      get: () => obterTipoAtividade(),
      set: (val: number) => {
        emit('update:valor', val);
      },
    });

    const computedValores = computed({
      get: () => props.valores,
      set: (val: number[]) => {
        emit('update:valores', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.valores : props.valor);
    }

    return {
      props,
      state,
      computedValor,
      computedValores,
      change,
    };
  },
});
