
import { defineComponent, reactive, watch } from 'vue';
import draggable from 'vuedraggable';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarConceitoPrecificacao from '@/components/Cadastros/Precificacao/TabelasPreco/SelecionarConceitoPrecificacao.vue';
import SelecionarStatus from '@/components/Cadastros/Precificacao/TabelasPreco/SelecionarStatus.vue';
import SelecionarFormaInclusaoItens from '@/components/Cadastros/Precificacao/TabelasPreco/SelecionarFormaInclusaoItens.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarTipoCusto from '@/components/Cadastros/Precificacao/SelecionarTipoCusto.vue';
import TabelaPrecoProdutos from '@/components/Cadastros/Precificacao/TabelasPreco/TabelaPrecoProdutos.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoTabelaPreco from '@/servicos/Cadastros/Precificacao/ServicoTabelaPreco';
import {
  ITabelaPreco, ITabelaPrecoEmpresa, ITabelaPrecoEstrutura,
} from '@/models/Entidades/Cadastros/Precificacao/ITabelaPreco';
import { EStatusTabelaPreco } from '@/models/Enumeradores/Cadastros/Precificacao/EStatusTabelaPreco';
import { EConceitoPrecificacao } from '@/models/Enumeradores/Cadastros/Precificacao/EConceitoPrecificacao';
import { EFormaInclusaoItens } from '@/models/Enumeradores/Cadastros/Precificacao/EFormaInclusaoItens';
import { ITipoPreco } from '@/models/Entidades/Cadastros/Precificacao/ITipoPreco';
import ServicoTipoPreco from '@/servicos/Cadastros/Precificacao/ServicoTipoPreco';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'TabelaPrecoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    draggable,
    Icone,
    Card,
    EditorHtml,
    SelecionarData,
    CampoNumerico,
    RequisicaoModal,
    SelecionarConceitoPrecificacao,
    SelecionarStatus,
    SelecionarFormaInclusaoItens,
    SelecionarEmpresaCadastroCompartilhado,
    SelecionarTipoCusto,
    TabelaPrecoProdutos,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas, apresentarMensagemAlerta,
      defineEmpresasSelecionadasCadastroCompartilhado, preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoTabelaPreco = new ServicoTabelaPreco();
    telaBase.identificadorRecurso = 'CADASTRO_TABELAS_PRECOS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_TABELAS_PRECOS';

    const state = reactive({
      tabelaPreco: {} as ITabelaPreco,
      tiposPrecos: [] as ITipoPreco[],
      tiposPrecosSelecao: [] as ITipoPreco[],
      tiposPrecoSelecionados: [] as number[],
      apresentarSelecaoTipoPreco: false,
      requerSalvarDadosPrincipais: false,
      alterouEstruturaTabela: false,
    });

    state.tiposPrecos = [];
    state.tiposPrecosSelecao = [];
    state.tiposPrecoSelecionados = [];

    (async () => {
      state.tiposPrecos = await new ServicoTipoPreco().obterTodosTiposPrecos();
    })();

    function requerSalvarDadosPrincipais() {
      state.requerSalvarDadosPrincipais = true;
    }

    function requerSalvarEstrutura() {
      state.requerSalvarDadosPrincipais = true;
      state.alterouEstruturaTabela = true;
    }

    function prepararSelecaoTipoPreco() {
      let codigoTiposPrecosAdicionados: number[] = [];
      state.tiposPrecoSelecionados = [];

      if (!UtilitarioGeral.validaLista(state.tiposPrecos)) {
        apresentarMensagemAlerta('Nenhum tipo de preço foi cadastrado ou localizado!');
        return;
      }

      if (!UtilitarioGeral.validaLista(telaBase.empresasSelecionadas)) {
        apresentarMensagemAlerta('É necessário definir pelo menos uma empresa para selecionar os preços!');
        return;
      }

      if (UtilitarioGeral.validaLista(state.tabelaPreco.estruturas)) {
        codigoTiposPrecosAdicionados = state.tabelaPreco.estruturas.map((c) => c.codigoTipoPreco);
      }

      state.tiposPrecosSelecao = [];
      let tipoPrecosFiltrados = state.tiposPrecos.filter((c) => c.ativo === true);
      tipoPrecosFiltrados = state.tiposPrecos.filter((c) => !codigoTiposPrecosAdicionados.includes(c.codigo));
      tipoPrecosFiltrados.forEach((tipoPreco) => {
        // Disponibiliza para seleção somente os tipos de preços que possuem as empresas selecionadas
        const empresasTipoPreco = tipoPreco.empresas.map((c) => c.codigoEmpresa);
        if (empresasTipoPreco.some((codigoEmpresa) => telaBase.empresasSelecionadas.includes(codigoEmpresa))) {
          state.tiposPrecosSelecao.push(tipoPreco);
        }
      });
      state.apresentarSelecaoTipoPreco = true;
    }

    function adicionarTipoPreco() {
      if (UtilitarioGeral.validaLista(state.tiposPrecoSelecionados)) {
        const estruturas = UtilitarioGeral.instanciaObjetoLocal(state.tabelaPreco.estruturas);
        state.tiposPrecoSelecionados.forEach((codigoTipoPreco) => {
          if (!estruturas.some((estrutura: ITabelaPrecoEstrutura) => estrutura.codigoTipoPreco === codigoTipoPreco)) {
            const estruturaTipoPreco: ITabelaPrecoEstrutura = {} as ITabelaPrecoEstrutura;
            estruturaTipoPreco.codigo = 0;
            estruturaTipoPreco.codigoTabelaPreco = state.tabelaPreco.codigo;
            estruturaTipoPreco.codigoTipoPreco = codigoTipoPreco;
            estruturaTipoPreco.markupPadrao = 0;
            estruturaTipoPreco.descontoMaximoPadrao = 0;
            estruturaTipoPreco.ordem = 0;
            estruturas.push(estruturaTipoPreco);
          }
        });

        state.tabelaPreco.estruturas = estruturas;
        state.tiposPrecoSelecionados = [];
        state.apresentarSelecaoTipoPreco = false;
        requerSalvarEstrutura();
      } else {
        apresentarMensagemAlerta('É necessário selecionar pelo menos um tipo de preço!');
      }
    }

    function manipulaTipoPrecosSelecionados(valor: boolean, codigoTipoPreco: number) {
      if (valor) {
        state.tiposPrecoSelecionados.push(codigoTipoPreco);
      } else {
        const index = state.tiposPrecoSelecionados.findIndex((c) => c === codigoTipoPreco);
        if (index >= 0) {
          state.tiposPrecoSelecionados.splice(index, 1);
        }
      }
    }

    function obterDescricaoTipoPreco(codigoTipoPreco: number) {
      const tipoPreco = state.tiposPrecos.find((c) => c.codigo === codigoTipoPreco);
      if (tipoPreco !== undefined) {
        return tipoPreco.descricao;
      }

      return '';
    }
    function objetoInicial() {
      telaBase.empresasSelecionadas = [];
      state.tabelaPreco = {} as ITabelaPreco;
      state.tabelaPreco.codigo = 0;
      state.tabelaPreco.tipoItem = 1;
      state.tabelaPreco.status = EStatusTabelaPreco.Ativa;
      state.tabelaPreco.conceitoPrecificacao = EConceitoPrecificacao.Personalizada;
      state.tabelaPreco.formaInclusaoItens = EFormaInclusaoItens.Automatica;
      state.tabelaPreco.empresas = [];
      state.tabelaPreco.estruturas = [];
      state.tabelaPreco.produtos = [];
      state.tabelaPreco.servicos = [];
      state.requerSalvarDadosPrincipais = false;
      state.alterouEstruturaTabela = false;
    }

    function preparaPersistenciaEmpresas() {
      const tabelaPrecoEmpresas: ITabelaPrecoEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.tabelaPreco.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            tabelaPrecoEmpresas.push(empresaExistente);
          } else {
            const tabelaPrecoEmpresa: ITabelaPrecoEmpresa = { codigo: 0, codigoTabelaPreco: state.tabelaPreco.codigo, codigoEmpresa };
            tabelaPrecoEmpresas.push(tabelaPrecoEmpresa);
          }
        });
      }
      state.tabelaPreco.empresas = tabelaPrecoEmpresas;
    }

    function preparaPersistenciaEstruturas() {
      if (UtilitarioGeral.validaLista(state.tabelaPreco.estruturas)) {
        for (let index = 0; index < state.tabelaPreco.estruturas.length; index += 1) {
          state.tabelaPreco.estruturas[index].ordem = index;
        }
      }
    }

    async function salvar(salvarNovo: boolean) {
      if (state.tiposPrecos.length === 0) {
        apresentarMensagemAlerta('Nenhum tipo de preço definido!');
        return;
      }
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      preparaPersistenciaEmpresas();
      preparaPersistenciaEstruturas();
      state.tabelaPreco.tipoItem = 1;
      apresentarBarraProgresso();

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoTabelaPreco.incluir(state.tabelaPreco);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoTabelaPreco.alterar(state.tabelaPreco);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados, true);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.tabelaPreco = await servicoTabelaPreco.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          if (state.tabelaPreco.empresas.length > 0) {
            state.tabelaPreco.empresas.forEach((tabelaEmpresa) => {
              telaBase.empresasSelecionadas.push(tabelaEmpresa.codigoEmpresa);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
          requerSalvarDadosPrincipais();
        }

        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    function removerTipoPrecoEstrutura(index: number) {
      state.tabelaPreco.estruturas.splice(index, 1);
      state.tiposPrecoSelecionados = [];
      requerSalvarEstrutura();
    }

    function estrategiaApresentarProdutos(): boolean {
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        return false;
      }

      if (state.alterouEstruturaTabela) {
        return false;
      }

      return true;
    }
    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
      removerTipoPrecoEstrutura,
      obterDescricaoTipoPreco,
      prepararSelecaoTipoPreco,
      adicionarTipoPreco,
      manipulaTipoPrecosSelecionados,
      estrategiaApresentarProdutos,
      requerSalvarDadosPrincipais,
      requerSalvarEstrutura,
    };
  },
});
