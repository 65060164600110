
import { defineComponent, reactive, watch } from 'vue';
import { useModalBase } from '@/core/composables/ModalBase';
import Icone from '@/core/components/Icone.vue';
import SelecionarTipoDocumentoFinanceiro from './SelecionarTipoDocumentoFinanceiro.vue';
import { useTelaBase } from '@/core/composables/TelaBase';

export default defineComponent({
  name: 'SelecionarTipoDocumentoFinanceiroModal',
  props: {
    visivel: {
      type: Boolean,
    },
    titulo: {
      type: String,
      default: 'Selecionar Tipo de Documento',
    },
    meioPagamento: {
      type: Number,
      default: 0,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Icone,
    SelecionarTipoDocumentoFinanceiro,
  },
  emits: ['confirmar'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      codigoSelecionado: 0,
    });

    function limparTela() {
      state.codigoSelecionado = 0;
    }

    function confirmar() {
      if (state.codigoSelecionado === 0 || state.codigoSelecionado === undefined) {
        apresentarMensagemAlerta('Nenhum tipo de documento selecionado!');
        return;
      }
      emit('confirmar', state.codigoSelecionado);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
    });

    return {
      props,
      state,
      modalBase,
      confirmar,
    };
  },
});
