
import {
  computed, defineComponent, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { IOption } from '@/core/models/AntDesign/IOption';

export default defineComponent({
  name: 'SelecionarTipoMovimentoComercial',
  components: {
  },
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    valor: {
      type: Number || undefined,
    },
    valoresSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    radioButton: {
      type: Boolean,
      default: false,
    },
    apenasEntradas: {
      type: Boolean,
      default: false,
    },
    apenasSaidas: {
      type: Boolean,
      default: false,
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:valor', 'update:valoresSelecionados', 'update:apenasEntradas', 'update:apenasSaidas', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenerico } = useTelaBase();

    const state = reactive({
      listaTipoMovimentoComercial: [] as IOption[],
      listaTipoMovimentoComercialCompleto: [] as IOption[],
      listaTipoMovimentoComercialEntradas: [] as IOption[],
      listaTipoMovimentoComercialSaidas: [] as IOption[],
    });

    async function buscarTiposMovimentos() {
      const listaTipoMovimentoComercial = await new ServicoSistema().obterListaTipoMovimentosComerciais(props.apenasEntradas, props.apenasSaidas);
      state.listaTipoMovimentoComercial = montaOpcoesComListaItemGenerico(listaTipoMovimentoComercial);
      state.listaTipoMovimentoComercialCompleto = montaOpcoesComListaItemGenerico(listaTipoMovimentoComercial);
    }

    (async () => {
      buscarTiposMovimentos();
    })();

    watch(async () => props.apenasEntradas, async () => {
      buscarTiposMovimentos();
    });

    watch(async () => props.apenasSaidas, async () => {
      buscarTiposMovimentos();
    });

    function obterMovimentoComercial():any {
      return (props.valor === 0 ? undefined : props.valor);
    }
    const computedValor = computed({
      get: () => obterMovimentoComercial(),
      set: (valor: number) => {
        emit('update:valor', valor);
      },
    });

    const computedValoresSelecionados = computed({
      get: () => props.valoresSelecionados,
      set: (valores: number[]) => {
        if (!(valores.length > 0)) {
          state.listaTipoMovimentoComercial = state.listaTipoMovimentoComercialCompleto;
        }

        emit('update:valoresSelecionados', valores);
      },
    });

    function defineValorSelecionado(valor:number) {
      emit('update:valor', valor);
      emit('change', valor);
    }

    function defineValoresSelecionados(valores:number[]) {
      emit('update:valoresSelecionados', valores);
      emit('change', valores);
    }

    function defineValorMarcado(valor:number) {
      emit('change', valor);
    }

    return {
      props,
      state,
      computedValor,
      computedValoresSelecionados,
      defineValorSelecionado,
      defineValoresSelecionados,
      defineValorMarcado,
    };
  },
});
