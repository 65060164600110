
import { defineComponent } from 'vue';
import router from '@/router';

export default defineComponent({
  name: 'AmbienteNaoEncontrado',
  components: {

  },
  setup() {
    function voltar() {
      router.back();
    }

    return {
      voltar,
    };
  },
});
