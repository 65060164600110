import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_ctx.props.radioButton)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_radio_group, {
          value: _ctx.computedValor,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.defineValorMarcado($event))),
          "button-style": "solid",
          disabled: _ctx.props.disabled
        }, {
          default: _withCtx(() => [
            (!_ctx.props.ignorarTipos.includes(1))
              ? (_openBlock(), _createBlock(_component_a_radio_button, {
                  key: 0,
                  value: 1
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Geral")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.props.ignorarTipos.includes(2))
              ? (_openBlock(), _createBlock(_component_a_radio_button, {
                  key: 1,
                  value: 2
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Vendas")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.props.ignorarTipos.includes(3))
              ? (_openBlock(), _createBlock(_component_a_radio_button, {
                  key: 2,
                  value: 3
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Serviços")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["value", "disabled"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_a_select, {
          value: _ctx.computedValor,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedValor) = $event)),
          onChange: _ctx.defineValorSelecionado,
          class: "ss-tamanho-completo",
          disabled: _ctx.props.disabled
        }, {
          default: _withCtx(() => [
            (!_ctx.props.ignorarTipos.includes(1))
              ? (_openBlock(), _createBlock(_component_a_select_option, {
                  key: 0,
                  value: 1
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Geral")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.props.ignorarTipos.includes(2))
              ? (_openBlock(), _createBlock(_component_a_select_option, {
                  key: 1,
                  value: 2
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Vendas")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.props.ignorarTipos.includes(3))
              ? (_openBlock(), _createBlock(_component_a_select_option, {
                  key: 2,
                  value: 3
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Serviços")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["value", "onChange", "disabled"])
      ]))
}