export enum EStatusVenda {
  EmDigitacao = 1,
  Orcamento = 2,
  Efetivada = 3,
  EmFaturamento = 4,
  Faturada = 5,
  Finalizada = 6,
  Cancelada = 7,
  Devolvida = 8,
}
