
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { useRoute } from 'vue-router';
import InlineSvg from 'vue-inline-svg';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '../components/Icone.vue';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { EStatusRetornoRequisicao } from '../models/IRetornoRequisicao';
import router from '@/router';
import { IDTOAssinaturaDetalhe } from '@/models/DTO/PainelAdministrativo/IDTOAssinaturaDetalhe';
import ServicoPAS from '@/servicos/ServicoPAS';
import { EStatusAssinatura } from '@/models/Enumeradores/MeuSistema/EStatusAssinatura';
import GerenteAutenticacao from '../gerentes/GerenteAutenticacao';

export default defineComponent({
  name: 'RedefinirSenha',
  components: {
    InlineSvg,
    Icone,
  },
  setup() {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const gerenteAutenticacao = new GerenteAutenticacao();
    const route = useRoute();

    const state = reactive({
      contratante: '',
      codigoAcesso: '',
      senha: '',
      confirmacaoSenha: '',
      carregando: false,
    });

    async function atualizarUrlAmbiente() {
      let detalhesAssinatura:IDTOAssinaturaDetalhe = {} as IDTOAssinaturaDetalhe;
      if (storeSistema.state.configuracaoApp.tipoAmbiente === 2) {
        detalhesAssinatura = await new ServicoPAS().obterDetalhesAssinatura(state.contratante);
        if (!UtilitarioGeral.objetoValido(detalhesAssinatura) || detalhesAssinatura.codigoContratante === 0) {
          router.push({ name: 'AmbienteNaoEncontrado' });
          return;
        }
        storeSistema.mutations.atualizarUrlApiESignalR(detalhesAssinatura.urlApi);
        if (detalhesAssinatura.status !== EStatusAssinatura.Ativa && detalhesAssinatura.status !== EStatusAssinatura.Teste) {
          router.push({ name: 'AssinaturaInativa' });
        }

        gerenteAutenticacao.salvarIdentificadorContratante(detalhesAssinatura.codigoContratante.toString());
      }
    }

    async function redefinirSenha() {
      if (state.senha !== state.confirmacaoSenha) {
        apresentarMensagemAlerta('A senha de confirmação não é igual a senha informada!');
        return;
      }

      state.carregando = true;
      const servicoSistema = new ServicoSistema();
      const retorno = await servicoSistema.redefinirSenha(state.codigoAcesso, state.senha);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso('Senha redefinida com sucesso!');
        router.push({ name: 'Login' });
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
      state.carregando = false;
    }

    onBeforeMount(async () => {
      state.carregando = true;
      state.contratante = String(route.params.contratante);
      state.codigoAcesso = String(route.params.codigoAcesso);
      await atualizarUrlAmbiente();
      const servicoSistema = new ServicoSistema();
      const retorno = await servicoSistema.validarRedefinicaoSenha(state.codigoAcesso);
      if (retorno.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemAlerta('O pedido de recuperação não vale mais!');
        router.push({ name: 'Login' });
      }
      state.carregando = false;
    });

    return {
      state,
      telaBase,
      storeSistema,
      redefinirSenha,
    };
  },
});
