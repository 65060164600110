import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_ctx.props.radioButton)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_radio_group, {
          value: _ctx.computedValor,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.defineValorMarcado($event))),
          "button-style": "solid",
          disabled: _ctx.props.disabled
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.itens, (item) => {
              return (_openBlock(), _createBlock(_component_a_radio_button, {
                key: item.value,
                value: item.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value", "disabled"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.props.varios)
          ? (_openBlock(), _createBlock(_component_a_select, {
              key: 0,
              class: "ss-tamanho-completo",
              value: _ctx.computedValoresSelecionados,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedValoresSelecionados) = $event)),
              mode: "multiple",
              placeholder: _ctx.props.placeholder,
              "show-search": _ctx.mostrarBusca,
              optionFilterProp: "label",
              allowClear: _ctx.props.limparSelecao,
              options: _ctx.props.itens,
              disabled: _ctx.props.disabled,
              onChange: _ctx.defineValoresSelecionados
            }, null, 8, ["value", "placeholder", "show-search", "allowClear", "options", "disabled", "onChange"]))
          : (_openBlock(), _createBlock(_component_a_select, {
              key: 1,
              class: "ss-tamanho-completo",
              value: _ctx.computedValor,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedValor) = $event)),
              placeholder: _ctx.props.placeholder,
              "show-search": _ctx.mostrarBusca,
              optionFilterProp: "label",
              allowClear: _ctx.props.limparSelecao,
              options: _ctx.props.itens,
              disabled: _ctx.props.disabled,
              onChange: _ctx.defineValorSelecionado
            }, null, 8, ["value", "placeholder", "show-search", "allowClear", "options", "disabled", "onChange"]))
      ]))
}