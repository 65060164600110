import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_2 = { class: "ant-input-group-addon" }
const _hoisted_3 = ["type", "disabled", "maxlength"]
const _hoisted_4 = { class: "ant-input-group-addon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_icone, {
        class: "ss-icone",
        nome: "senha"
      })
    ]),
    _withDirectives(_createElementVNode("input", {
      type: _ctx.state.esconder ? 'password' : 'text',
      class: "ant-input",
      style: {"text-align":"left"},
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      disabled: _ctx.props.disabled,
      maxlength: _ctx.props.tamanhoMaximo > 0 ? _ctx.props.tamanhoMaximo : '',
      onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.keyEnter && _ctx.keyEnter(...args)), ["enter"]))
    }, null, 40, _hoisted_3), [
      [_vModelDynamic, _ctx.computedValor]
    ]),
    _createElementVNode("span", _hoisted_4, [
      _createElementVNode("a", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mostrar && _ctx.mostrar(...args)))
      }, [
        _createVNode(_component_icone, {
          class: "ss-icone",
          nome: _ctx.state.esconder ? 'visualizar' : 'esconder'
        }, null, 8, ["nome"])
      ])
    ])
  ]))
}